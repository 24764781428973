#ridesharing_journeys_card {
  .kisio-summary {
    height: 95px;
    padding-top: 30px;
    text-align: center;
    .picto_line{
      margin-right: 20px;
    }
  }
}
.ridesharing-solutions-container, .ridesharing-FDR-container {
  .ridesharing-solutions-header {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: center;
    & > div {
      margin-right: 9px;
    }
    .prev-next-headers-button {
      display: none;
      &.prev-headers {
        margin-left: 15px;
        margin-right: 10px;
      }
      &.next-headers {
        margin-right: 15px;
        margin-left: 10px;
      }
    }
    .ridesharing-solution-summary {
      position: relative;
      padding-bottom: 2px;
      .img-modes-container {
        cursor: pointer;
        opacity: 0.95;
        width: auto;
        white-space: nowrap;
        background: white;
        padding: 10px 13px;
        border-radius: 23px;
        height: 42px;
        display: flex;
        align-items: center;
        & > * {
          margin-left: 5px;
          &:first-child {
            margin-left: 0;
          }

        }
        .round-separator {
          background: black;
          height: 6px;
          width: 6px;
          display: inline-block;
          border-radius: 50%;
          &:last-child {
            display: none;
          }
        }
      }
      .number-ridesharing-journeys {
        position: absolute;
        border: 1px solid $color-main-one;
        background: white;
        height: 26px;
        width: 26px;
        line-height: 26px;
        color: $color-main-one;
        text-align: center;
        border-radius: 50%;
        right: -10px;
        top: -13px;
      }
      &.active, &:hover {
        border-bottom-color: $color-main-one;
        border-radius: 3px;
        .img-modes-container {
          border: 1px solid $color-main-one;
          border-radius: 21px;
          opacity: 1;
        }
        .number-ridesharing-journeys {
          background: $color-main-one;
          color: white;
        }
      }
      &.active {
        .img-modes-container, .number-ridesharing-journeys {
          cursor: default;
        }
      }
    }
  }
  .ridesharing-solutions-body {
    display: flex;
    .ridesharing-solution-listing {
      width: 100%;
    }
  }
  .ridesharing-journey-card, .list-detail-iti {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
    .ridesharing-journey-card-summary {
      background: white;
      padding: 8px 8px 13px 8px;
      border-radius: 5px;
      &.no-solution-found {
        height: 95px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          margin: 0 30px 0 5px;
        }
      }
      .summary-header {
        display: flex;
        height: 90px;
        border-bottom: 1px solid #f0f0f0;
        .left-block {
          width: 70%;
          height: 100%;
          padding-left: 10px;
          display: flex;
          flex-direction: column;
          .top-block {
            height: 80%;
            display: flex;
            align-items: center;
            .rider-info {
              display: flex;
              align-items: center;
              justify-content: start;
              width: 70%;
              .img-rider {
                height: 40px;
                width: 40px;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 6px;
                img {
                  height: 100%;
                }
              }
              .name-rider {
                font-weight: bold;
              }
            }
            .ridesharing-company-info {
              width: calc(30% - 21px);
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0;
              img {
                max-width: 100%;
                max-height: 100%;
              }
              &.alone {
                width: calc(100% - 21px);
                height: 100%;
                justify-content: start;
              }
            }
            .separator {
              width: 1px;
              background: #f0f0f0;
              height: 100%;
              margin-left: 15px;
              margin-right: 5px;
            }
          }
          .bottom-block {
            height: 20%;
            color: #6c757d;
            font-size: 11px;
            .walking_duration {
              font-weight: bold;
            }
          }
        }
        .right-block {
          width: 30%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .etd {
            color: $color-main-one;
            text-align: right;
            font-size: 10px;
            .etd-value {
              font-weight: bold;
              font-size: 12px;
            }
          }
          .rating-container {
            .stars-container {
              text-align: center;
              .filled:before {
                content: "\2605";
                position: absolute;
                color: #F9AD4A;
              }
            }
            .number-ratings {
              text-align: center;
              color: #6c757d;
              font-size: 12px;
            }
          }
        }
      }
      .summary-body {
        height: 135px;
        &.in-FDR {
          height: auto;
        }
        .iti-ridesharing-summary {
          padding: 20px 0 0 10px;
          display: flex;
          flex-direction: column;
          .journey-path {
            .kisio-left-info {
              display: block;
              justify-content: space-between;
              width: 100%;
              ul {
                margin-bottom: 10px;
                display: flex;
                flex-wrap: wrap;
                padding: 0;
                li {
                  margin-bottom: 8px;
                  list-style-type: none;
                  display: -webkit-box;
                  span.kisio-line-code {
                    padding: 0 5px;
                    height: 24px;
                    position: relative;
                    display: inline-block;
                    line-height: 2;
                    min-width: 24px;
                    text-align: center;
                    border-radius: 3px;
                    font-family: $font-main-one;
                    font-weight: bold;
                    font-size: 12px;
                  }
                  .kisio-public-transport {
                    position: relative;
                    .ikisio-tad {
                      position: inherit;
                      border: solid 2px #ffffff;
                      background-color: $color-main-one;
                      background-clip: padding-box;
                      border-radius: 20px;
                      padding: 3px;
                      font-size: 9px;
                      color: #ffffff;
                      margin-left: -3px;
                      bottom: 2px;
                    }
                  }
                }
                li.kisio-division {
                  background-color: #e9e9e9;
                  border-radius: 7px;
                  height: 7px;
                  width: 7px;
                  margin: auto 5px auto 5px;
                }
              }
            }
            .kisio-right-info {
              display: none;
            }
          }
          .walking-rs-path {
            display: flex;
            align-items: center;
            font-size: 13px;
            margin-bottom: 20px;
            .walking-mode {
              width: 70px;
            }
            .walking-text {
              width: calc(100% - 70px);
              span {
                font-weight: bold;
              }
            }
          }
          .stop-path {
            font-size: 13px;
            margin-bottom: 20px;
            .point-path {
              display: flex;
              &.start-point {
                .times {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: space-evenly;
                }
              }
              .times {
                text-align: center;
                width: 40px;
              }
              .lines {
                width: 30px;
                text-align: center;
                color: #01538A;
                display: flex;
                flex-direction: column;
                align-items: center;
                .line {
                  width: 2px;
                  background: #01538A;
                  height: 100%;
                  margin-top: -8px;
                  margin-bottom: -5px;
                }
              }
              .addresses {
                width: calc(100% - 70px);
                .address-block {
                  .address-name {
                    font-weight: bold;
                  }
                }
              }
            }
          }
          .additional-informations {
            display: flex;
            justify-content: space-between;
            &.tariff-alone {
              justify-content: flex-end;
            }
            .availability {
              font-weight: bold;
            }
            .tariff {
              color: #F9AD4A;
              font-size: 13px;
            }
          }
          .link-to-offer-container {
            min-height: 60px;
            max-height: 115px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .warning-blablalines {
              text-align: center;
              font-size: 12px;
              color: #6d6d6d;
              margin-top: 5px;
            }
          }
          .show-details-offer, .link-to-offer {
            margin: 5px auto 0 auto;
            border: none;
            font-weight: bold;
            background: $color-main-one;
            color: white;
            line-height: 46px;
            height: 46px;
            border-radius: 23px;
            text-align: center;
            width: 220px;
            &:hover{
              width: 224px;
              height: 50px;
              margin-top: 3px;
            }
          }
        }
      }
    }
    .ridesharing-journey-card-body {
      display: none;
    }
  }
}
.ridesharing-FDR-container {
  display: none;
  .kisio-details {
    display: block;
  }
}

.ridesharing-solutions-container {
  padding-top: 20px;
}