@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?u927j2');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?u927j2#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?u927j2') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?u927j2') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?u927j2##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.ikisio {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ikisio-street-view {
  &:before {
    content: $ikisio-street-view; 
  }
}
.ikisio-arrow {
  &:before {
    content: $ikisio-arrow; 
  }
}
.ikisio-reload {
  &:before {
    content: $ikisio-reload; 
  }
}
.ikisio-trash {
  &:before {
    content: $ikisio-trash; 
  }
}
.ikisio-ctp-arrow-back {
  &:before {
    content: $ikisio-ctp-arrow-back; 
  }
}
.ikisio-ctp-arrow-left {
  &:before {
    content: $ikisio-ctp-arrow-left; 
  }
}
.ikisio-ctp-arrow-right {
  &:before {
    content: $ikisio-ctp-arrow-right; 
  }
}
.ikisio-ctp-arrow-straight {
  &:before {
    content: $ikisio-ctp-arrow-straight; 
  }
}
.ikisio-vls {
  &:before {
    content: $ikisio-bss;
  }
}
.ikisio-bss {
  &:before {
    content: $ikisio-bss;
  }
}
.ikisio-self-service-bike {
  &:before {
    content: $ikisio-bss;
  }
}
.ikisio-bicycle {
  &:before {
    content: $ikisio-bicycle; 
  }
}
.ikisio-bike {
  &:before {
    content: $ikisio-bike; 
  }
}
.ikisio-car {
  &:before {
    content: $ikisio-car; 
  }
}
.ikisio-walking {
  &:before {
    content: $ikisio-walking; 
  }
}
.ikisio-bus-front {
  &:before {
    content: $ikisio-bus-front; 
  }
}
.ikisio-train {
  &:before {
    content: $ikisio-train; 
  }
}
.ikisio-metro {
  &:before {
    content: $ikisio-metro; 
  }
}
.ikisio-tram {
  &:before {
    content: $ikisio-tram; 
  }
}
.ikisio-tramway {
  &:before {
    content: $ikisio-tramway; 
  }
}
.ikisio-bus {
  &:before {
    content: $ikisio-bus; 
  }
}
.ikisio-cable_car {
  &:before {
    content: $ikisio-cable_car; 
  }
}
.ikisio-suspendedcablecar {
  &:before {
    content: $ikisio-suspendedcablecar; 
  }
}
.ikisio-airplane {
  &:before {
    content: $ikisio-airplane; 
  }
}
.ikisio-air {
  &:before {
    content: $ikisio-air; 
  }
}
.ikisio-shuttle {
  &:before {
    content: $ikisio-shuttle; 
  }
}
.ikisio-busrapidtransit {
  &:before {
    content: $ikisio-busrapidtransit; 
  }
}
.ikisio-autocar {
  &:before {
    content: $ikisio-autocar; 
  }
}
.ikisio-park-car {
  &:before {
    content: $ikisio-park-car;
  }
}

.ikisio-update {
  &:before {
    content: $ikisio-update;
  }
}

.ikisio-back {
  &:before {
    content: $ikisio-back;
  }
}
.ikisio-minibus {
  &:before {
    content: $ikisio-minibus;
  }
}
.ikisio-coach {
  &:before {
    content: $ikisio-coach; 
  }
}
.ikisio-density {
  &:before {
    content: $ikisio-density; 
  }
}
.ikisio-localtrain {
  &:before {
    content: $ikisio-localtrain; 
  }
}
.ikisio-ter {
  &:before {
    content: $ikisio-ter;
  }
}
.ikisio-tgv {
  &:before {
    content: $ikisio-tgv; 
  }
}
.ikisio-longdistancetrain {
  &:before {
    content: $ikisio-longdistancetrain; 
  }
}
.ikisio-rapidtransit {
  &:before {
    content: $ikisio-rapidtransit; 
  }
}
.ikisio-railshuttle {
  &:before {
    content: $ikisio-railshuttle; 
  }
}
.ikisio-boat {
  &:before {
    content: $ikisio-boat; 
  }
}
.ikisio-taxi {
  &:before {
    content: $ikisio-taxi; 
  }
}
.ikisio-ferry {
  &:before {
    content: $ikisio-ferry; 
  }
}
.ikisio-funicular {
  &:before {
    content: $ikisio-funicular; 
  }
}
.ikisio-fast-walker {
  &:before {
    content: $ikisio-fast-walker; 
  }
}
.ikisio-slow-walker {
  &:before {
    content: $ikisio-slow-walker; 
  }
}
.ikisio-standard {
  &:before {
    content: $ikisio-standard; 
  }
}
.ikisio-fast-bike {
  &:before {
    content: $ikisio-fast-bike; 
  }
}
.ikisio-slow-bike {
  &:before {
    content: $ikisio-slow-bike; 
  }
}
.ikisio-standard-bike {
  &:before {
    content: $ikisio-standard-bike; 
  }
}
.ikisio-mail {
  &:before {
    content: $ikisio-mail; 
  }
}
.ikisio-facebook {
  &:before {
    content: $ikisio-facebook; 
  }
}
.ikisio-twitter {
  &:before {
    content: $ikisio-twitter; 
  }
}
.ikisio-right {
  &:before {
    content: $ikisio-right; 
  }
}
.ikisio-tad {
  &:before {
    content: $ikisio-tad; 
  }
}
.ikisio-validate {
  &:before {
    content: $ikisio-validate; 
  }
}
.ikisio-stop_point {
  &:before {
    content: $ikisio-stop_point; 
  }
}
.ikisio-administrative_region {
  &:before {
    content: $ikisio-administrative_region; 
  }
}
.ikisio-stop_area {
  &:before {
    content: $ikisio-stop_area; 
  }
}
.ikisio-address {
  &:before {
    content: $ikisio-address; 
  }
}
.ikisio-poi {
  &:before {
    content: $ikisio-poi; 
  }
}
.ikisio-pdf {
  &:before {
    content: $ikisio-pdf; 
  }
}
.ikisio-geoloc {
  &:before {
    content: $ikisio-geoloc; 
  }
}
.ikisio-proximity {
  &:before {
    content: $ikisio-proximity; 
  }
}
.ikisio-favorites {
  &:before {
    content: $ikisio-favorites;
  }
}
.fav-star-del .ikisio-favorites {
  &:before {
    content: $ikisio-favorites-full;
  }
}

.ikisio-calendar {
  &:before {
    content: $ikisio-calendar; 
  }
}
.ikisio-reverse {
  &:before {
    content: $ikisio-reverse; 
  }
}
.ikisio-close {
  &:before {
    content: $ikisio-close; 
  }
}
.ikisio-clear-field {
  &:before {
    content: $ikisio-clear-field; 
  }
}
.ikisio-share {
  &:before {
    content: $ikisio-share; 
  }
}
.ikisio-print {
  &:before {
    content: $ikisio-print; 
  }
}
.ikisio-recenter {
  &:before {
    content: $ikisio-recenter; 
  }
}
.ikisio-locate {
  &:before {
    content: $ikisio-locate; 
  }
}
.ikisio-calendar-new {
  &:before {
    content: $ikisio-calendar-new; 
  }
}
.ikisio-calendar-main {
  &:before {
    content: $ikisio-calendar-main; 
  }
}
.ikisio-clock-times {
  &:before {
    content: $ikisio-clock-times; 
  }
}
.ikisio-arrow-go-back {
  &:before {
    content: $ikisio-arrow-go-back; 
  }
}
.ikisio-arrow-go-forward {
  &:before {
    content: $ikisio-arrow-go-forward; 
  }
}
.ikisio-luggage {
  &:before {
    content: $ikisio-luggage; 
  }
}
.ikisio-rapid {
  &:before {
    content: $ikisio-rapid; 
  }
}
.ikisio-comfort {
  &:before {
    content: $ikisio-comfort; 
  }
}
.ikisio-balanced {
  &:before {
    content: $ikisio-balanced; 
  }
}
.ikisio-shortest {
  &:before {
    content: $ikisio-shortest; 
  }
}
.ikisio-walking-new {
  &:before {
    content: $ikisio-walking-new; 
  }
}
.ikisio-bike-new {
  &:before {
    content: $ikisio-bike-new;
  }
}
.ikisio-waiting-time {
  &:before {
    content: $ikisio-waiting-time; 
  }
}
.ikisio-entrance {
  &:before {
    content: $ikisio-entrance; 
  }
}
.ikisio-exit {
  &:before {
    content: $ikisio-exit; 
  }
}
.ikisio-changer {
  &:before {
    content: $ikisio-changer; 
  }
}
.ikisio-car-new {
  &:before {
    content: $ikisio-car-new; 
  }
}
 
.ikisio-ridesharing {
  &:before {
    content: $ikisio-ridesharing;
  }
}
.ikisio-ridesharing-new {
  &:before {
    content: $ikisio-ridesharing;
    font-size: 30px !important;
  }
}
.ikisio-carnopark-new {
  &:before {
    content: $ikisio-carnopark-new; 
  }
}
.ikisio-public-transport {
  &:before {
    content: $ikisio-public-transport; 
  }
}
.ikisio-preferences-new {
  &:before {
    content: $ikisio-preferences-new; 
  }
}
.ikisio-arrow-right {
  &:before {
    content: $ikisio-arrow-right; 
  }
}
.ikisio-back {
  &:before {
    content: $ikisio-back; 
  }
}
.ikisio-leaf {
  &:before {
    content: $ikisio-leaf; 
  }
}
.ikisio-arrow-drop-down {
  &:before {
    content: $ikisio-arrow-drop-down; 
  }
}
.ikisio-arrow-drop-up {
  &:before {
    content: $ikisio-arrow-drop-up; 
  }
}
.ikisio-arrow-direction {
  &:before {
    content: $ikisio-arrow-direction; 
  }
}
.ikisio-zoom {
  &:before {
    content: $ikisio-zoom; 
  }
}
.ikisio-warning {
  &:before {
    content: $ikisio-warning; 
  }
}
.ikisio-disrupt {
  &:before {
    content: $ikisio-warning;
  }
}
.ikisio-download {
  &:before {
    content: $ikisio-download; 
  }
}
.ikisio-wheelchair {
  &:before {
    content: $ikisio-wheelchair; 
  }
}
.ikisio-traffic {
  &:before {
    content: $ikisio-traffic; 
  }
}
.ikisio-information-outline {
  &:before {
    content: $ikisio-information-outline;
  }
}
.ikisio-information {
  &:before {
    content: $ikisio-information; 
  }
}
.ikisio-selector {
  &:before {
    content: $ikisio-selector; 
  }
}
.ikisio-arrival {
  &:before {
    content: $ikisio-arrival; 
  }
}
.ikisio-departure {
  &:before {
    content: $ikisio-departure; 
  }
}
.ikisio-settings {
  &:before {
    content: $ikisio-settings; 
  }
}
.ikisio-times {
  &:before {
    content: $ikisio-times; 
  }
}
.ikisio-schedule {
  &:before {
    content: $ikisio-schedule;
  }
}
.ikisio-journey {
  &:before {
    content: $ikisio-journey;
  }
}
.ikisio-from {
  &:before {
    content: $ikisio-from;
  }
}
.ikisio-arrow-destination {
  &:before {
    content: $ikisio-arrow-destination;
  }
}
.ikisio-to {
  &:before {
    content: $ikisio-to;
  }
}
.ikisio-rer {
  &:before {
    content: $ikisio-localtrain;
  }
}

.ikisio-home {
  &:before {
    content: $ikisio-home;
  }
}

.ikisio-work {
  &:before {
    content: $ikisio-work;
  }
}


.ikisio-big-bicycle {
  &:before {
    content: $ikisio-big-bicycle;
  }
}

.ikisio-handi {
  &:before {
    content: $ikisio-handi;
  }
}

.ikisio-parking {
  &:before {
    content: $ikisio-parking;
  }
}

.ikisio-information-important {
  &:before {
    content: $ikisio-information-important;
  } 
}

.ikisio-places {
  &:before {
    content: $ikisio-places;
  } 
}