#schedule_content {
  min-height: 60px;
  height: 100%;

  #ctp-searchSchedule {
    padding: 0 0 15px 0;

    #schedule_stop_area_lines {
      border: 1px solid #251942;
      border-radius: 24px;
      margin: 7% 5% 4% 5%;
      display: flex;

      .autocomplete-search-icon {
        margin: auto 2% auto 0;
        font-size: 19px;

        em {
          font-weight: bold;
        }

        i {
          color: $color-main-one;
        }
      }

      label {
        padding-left: 2%;
        display: inline-flex;
      }

      input {
        overflow: visible;
        outline: none;
        text-overflow: ellipsis;
        background-color: transparent;
        border: none;
        height: 34px;
        width: 92%;
        padding-left: 20px;

        &::placeholder {
          opacity: .9;
          color: #6F6F70;
        }
      }

      .autocomplete-clear {
        width: 9%;
        margin-right: 0;
      }

      #show_all_lines {
        margin: 6px 7px 0 0;
        cursor: pointer;
      }
    }

    .favorites_standalone_link {
      margin: 30px auto;
    }
  }

  .schedule-line {
    &__title > h4 {
      font-weight: 700;
      margin-bottom: 20px;
    }
  }
}

.schedule-line-form {
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 30px 0;
    margin-right: 25px;

    .schedule-section__line-constrast {
      line-height: 1.2;
      height: 30px;
      width: auto;
      min-width: 30px;
      font-size: 22px;
      margin: 7px 6px;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 24px;
    margin-left: 10px;
  }

  &__body {
    height: 100vh;
    background-color: #F5F5F5;
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__choice-type {
    margin-bottom: 30px;
    width: 100%;

    ul {
      max-height: 50vh;
      padding: 10px 20px;
      border-radius: 0 0px 20px 20px;

      li {
        border-bottom: none;
        margin-bottom: 10px;

        label {
          font-weight: 400;
          height: 40px;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
          border-radius: 12px;
          width: auto;
          justify-content: flex-start;
          align-items: center;

          i {
            font-weight: bold;
            font-size: 20px;
            margin-right: 10px;
          }
        }
      }
    }

    summary {
      border-radius: 20px;
      background-color: #FFFFFF;
      border: 1px solid #000000;
      padding: 0 20px;

      &:focus-visible {
        outline: auto;
      }

      &:after {
        color: #000000;
      }

      input {
        &:after {
          color: #000000 !important;
        }
      }

      #default_departure, #default_arrival {
        &:after {
          color: #6F6F70 !important;
          font-weight: 400;
        }
      }
    }
  }

  &__back {
    font-size: 14px;
    margin: 0 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 60px;

    i {
      padding-right: 5px;
    }
  }
}

// @TODO refacto with leaflet css and Traffic (remove !important)
.tooltip__terminus, .tooltip__item_departure, .tooltip__item_arrival {
  padding: 0px !important;
  width: auto !important;
  border-radius: 20px !important;
  border: none !important;
  background-color: transparent !important;

  &-content {
    width: auto !important;
    border-radius: 20px !important;
    padding: 0 20px !important;
    position: relative !important;
    color: #000000 !important;
    top: 0 !important;
    right: 0 !important;
    background-color: #FFFFFF !important;
    font-size: 12px;
  }
}
