//================================ Colors ================================
// Main colors
$color-main-one: #000FA0;
$color-main-two: #3a8dde;
$color-main-three: #ebebeb;
$color-text : #ffffff;
$color-text-two: #6F6F70;
$color-traffic-disrupt: #FF0000;
$color-traffic-warning: #FF7A00;
$color-traffic-info: #0FA958;

// Main computed colors
$color-main-one-dark: darken($color-main-one, 10);

// ================================ Fonts ================================

// Main font
$font-main-one: "Lato", "Helvetica Neue", "Helvetica", "Arial", sans-serif;