.kisio-primary-button {
  text-align: center;
}

a {
  &:hover {
    text-decoration: none;
  }
}

button[class*="kisio-primary-button"] {
  cursor: pointer;
  width: 172px;
  height: 46px;
  text-align: center;
  border-radius: 23px;
  background-color: $color-main-one;
  font-size: 17px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  border: none;
  color: $color-text;
}

button[class*="kisio-primary-button"]:hover {
  width: 176px;
  height: 50px;
  margin-top: -2px;
}

button[class*="kisio-primary-button"]:active {
  background-color: #2f605a;
}

#board_container {
  background-color: #FFFFFF;
  position: absolute;
  top: 130px;
  left: 2%;
  z-index: 999;
  width: 38%;
  box-shadow: 1px 2px 8px 2px rgb(0 0 0 / 25%);
  border-radius: 10px;

  .expanded {
    height: 100vh;

    &::-webkit-scrollbar {
      width: 2px;
      opacity: 0.8;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-main-one;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $color-main-one;
      border-radius: 10px;
    }

    #board_title_container {
      height: 50px;
      border-bottom: 1px solid #e9e9e9;
      margin-bottom: 20px;
    }

    #board_content {
      height: 100%;
      display: flex;
      flex-direction: column;

      #journey_content, .ctp-row, .ctp-col, .journey-search-form, #journey-target-form {
        // height: 100%;
      }

      #journey-target-form {
        &::-webkit-scrollbar {
          width: 2px;
          opacity: 0.8;
        }
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }
      
        &::-webkit-scrollbar-thumb {
          background: $color-main-one;
        }
      
        &::-webkit-scrollbar-thumb:hover {
          background: $color-main-one;
          border-radius: 10px;
        }
      }

      .ctp-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
  
}

#board_container.board_open {
  top: 0px;
  left: 0px;
}

#board_container.board_open_proximity {
  top: 38px;
  left: 35px;

  .expanded_proximity {
    height: 125px;
    border-radius: 20px;
  }
}

#board, #board_footer {
  width: 100%;
  background: #ffffff;
}

#board {
  height: auto;
  border-radius: 10px;

  &.standalone {
    .board_title {
      background-color: $color-main-one;
      color: #FFFFFF;
    }
  }
}

#board_title_container, #title_container, .title_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
  line-height: 1.25;

  em {
    font-size: 20px;
  }

  a {
    color: #000000;
  }

  .ikisio-favorites {
    position: absolute;
    left: 12%;
    top: 25px;
    font-size: 24px;
    color: white;
  }
}

#board_title_container .board_title, #title_container .board_title, .title_container .board_title {
  font-weight: 600;
  padding: 20px;
  float: left;
  margin: 0 3% 0 3%;
  white-space: nowrap;
  padding-bottom: 30px;
  padding-left: 43px;
  border-bottom: 1px solid #e9e9e9;
  cursor: pointer;

  &:last-child {
    margin-right: 4%;
  }

  .section_icon {
    position: absolute;
    left: 30px;
  }
  &:focus-visible {
    outline: auto;
  }
}

.open {
  flex-direction: row !important;
  padding-top: 0 !important;
  background-color: $color-main-one;

  .board_title {
    padding: 10px !important;
    border-bottom: none;
    margin: 0px !important;
    border-radius: 8px 8px 0px 0px;
    flex: 1;
    text-align: center;
    background-color: $color-main-one;
    color: #FFFFFF;
    border-bottom: none !important;

    .subtitle {
      display: none !important;
    }

    .under_subtitle {
      display: none !important;
    }
  }

  .ikisio-back {
    color: #FFFFFF;
    margin: 15px;
    cursor: pointer;
  }
}

#board_title_container .board_title {
  .subtitle {
    font-size: 16px;
    color: $color-text-two;
    display: block;
    line-height: 24px;
    font-weight: 400;
  }

  .under_subtitle {
    font-size: 16px;
    color: $color-text-two;
    display: block;
    line-height: 24px;
    font-weight: 400;
  }
}


#board_title_container, #title_container, .title_container {
  .selected {
    color: $color-main-one;
    background-color: #ffffff;
    margin: 0px;
    padding-left: 20%;
    padding-top: 26px;
    cursor: pointer;
    padding-bottom: 24px;
    // border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    &#favorites_title, &#proximity_title, &.proximity_title {
      color: #FFFFFF;
      background-color: $color-main-one;
    }
  }
}

#board_title_container .title_alone, #title_container .title_alone, .title_container .title_alone {
  width: 88%;
  border-bottom: none;
  font-size: 21px;

  &:hover {
    border-bottom: none;
    cursor: default;
  }
}

#from_to {
  text-align: center;
}

#path {
  font-size: 12px;
  padding: 5px;
  overflow-y: scroll;
  height: 190px;
}

.selector {
  color: red;
}

.blinking {
  animation: fade 1s infinite alternate;
}

#board_content {
  > #journey_content, > #schedule_content, > #traffic_content {
    display: none;
  }
}

#board_footer {
  display: flex;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  .footer_content {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    text-align: center;
    color: #ffffff;
    background-color: $color-main-one;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    font-size: 13px;
    font-weight: bold;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    &.footer_button_alone {
      width: 100%;
      display: flex;
      align-items: center;

      > em {
        width: 100%;
        margin: auto;
        font-size: 2rem;
        padding-right: 8px;
        text-align: right;

        &.ikisio-traffic {
          margin-bottom: 1rem;
        }
      }

      > span {
        width: 100%;
        margin: auto;
        padding-left: 8px;
        text-align: left;
        font-size: 13px;
      }
    }

    > .ikisio {
      font-size: 1rem;
      margin: -4px 5px 0 0;
    }

    &:focus-visible {
      outline: auto;
    }
  }

  #footer_traffic, #footer_proximit {
    text-transform: capitalize;
  }

  #footer_proximity {
    .title {
      color: $color-main-one;
    }
  }

  #footer_traffic:hover:not(.footer_button_alone) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  #footer_favorites {
    .img-favorite-container {
      width: 20px;
      height: 20px;
      background: $color-main-one;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      border-radius: 50%;
      margin: -4px 10px 0 0;

      img {
        width: 14px;
        height: 14px;
      }
    }
  }
}

.journey-search-autocomplete {
  position: relative;
}

#img-journey-form-reverse {
  color: $color-main-one;
  font-size: 0.9rem;

  &:hover, &:active {
    color: $color-main-one-dark;
  }
}

#journey-search-reverse {
  z-index: 100;
  position: absolute;
  right: 60px;
  top: 42%;
  background-color: #ffffff;

  #journey-form-reverse {
    width: 30px;
    height: 30px;
    display: flex;
    align-content: center;
    justify-content: center;
    border: solid 3px $color-main-two;
    border-radius: 11px;

    .icon-journey-form-reverse {
      display: flex;
      align-items: center;
    }

    em {
      color: $color-main-two;
      font-size: 16px;
      font-weight: 700;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

#search_from, #search_to {
  position: relative;
  border: solid 1px rgba(37, 25, 66, 0.5);
  border-radius: 50px;
  margin: 3% 5% 0 5%;
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;

  label {
    padding-left: 2%;
    display: inline-flex;
  }

  input {
    overflow: visible;
    outline: none;
    text-overflow: ellipsis;
    background-color: transparent;
    border: none;
    height: 34px;
    width: 70%;
  }
}

#search_to {
  margin: 1% 5% 0 5%;
}

#journey-search-board-modes {
  text-align: center;
  box-shadow: none;
  z-index: 9999;
  position: relative;
  padding: 5px 0 5px 0;
  width: 90%;
  margin: auto;

  #date-time-block {
    width: 100% !important;
  }

  #date_time_container {
    width: 100%;

    #time-ui-accordion-header-text {
      width: 100%;
      display: flex;
      justify-content: flex-start;

      #selected_date {
        margin-left: 20px;
      }

      #date_time_at {
        color: #FFFFFF;
        margin-left: 15px;
        margin-right: 15px;
      }

    }
  }


}

.ui-accordion-header {
  cursor: pointer;
  font-size: 15px;
  font-stretch: normal;
  line-height: 1.25;
  font-weight: 600;
  padding: 15px;
  margin: 2% 0 0 0;
  border-radius: 10px;
  height: auto;
  min-height: 33px;
  background-color: $color-main-one;
  color: #ffffff;

  &:first-child {
    // margin-right: 2%;
  }

  > div:first-child {
    font-size: 17px;
    width: 23px;
    margin-right: 20px;
  }

  .ikisio-calendar-main {
    color: #ffffff;
  }

  .ikisio-preferences-new {
    color: #ffffff;
    font-size: 10x;
  }

  &__name {
    width: 100%;
    text-align: start;
    margin-left: 20px;
  }
}

#journey-search-board-modes .ui-accordion-header {
  display: flex;
  align-items: center;
  height: 50px;
  margin-top: 30px;
  @supports not (-ms-ime-align: auto) {
    justify-content: flex-start;
  }
}

.journey-search-board-modes-selected {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

#journey-form-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-top: 30px;

  button {
    cursor: pointer;
    width: 172px;
    height: 46px;
    text-align: center;
    border-radius: 23px;
    background-color: $color-main-two;
    font-size: 17px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    border: none;
    color: $color-text;

    &:hover {
    //   width: 176px;
    //   height: 50px;
      margin-top: 0;
    }

    &:active {
      background-color: $color-main-one-dark;
    }
  }
  .favorites_standalone_link {
    margin-right: 25px;
  }
}

#kisio-result-col.search-mode {
  height: calc(100% - 55px);
}

#journey-form-new-search {
  display: none;
  text-align: center;
  width: 100%;
  margin-bottom: 66px; // margin from botom, return link of heigh 24px included
  button {
    padding: 0 70px;
  }
}
.journey-form-modify {
  text-align: center;
  padding-bottom: 5%;
  padding-top: 10px;
  height: 80px;

  button {
    cursor: pointer;
    width: 172px;
    height: 46px;
    text-align: center;
    border-radius: 23px;
    background-color: $color-main-one;
    font-size: 17px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    border: none;
    color: $color-text;

    &:hover {
      width: 176px;
      height: 50px;
      margin-top: -2px;
    }

    &:active {
      background-color: $color-main-one-dark;
    }
  }

  #cancel_search {
    float: left;
    margin: 5px 0 0 17%;
  }

  .modify-settings {
    background-color: #dddddd;
    border-radius: 23px;
    pointer-events: none;

    .ikisio-clear-field {
      color: #ffffff;
    }
  }

  .reset-settings {
    background-color: #ffffff;
    border-radius: 23px;
    pointer-events: unset;
  }

  button.reset-settings {
    background-color: $color-main-one;
  }

  button.modify-settings {
    background-color: rgb(221, 221, 221);
  }
}

#schedule-form-new-search {
  text-align: center;
  padding-top: 10px;

  button {
    cursor: pointer;
    width: 172px;
    height: 46px;
    text-align: center;
    border-radius: 23px;
    background-color: $color-main-one;
    font-size: 17px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    border: none;
    color: #ffffff;
    margin: 15px 0 0 0;

    &:active {
      background-color: $color-main-one-dark;
    }
  }
}

.autocomplete-label {
  width: 25%;
  margin: 0 3%;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    margin-right: 14px;
  }
}

.autocomplete-clear {
  width: 5%;
  margin: auto;
  margin-right: 20%;
  line-height: 1rem;
  font-size: 1rem;
  margin-left: 5px;
}

#search-from-autocomplete-clear,
#search-to-autocomplete-clear,
#schedule-stop-area-lines-autocomplete-clear {
  color: #B3B5B5;
}

.journey-home {
  &__modes {
    margin: 20px 0;

    &-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 23px;
    }

    &-content {
      margin-top: 25px;
      margin-bottom: 10px;
      margin-right: -12px;

      .label {
        width: 15%;
        margin: 10px auto;
        position: relative;
        &:hover {
          color: $color-main-two;
    
          &:before {
            content: attr(data-tooltip);
            display: none;
            position: absolute;
            padding: 10px 10px;
            top: 120%;
            transform: translateX(-20%);
            width: 100px;
            height: auto;
            background-color: #fff;
            border: 1px solid #251942;
            box-shadow: 0px 0px 2px $font-main-one;
            border-radius: 10px;
            color: $color-main-one;
            font-size: 14px;
            line-height: 1.5;
            font-size: 16px;
            opacity: 1;
            z-index: 999999;
            font-weight: 600;
            text-align: center;
            left: 14%;
          }
    
          &:after {
            content: '';
            display: none;
            position: absolute;
            bottom: -20%;
            border-width: 7px;
            border-style: solid;
            border-color: transparent transparent $color-main-one transparent;
            left: 52%;
          }
    
          &:hover::before {
            display: block;
          }
    
          &:hover::after {
            display: block;
          }
        }
      }

      .label__checkbox {
        display: none;
      }

      .label__check {
        display: inline-block;
        border-radius: 50%;
        border: 2px solid rgba(0, 0, 0, 0.1);
        background: transparent;
        box-shadow: none;
        vertical-align: middle;
        width: 48px;
        height: 48px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: border .3s ease;

        .picto_line {
          font-size: 'calc(1rem + 1vw)';
          color: transparent;
          transition: opacity .3s .1s ease;
          -webkit-text-stroke: 3px rgba(0, 0, 0, .5);
          filter: none;
        }
      }

      .label__text {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: auto 0;
        margin-left: 15px;
      }

      .label__checkbox:checked + .label__text .label__check {
        animation: check .5s cubic-bezier(0.895, 0.030, 0.685, 0.220) forwards;
        border: 1px solid black;
        
        .picto_line {
          opacity: 1;
          transform: scale(0);
          color: white;
          -webkit-text-stroke: 0;
          animation: picto_line .3s cubic-bezier(1.000, 0.008, 0.565, 1.650) .1s 1 forwards;
          filter: brightness(0) invert(1);
        }
      }

      #traveler-profile-container {
        margin-top: 20px;

        #traveler-profile {
          display: flex;
          justify-content: space-between;
          width: 93%;
          margin-left: 15px
        }

        #profiles-blocks {
          margin-bottom: 30px;
          display: flex;
          justify-content: space-between;
        }

        .traveler-profile {
          display: flex;
          justify-content: space-between;
          padding: 15px;

          &__title {
            font-size: 18px;
            padding-left: 15px;
            font-weight: 700;
          }
        }
      }
    }
  }
}

.ui-accordion-content {
  display: none;
  position: relative;
  text-align: justify;
  padding: 0 3%;
  height: 160px;

  &.show {
    background: #FAFAFA;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: #D0D0D0;
    border-radius: 0 0 10px 10px;
    margin-bottom: 50px !important;
    height: 200px;
  }
}

.journey-search-datetime, .journey-search-modes {
  margin: auto;
  text-align: center;
  z-index: 1;
  width: 90%;

  #ui-accordion-content-settings {
    margin: auto;
    top: -15px;
    height: auto;
    position: relative;
    padding-left: 0;
  }
}

.journey-search-modes legend {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.journey-search-modes #modes_content, .journey-search-modes .ctp-row {
  border-radius: 15px;
  margin: 10px 0;
  flex: 0.35;

  .accessibility-profile {
    flex: 0.2 !important;
  }
  .journey-home__modes-content {
    legend {
      font-size: 18px !important;
      padding-left: 15px;
    }
  }
}

.journey-search-modes #modes_content, .journey-search-modes .bike_speed {
  display: inline-block;
}

.journey-search-modes #modes_content {
  width: 100%;
  #transport-modes {
        border-bottom: solid 1px #D0D0D0;
        #labels-container{
          padding-right: 15px;
        }
  }
}


.journey-search-modes ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.journey-search-modes label {
  margin: 0;
}

.journey-search-modes li {
  height: 38px;
  padding: 6px 0 0 0;
  vertical-align: middle;
}

.journey-search-modes #search_modes li, .journey-search-modes #drawdown_modes_0 li {
  border-bottom: solid 1px #f1f1f1;
  display: inline;
}

.journey-search-modes #search_modes li:last-child, .journey-search-modes #drawdown_modes_0 li:last-child {
  border-bottom: none;
}

.journey-search-modes img {
  margin: 3px 6px;
  position: absolute;
  z-index: 1;
  width: 26px;
  height: 26px;
}

.ipt-content {
  width: 90%;
  float: left;
}

.ipt-content .ikisio {
  margin: 0 10px;
}

label.input-toggle {
  line-height: 0;
  font-size: 0;
  display: inline-block;
  margin: 2px 0 0 0;
  padding-right: 20px;
}

label.input-toggle > span {
  display: inline-block;
  position: relative;
  background-image: -webkit-linear-gradient(left, #848484 0%, #848484 50%, $color-main-one 50%, $color-main-one 100%);
  background-image: linear-gradient(to right, #848484 0%, #848484 50%, $color-main-one 50%, $color-main-one 100%);
  background-size: 64px 14px;
  background-position: 0;
  border-radius: 32px;
  width: 32px;
  height: 14px;
  cursor: pointer;
  -webkit-transition: background-position 0.2s ease-in;
  transition: background-position 0.2s ease-in;
}

label.input-toggle > input:checked + span {
  background-position: -100%;
}

label.input-toggle > span:after {
  content: "";
  display: block;
  position: absolute;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

label.input-toggle + span:after {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: solid 1px #6F6F70;
}

label.input-toggle > input:checked + span:after {
  background-color: $color-main-two;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: solid 1px #6F6F70;
}

label.input-toggle > span:active:after {
  -webkit-transform: translateY(-50%) scale3d(1.15, 0.85, 1);
  transform: translateY(-50%) scale3d(1.15, 0.85, 1);
}

label.input-toggle > input:disabled + span:active:after {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

label.input-toggle > input:disabled + span {
  cursor: default;
}

label.input-toggle > input:disabled + span {
  background: #D5D5D5;
}

label.input-toggle > input:disabled + span:after {
  background: #BDBDBD;
}

label.input-toggle > input {
  display: block;
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.ctp-row .ctp-col legend {
  font-size: 14px;
  font-weight: 700;
}

.ctp-row .ctp-col {
  margin: 10px 0 0 0;
}

.ctp-row .ctp-col:first-of-type {
  margin: 0;
}

.traveler_profile_level {
  padding-top: 10px;
}

ul#traveler_type_0, ul#bike_speed_0 {
  list-style-type: none;
  margin: 10px 0 0 0;
}

div#traveler_type_0 input[type="radio"], div#bike_speed_0 input[type="radio"] {
  opacity: 0.01;
  z-index: 100;
}


#traveler-profile {
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
}

.profile-type {
  > div {
    list-style-type: none;
    background: rgba(0, 0, 0, 0.1);
    display: flex;
    border-radius: 15px;
    height: 54px;
    justify-content: center;
    padding: 8px 0;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 4%;
      width: 34px;

      em {
        margin: 0;
      }
    }
  }

  width: 30%;
  display: inline-block;

  .ikisio {
    margin-left: 0px;
    padding: 1px;
  }
}

label.ctp-modes-traveler-type {
  // height: 32px;
  display: inline-block;
  padding: 0;
  overflow: hidden;
  text-align: center;
  line-height: 2.2;
  font-size: 14px;
  font-weight: normal;

  .ikisio {
    font-size: 1.6rem;
    float: left;
  }
}

.ui-accordion-content-now .search_datetime_represents {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px;

  > .input-append {
    > .input-daterange {
      display: flex;
      flex-wrap: nowrap;

      > input, span {
        flex: 1 1 auto;
        width: inherit;
      }

      > .input-group-addon {
        min-width: auto;
        max-width: 30px;
      }
    }
  }

  li {
    padding: 0;
    width: 49%;
  }

  input[type="radio"] {
    opacity: 0.011;
    position: absolute;
    z-index: 100;
  }

  input[type="radio"]:checked + label {
    color: $color-main-one;

    &::after {
      content: "";
      display: block;
      border-bottom: 3px solid $color-main-two;
      width: 30%;
      margin: 0 auto;
    }
  }

  label {
    color: $color-main-two;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    height: 100%;
    padding: 1% 0 1% 0;

    i.icon_datetime_represents {
      font-size: large;
      vertical-align: text-bottom;
      margin-right: 3%;
    }
  }

  p {
    margin: auto;
  }
}

// @TODO remove to journey bundle
#ui-accordion-content-settings {
  .traveler_profile_level {

    > .input-append {
      > .input-daterange {
        display: flex;
        flex-wrap: nowrap;

        > input, span {
          flex: 1 1 auto;
          width: inherit;
        }

        > .input-group-addon {
          min-width: auto;
          max-width: 30px;
        }
      }
    }

    li {
      padding: 0;
      width: 49%;
    }

    input[type="checkbox"], input[type="radio"] {
      display: none;
    }

    input[type="radio"]:checked + label {
      color: $color-main-two
    }

    input[type="checkbox"]:checked + label {
      color: $color-main-two
    }
    
    label {
      text-align: center;
      cursor: pointer;
      color: #6A6969;

      i.icon_datetime_represents {
        font-size: x-large;
        vertical-align: middle !important;
      }
    }

    label:hover {
      color: $color-main-two;

      &:before {
        content: attr(data-tooltip);
        display: none;
        position: absolute;
        padding: 10px 10px;
        transform: translateX(-40%);
        background-color: #fff;
        border: 1px solid #251942;
        box-shadow: 0px 0px 2px $font-main-one;
        border-radius: 10px;
        color: $color-main-one;
        font-size: 14px;
        line-height: 1.5;
        font-size: 16px;
        font-weight: 600;
        max-width: 200px;
        bottom: 0;
      }

      &:after {
        content: '';
        display: none;
        position: absolute;
        border-width: 7px;
        bottom: 45px;
        border-style: solid;
        border-color: transparent transparent $color-main-one transparent;
      }

      &:hover::before {
        display: block;
      }

      &:hover::after {
        display: block;
      }
    }

    p {
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 2%;
    }
  }

  .accessibility {
    justify-content: space-around !important;
  }
}

.popup {
  position: fixed;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.popup .popuptext {
  width: 300px;
  background-color: #FFFFFF;
  color: #ff0000;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  // /!\ Est aussi utiliser pour l'affichage d'erreur dans la recherche de journey
  top: -12px;
  z-index: 1;
  left: 50%;
  margin-left: -80px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 15px -4px #000;
}

.popup .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #FFFFFF transparent transparent transparent;
}

.popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

.error-message {
  outline: 1px solid #FF0000;
}

#journey-search-board-modes-settings {
  position: relative;
  margin-top: 10px !important;

  #preferences-text {
    width: 100%;
    padding-left: 20px;
    text-align: left;
  }
}

#notification {
  display: none;
  width: 14px;
  height: 15px;
  border-radius: 7px;
  border: solid 3px #ffffff;
  position: absolute;
  right: 40px;
  top: -8px;
  background-color: $color-main-two;
}

.locate-position,
.locate-position-proximity {
  position: absolute;
  right: 14px;
  cursor: pointer;
  background: transparent;
  z-index: 9999;
}

.location-autocomplete {
  width: 90%;
  background: #FFFFFF;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 25%);
  border-radius: 12px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 10px;
  position: relative;
  margin-left: 29px !important;
  margin-top: 20px !important;
}

#location-text {
  font-weight: 600;
  margin-left: 10px;
}


// IE10+ & Edge Removing Browser Clear Button icon
input::-ms-clear {
  display: none;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .ui-accordion-header {
    text-align: center;
  }
  #journey-search-board-modes .ui-accordion-header {
    justify-content: space-between;
  }
  .autocomplete-label {
    padding-top: 5px;
  }
  .autocomplete-clear {
    padding-top: 8px;
  }
  #ui-accordion-content-settings {
    .traveler_profile_level {
      .ctp-modes-traveler-type {
        padding-left: 0;
        text-align: center;

        .ikisio {
          float: none;
          margin-right: 5px;
          display: inline-block;
        }
      }
    }
  }
}

@supports (-ms-ime-align: auto) {
  /* Edge specific styles go here */
  #ui-accordion-content-settings {
    .traveler_profile_level {
      .ctp-modes-traveler-type {
        padding-left: 0;
        text-align: center;

        .ikisio {
          float: none;
          margin-right: 5px;
          display: inline-block;
        }
      }
    }
  }
  #journey-search-board-modes .ui-accordion-header {
    display: block;
    text-align: center;

    > div:first-child, #notification + div {
      float: left;
    }

    > div:last-child {
      float: right;
      margin-top: 3px;
    }
  }
}

#geolocate-img {
  margin: 20px 0;
}

#geolocate_buttons {
  clear: both;
  width: 100%;
  margin-top: 20px;
  height: 50px;

  button:first-of-type {
    cursor: pointer;
    width: 172px;
    height: 46px;
    text-align: center;
    border-radius: 23px;
    background-color: #ffffff;
    border: solid 1px $color-main-one;
    font-size: 17px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    color: $color-main-one;
    margin-right: 20px;

    &:hover {
      width: 176px;
      height: 50px;
      margin-top: -2px;
    }
  }

  button:last-of-type {
    cursor: pointer;
    width: 172px;
    height: 46px;
    text-align: center;
    border-radius: 23px;
    background-color: $color-main-one;
    font-size: 17px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    border: none;
    color: #ffffff;

    &:hover {
      width: 176px;
      height: 50px;
      margin-top: -2px;
    }

    &:active {
      background-color: $color-main-one-dark;
    }
  }
}

.geolocation-map-button {
  background: url('/public/bundles/canaltppnpcore/images/icon-localisation.png') no-repeat 6px 2px; // need relative path
  width: 30px;
  height: 30px;
  background-size: 65%;
  display: block;
}

.leaflet-control-locate a {
  font-size: 1.4em;
  color: #444;
  cursor: pointer;
}

.leaflet-control-locate.active a {
  color: #2074b6;
}

.leaflet-control-locate.active.following a {
  color: #fc8428;
}

.leaflet-control-locate-location circle {
  animation: leaflet-control-locate-throb 4s ease infinite;
}

@keyframes leaflet-control-locate-throb {
  0% {
    stroke-width: 1;
  }
  50% {
    stroke-width: 3;
    transform: scale(0.8, 0.8);
  }
  100% {
    stroke-width: 1;
  }
}

#geolocate-second-step {
  display: none;
}

@media (orientation: landscape) and (max-height:600px) {
  #board_container {
    top: 20px;

    #board_title_container {
      display: flex;
      flex-flow: column;
      justify-content: space-evenly;
      height: 100%;

      .board_title {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}

@media (orientation: landscape) and (max-height:464px) {
  #board_container {
    // For zoom 200%
    // top: 0 !important;
    // left: 0 !important;
    // height: 100vh;
    // overflow: scroll;

    display: flex;
    flex-flow: column;

    #board {
      flex: 1 1 auto;
    }

    #board_footer {
      flex: 0 1 auto;
    }

    .board_title {
      padding-top: 15px;
      padding-bottom: 20px;
    }
  }
}