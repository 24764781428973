@keyframes picto_line {
    from {
        opacity: 0;
        transform: scale(0.3);
    }

    to {
        opacity: 1;
        transform: scale(1)
    }
}

@keyframes check {
    0% {
        border-width: 5px;
    }

    10% {
        opacity: 0.1;
        background: rgba(0, 0, 0, 0.2);
        border-width: 15px;
    }

    12% {
        opacity: 0.4;
        background: rgba(0, 0, 0, 0.1);
        border-width: 0;
    }

    50% {
        background: $color-main-two;
        opacity: 0.6;
    }

    100% {
        background: $color-main-two;
        border: 1px solid black;
        opacity: 1;
    }
}