/*Small devices (320px and up until 600px, excluded)*/
@media (max-width: 599.98px) {
  #board_container {
    top: auto;
    position: absolute;
    width: 100%;
    min-width: 320px;
    left: 0;
    height: auto;
    // overflow: auto;
    bottom: 0 !important;
    font-size: 16px;
    border-radius: 0;
    .expanded {
      height: 100%;
    }

    &.board_open {
      top: 5%;
    }

    .board_title{
      font-size: 15px;

      .subtitle {
        line-height: 24px;
        width: 250px;
        white-space: normal;
        br {
          display: none;
        }
      }

      .under_subtitle  {
        line-height: 24px;
      }

      &:last-child {
        padding-bottom: 51px;
        .subtitle {
          display: none;
        }
      }
    }

    #footer_favorites {
      text-transform: capitalize;
      justify-content: center;
      height: 67px;
      margin-bottom: -20px;
      span {
        margin-left: 0px !important;
        font-size: 16px;
      }
      .ikisio-favorites {
        margin-left: 0px !important;
      }
    }

    #footer_proximity {
      padding-bottom: 30px;
      .subtitle {
        display: block;
        font-size: 16px;
        color: $color-text-two;
        display: block;
        line-height: 24px;
      }
    }

    .subtitle {
      display: none;
    }
  }

  #board, #board_footer {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ipt-content {
    width: 85%;
  }
  .leaflet-control-zoom {
    display: none;
  }
  #board_footer {
    margin-top: 0;
    border-radius: 0;
    box-shadow: none;
    height: auto;
    margin-bottom: 20px;
    .footer_content {
      border-radius: 0px;
    }
  }
  .board_footer_separator {
    border-top: 1px solid #e9e9e9;
    margin-bottom: 5px;
  }
  #datepicker input {
    width: 82%;
  }
  .timepicker-select {
    width: 86%;
  }
  #precision_content{
    .precision-back-button {
      margin: 2% 0 2% 3%;
    }
  }

  #journey-search-board-modes .ui-accordion-header {
    > div:first-child {
      float: left;
      position: relative;
    }
    > div:last-child {
      margin-right: 7px;
    }
    .ikisio-settings{
      position: relative;
      top: 12px;
    }
    #journey-search-reverse {
      right: 15%;
    }
  }

  #walking_speed_0 .traveler_profile_level{
    label {
      padding-left: 0;
    }
  }

  #journey-search-board-modes-settings{
    #preferences-text {
      padding-left: 20px;
    }
    #preferences-icon{
      float: left;
      margin-right: 6px;
      margin-left: 15px;
      position: relative;
    }
  }

  #notification{
    right: 50px;
  }

  #board_footer .footer_content > .ikisio {
    margin-right: 10px;
  }
  #journey-form-submit {
    flex-flow: column-reverse;
    text-align: center;
    padding-bottom: 5%;
    position: relative;
    margin-top: 30px;
    width: 100%;

    .favorites_standalone_link {
      margin-right: 0;
      margin-top: 10px;
    }
  }

  @media (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /* IE10+ specific styles go here */
    #journey-search-board-modes .ui-accordion-header {
      justify-content: space-between;
    }
  }

  .locate-position, 
  .locate-position-proximity {
    margin: 6px 1% 0 0;
  }
  .datepicker-dropdown {
    top: 70% !important;
    left: 10% !important;
    min-width: 17rem !important;
  }
  
  #search_from input, #search_to input {
    width: 60%;
  }
  .autocomplete-clear {
    margin-right: 20%;
  }
  #ctp-proximity .autocomplete-clear{
    margin-right: 21%;
  }
  #ctp-proximityPoiForm legend .check_all {
    margin-right: 9%;
  }
  #proximity-search-time label {
    padding-left: 1%;
  }
  #ctp-proximityPoiForm legend .arrow-ikisio {
    margin: 3px 5px 0 0;
  }
  
  #proximity-search-time label {
    padding: 2px 0 0 0;
  }
  #next_departures_results #prev_next #schedule-datepicker-bloc #schedule-datetime-body #schedule_from_datetime > .input-append > .input-group .schedule-timepicker {
    text-align: left;
    padding: 0 0 0 5px;
  }
  #kisio-full-details .kisio-co2-comparator .kisio-co2-car-compare .kisio-co2-value .kisio-co2-value-unit{
    min-width: 33%;
  }
  .journey-form-modify #cancel_search, #journey-form-new-search #cancel_search {
    float: left;
    margin: 10px 0 0 4%;
  }
  .search_datetime_represents p {
    padding-left: 10%;
    padding-right: 2%;
  }
  .kisio-result-toolbox{
    display: none;
  }

  .kisio-section-disruptions {
    padding: 0 19%;
  }
  .kisio-btn-social {
    iframe {
      min-height: 500px;
    }
  }
  .kisio-show-result{
    max-height: 310px;
  }
  #ctp-searchScheduleResults{
    max-height: 280px;
  }
  #geolocate_buttons{
    button{
      width: 123px !important;
    }
  }
  #button_action{
    display: inline-flex;
    align-items: center;
    #change_direction{
      display: inline-flex;
    }
    a{
      align-items: center !important;
      &:first-child{
        margin-left: 0 !important;
      }
      &:last-child{
        margin-right: 0 !important;
      }
    }
  }
  #label-schedule-datetime{
    padding-top: 12px !important;
  }
  .board_open_proximity {
    left: 0 !important;
  }
  #proximity_filters_content .proximity_filters_validate {
    position: fixed;
  }

  // fix autocomplete
  .ui-autocomplete .title {
    margin-left: 10px !important;
  }

  .favorites_standalone_link.map,
  #board_container[data-section='proximity'] ~ .favorites_standalone_link {
    display: none;
  }

  .favorites_standalone_link .ikisio-arrow-right {
    display: none !important;
  }

  .favorites_standalone_link.button.schedule {
    margin-top: 20px !important;
  }
  .favorites_standalone_link.map {
    .favorites_standalone_link_content {
      align-items: center;
      font-size: 16px;

      em {
        font-size: 17px;
        line-height: 22px;
        margin-right: 3px;
      }
    }
  }

  #ctp-favorites .transport-tab-content .shared-item .content .address {
    min-width: 100px !important;
  }

  @import "../base/proximity/small_proximity";
}

@media (max-width: 400px) {
  #ctp-favorites .transport-tab-content .shared-item .content .parent-slot {
    flex-flow: column !important;
  }
  
}