.favorites-full {
  padding: 40px 40px;
}

#bookmark_title_container {
  display: flex;
  background-color: $color-main-one !important;
  height: 46px;
  border-radius: 16px 16px 0 0;

  .bookmark_title {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    text-align: center;
    padding: 11px 0;
    flex: 1;
    cursor: pointer;
  }
  .bookmark_title.selected {
    background: $color-main-two;
    border-radius: 16px 16px 0px 0px;

  }
}