/* required styles */
@import "../base/variables";

.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0;
}

.leaflet-container {
  overflow: hidden;
}

.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}

/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0;
}

.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block;
}

/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer,
.leaflet-container .leaflet-tile {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  /* Fallback for FF which doesn't support pinch-zoom */
  touch-action: none;
  touch-action: pinch-zoom;
}

.my-tooltip {
	background-color: red;
	color: white;
	width: 200px;
	border-radius: 10px;
	text-align: center;

}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
  -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  width: 0;
  height: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 800;
}

/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}

.leaflet-overlay-pane {
  z-index: 400;
}

.leaflet-shadow-pane {
  z-index: 500;
}

.leaflet-marker-pane {
  z-index: 600;
}

.leaflet-tooltip-pane {
  z-index: 650;
}

.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}

.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url(#default#VML);
  display: inline-block;
  position: absolute;
}


/* control positioning */

.leaflet-control {
  position: relative;
  z-index: 800;
  pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 900;
  pointer-events: none;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-top .leaflet-control {
  margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-right .leaflet-control {
  margin-right: 10px;
}


/* zoom and fade animations */

.leaflet-fade-anim .leaflet-tile {
  will-change: opacity;
}

.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-animated {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: transform;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
}

.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}


/* cursors */

.leaflet-interactive {
  &:focus-visible {
    outline: auto !important;
  }
  cursor: pointer;
}

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}

.leaflet-popup-pane,
.leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

/* marker & overlays interactivity */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive {
  pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

/* visual tweaks */

.leaflet-container {
  background: #ddd;
  outline: 0;
}

.leaflet-container a {
  color: #0078A8;
}

.leaflet-container a.leaflet-active {
  outline: 2px solid orange;
}

.leaflet-zoom-box {
  border: 2px dotted #38f;
  background: rgba(255, 255, 255, 0.5);
}


/* general typography */
.leaflet-container {
  font: 12px/1.5 $font-main-one;
}


/* general toolbar styles */

.leaflet-bar {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
}

.leaflet-bar a,
.leaflet-bar a:hover {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}

.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

/* zoom control */

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: bold 18px 'Lucida Console', Monaco, monospace;
  text-indent: 1px;
}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}


/* layers control */

.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 5px;
}

.leaflet-control-layers-toggle {
  // background-image: url(../images/layers.png);
  width: 36px;
  height: 36px;
}

.leaflet-retina .leaflet-control-layers-toggle {
  // background-image: url(../images/layers-2x.png);
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff;
}

.leaflet-control-layers-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  display: block;
}

.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px;
}

/* Default icon URLs */
.leaflet-default-icon-path {
  // background-image: url("../../images/marker-marker-from.png");
}


/* attribution and scale controls */

.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  margin: 0;
}

.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover {
  text-decoration: underline;
}

.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
  font-size: 11px;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  background: #fff;
  background: rgba(255, 255, 255, 0.5);
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  box-shadow: none;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}


/* popup */

.leaflet-popup {
  text-align: center;
  width: 280px;
  margin-left: 20px;
  margin-top: -20px;
}

.leaflet-popup-content-wrapper {
  padding: 0;
  text-align: left;
  border-radius: 12px;
}

.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  overflow: hidden;
  pointer-events: none;
}

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;

  margin: -10px auto 0;

  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  color: #333;
}

.leaflet-container a.leaflet-popup-close-button {
  &:focus-visible {
    outline: auto !important;
  }
  position: absolute;
  border: none;
  text-align: center;
  color: #ffffff;
  font-size: 25px;
}
.leaflet-container a.leaflet-popup-close-button:hover {
  color: #999;
}

.leaflet-popup-scrolled {
  overflow: auto;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;

  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
}

.leaflet-oldie .leaflet-popup-tip-container {
  margin-top: -1px;
}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

/* Tooltip */
/* Base styles for the element that has a tooltip */
.leaflet-tooltip {
	position: absolute;
	display: flex;
    width: 80px;
    padding: 9px 6px;
    justify-content: space-between;
	background-color: #fff;
	border: 1px solid #fff;
	border-radius: 10px;
	color: #222;
	white-space: nowrap;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	pointer-events: none;
	box-shadow: 0 1px 3px rgba(0,0,0,0.25);
	span {
		display: flex;
		justify-content: center;
		align-items: center;
		right: 7px;
		top: 8px;
		text-align: center;
		font-size: 15px;
		width: 30px;
		border-radius: 5px;
		font-weight: bolder;
		color: #F1F1F1 !important;
	}
	img {
		vertical-align: initial;
	}
}

.terminus-tooltip {
	text-align: center;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	min-width: fit-content;
	padding: 5px;
	border-radius: 10px;
}
.leaflet-tooltip.leaflet-clickable {
	cursor: pointer;
	pointer-events: auto;
	.disruption-type {
		width: 10px;
	}
	}

/* Directions */

.leaflet-tooltip-bottom {
  margin-top: 6px;
}

.leaflet-tooltip-top {
  margin-top: -14px;
}

.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
  left: 50%;
  margin-left: -6px;
}

.leaflet-tooltip-bottom:before {
  top: 0;
  margin-top: -12px;
  margin-left: -6px;
  border-bottom-color: #fff;
}

.leaflet-tooltip-left {
  margin-left: -6px;
}

.leaflet-tooltip-right {
  margin-left: 6px;
}

.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  top: 50%;
  margin-top: -6px;
}

.leaflet-tooltip-left:before {
  right: 0;
  margin-right: -12px;
  border-left-color: #fff;
}

.leaflet-tooltip-right:before {
	left: 0;
	margin-left: -12px;
	border-right-color: #fff;
	}

#leaflet-popup{
	width: 300px;
	height: auto;
	border-radius: 10px;
	background-color: #ffffff;
}
#leaflet-popup-header{
	width: 100%;
	background-color: $color-main-one;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	font-size: 15px;
	color: #ffffff;
	padding: 6px 12px;
	display: table;
}
#leaflet-popup-header .ikisio-address{
	font-size: 32px;
	margin: 0 10px;
	width:40px;
}
#leaflet-popup-header > div{
	vertical-align:middle;
	display: table-cell;
	font-family: $font-main-one;
}
#leaflet-popup-header > div:last-child{
	display: block;
	margin: 0 10px 0 0;
}

#leaflet-popup-content{
	min-width: 300px;
	padding: 10px;
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #3c3c3c;
	min-height: 54px;
	.departure-container {
		display: flex;
		padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;
    margin-left: 14px;
    font-size: 12px;
    img {
      margin-right: 5px;
    }
		#routes {
			font-weight: 600;
			width: 60%;
      display: flex;
      align-items: center;
      font-size: 12px;
			em {
				margin-right: 13px;
			}
		}
		.times_container {
			display: flex; 
			align-items: center; 
			justify-content: center; 
			background-color: #F1F1F1;
			padding: 2px 12px;
			border-radius: 20px;
			width: 40%;
			text-align: center;
		}
	}
}

#leaflet-popup-header .ikisio-address {
  font-size: 32px;
  margin: 0 10px;
  width: 40px;
}

#leaflet-popup-header > div {
  vertical-align: middle;
  display: table-cell;
  font-family: $font-main-one;
}

#leaflet-popup-header > div:last-child {
  display: block;
  margin: 0 10px 0 0;
}

#leaflet-popup-content {
	button {
      &:focus-visible {
        outline: auto !important;
      }
      display: flex;
      align-items: center;
      width: 120px;
      height: 34px;
      float: left;
      margin: 0 10px;
      font-size:14px;
      background: #FFFFFF;
      box-shadow: 0px 4px 4px -2px rgba(37, 25, 66, 0.5);
      border-radius: 50px;
      border: none;
      font-weight: 600;
      justify-content: center;
      > span {
          font-family: $font-main-one;
      }
    > span:last-child {
      margin-left: 5px;
    }
		&:hover {
			cursor: pointer;
      background-color: $color-main-one;
      color: #ffffff;
		}
	}
	#popup-proximity-btn {
		margin-top: 0.5rem;
		width: 270px;
		height: auto;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}
}

.label-map-marker-from{
  background-color: #D34D58;
  border-color: #D34D58;
  color: #ffffff;
  text-transform: capitalize;
  position: absolute;
  top: -40px;
  z-index: 501;
  display: inline;
  text-align: center;
}

.label-map-marker-to{
  background-color: #26A65B;
  border-color: #26A65B;
  color: #ffffff;
  text-transform: capitalize;
  position: absolute;
  top: -40px;
  z-index: 501;
  display: inline;
  text-align: center;
}

.line-tooltip {
	&__home {
		padding: 4px;
		width: 62px;
		box-shadow: rgba(0, 0, 0, 0.25) 0 4px 4px;

		span {
			font-size: 15px;
			width: 24px;
			font-weight: bold;
			height: 24px;
		}
	}
}

.popup-info-line {
	margin: 0;
}

.leaflet-popup-content {
    margin: 0;
    line-height: 1.4;

	#buttons_container {
		display: flex;
		margin-bottom: 15px;
		justify-content: space-between;
		align-items: center;
		width: 100%;
    padding-left: 24px;

		#fav {
			width: 30px;
			height: 30px;
			text-align: center;
			border-radius: 50%;
			padding-top: 3px;
			box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
      display: flex;
      align-items: center;
      justify-content: center;

			em {
				font-size: 20px;
			}
		}
	}

    p {
      margin: 18px 0;
    }
}

#leaflet-popup-content {
	table {
		width: 100%;
		font-size: 11px;
		margin: 10px 0;
		td:first-of-type {
			width: 65%;
			background: white;
		}
		td:last-of-type {
			float: right;
			background: white;
		}
	}

	.realtime {
		width: 15px;
	}

	.picto_line {
		height: 24px;
	}
}

.journey-line-container {
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  overflow: hidden;
  padding: 7px;
  width: max-content;
  height: 38px;

  .picto-line{
    display: inline-block;
    font-size: 16px;
    float: left;
    min-width: 24px;
    font-size: 23px;
    padding: 0;
    overflow: hidden;
    height: 26px;
    line-height: 1;
    width: 26px;

    img {
      display: flex;
    }
  }

  .lines-logo {
    width: 24px;
    position: relative;
    left: 4px;
  }

  .line-code{
    display: inline-block;
    line-height: 2;
    min-width: 24px;
    text-align: center;
    border-radius: 5px;
    font-family: "Source Sans Pro", "Lato", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-weight: bold;
    margin-left: 2px;

    .line {
      padding: 1px;
    }

    .code {
      padding: 4px;
      border-radius: 5px;
    }
  }
}

.popup-info-line {
  display: inline-block;
  border: none;
  border-radius: 3em;
  text-align: center;
  line-height: 1.2em;
  min-width: 1em;
  padding: 0.2em 0.3em;
  float: left;

  .commercial_mode {
    background-color: white;
    padding: 3px;
    border-radius: 3px;
    margin-right: 4px;
    margin-left: -10px;
  }

  .line {
    border-radius: 3px;
    padding: 3px 8px;
    margin-right: 6px;
  }

    .picto_line {
      height: 24px;
    }
}

.via-container {
  display: flex;
  position: relative;
  background: #FFFFFF;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 7px;
  height: 30px;

  .picto-via{
    font-size: 15px;
    padding-right: 5px;
  }

  .adresse-via {
    width: fit-content;
    white-space: nowrap;
    padding-right: 7px;
  }
}

.icon-via-container::after {
  content: "";
  position: absolute;
  top: 30px;
  left: 9px;
  margin-top: -6px;
  border-style: solid;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
  transform: translateY(-50%) translateX(6px);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}