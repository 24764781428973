.leaflet-popup-content img.picto {
  vertical-align: middle;
  height: 1.6em;
  margin-right: 5px;
  float: left;
}

.custom {
  max-width: 215px;
}

.lines_in_popup {
  cursor: pointer;
  height: auto;
  min-height: 30px;
  line-height: 1;
  margin: 0 0 5px 0;
}

.popup_lines {
  cursor: pointer;
  min-height: 25px;
  border-radius: 2px;
  margin: 0 10px 5px 4px;
  font-weight: bold;
  line-height: 2;
  float: left;
  height: auto;
  display: block;
  min-width: 24px;
  text-align: center;
  font-size: 12px;
  clear: both;

  .icon-mode {
    float: left;
  }

  .icon-autocomplete-type {
    padding: 0 5px 0 0;
    float: left;
  }

  img {
    float: left;
  }

  span {
    font-weight: bold;
    line-height: 2;
    float: left;
    height: 24px;
    display: block;
    min-width: 24px;
    text-align: center;
    border-radius: 3px;
    font-family: Arial, sans-serif;
  }
}

#error_popup #myPopup {
  margin-left: 80px;
  margin-top: -75px;
  width: 250px;
  padding: 8px;

  &.popuptext-en {
    margin-top: -45px;
  }

  .ikisio {
    margin-right: 8px;
  }
}

#ctp-searchSchedule, .disruption-line-info {
  li {
    list-style: none;
  }

  .schedule-line {
    display: flex;
    width: 98%;
    justify-content: space-between;
    align-self: flex-start;
    margin-bottom: 1%;
  }

  .schedule-line-mode {
    margin-bottom: auto;
    margin-top: auto;
    display: flex;
    align-self: flex-start;
    width: auto;

    img {
      margin-right: 5px;
      max-height: 24px;
    }

    .picto_line {
      max-height: 24px;
    }

    .lines {
      border-radius: 2px;
      font-weight: bold;
      line-height: 2;
      float: left;
      height: 24px;
      max-height: 24px;
      display: block;
      min-width: 24px;
      text-align: center;
      font-size: 12px;
      padding: 0 5px;

      img {
        float: left;
      }

      span {
        padding: 0 5px;
        font-weight: bold;
        line-height: 2;
        float: left;
        height: 24px;
        display: block;
        min-width: 24px;
        text-align: center;
        border-radius: 3px;
        font-family: Arial, sans-serif;
      }
    }
  }

  .icon-schedule-line-type {
    padding: 0 !important;
    width: auto !important;
    float: left;
    font-size: 23px;
    height: 27px;
  }

  .schedule-line-code {
    padding: 2px 5px 2px 5px;
    margin: 0 15px 0 4px;
    font-size: 10px;
    font-weight: bold;
    line-height: 2;
    height: 25px;
    min-width: 24px;
    text-align: center;
    border-radius: 3px;
    font-family: Arial, sans-serif;
  }

  .icon-schedule-line-direction {
    margin: 4px 10px 0 10px;
  }

  .schedule-physical-mode {
    background-color: #dbdbdb;
    margin-bottom: 0;
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    padding: 0.8% 0 0.5% 3%;
  }

  .schedule-line-network {
    font-size: 9px;
    font-weight: 900;
    line-height: 2.22;
    letter-spacing: normal;
    text-align: left;
    color: #3c3c3c;
    width: auto;
    float: right;

    p {
      margin-bottom: 0;
      background-color: #dbdbdb;
      padding-left: 4px;
      padding-right: 4px;
      clear: both;
      float: left;
      border-radius: 2px;
    }
  }

  .title {
    font-size: 14px;
    color: #3c3c3c;
    margin-top: auto;
    margin-bottom: auto;
  }

  .schedule_by_line_temp {
    width: 100%;
    text-align: center;

    img {
      width: 20px;
    }
  }

  .schedule_by_line {
    font-size: 11px;
    width: 100%;
    font-weight: bold;
    height: auto;
    line-height: 20px;

    .fav-star {
      float: right;
      margin-left: 20px;
    }

    br {
      clear: both;
    }

    span {
      float: left;
      width: 65%;
      padding: 0;
      text-decoration: underline;
    }

    span:hover {
      text-decoration: none;
      color: $color-main-one;
    }

    b {
      float: right;
    }

    img {
      width: 10px;
      vertical-align: top;
    }
  }

  .no_schedule {
    width: 100%;
    text-align: center;
    font-size: 10px;
  }

  .kisio-show-result {
    box-shadow: inset 0 5px 12px -6px rgba(101, 101, 101, 0.4);

    #ctp-linesSearchResultsContainer {
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        height: 15px;
        bottom: 0;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      #ctp-searchScheduleResults {
        padding: 10px 5% 0 5%;
        margin: 0;

        li {
          cursor: pointer;
          margin: 0;
          padding: 5px 0;

          .letter, .label {
            line-height: 30px;
          }

          .letter {
            font-size: 27px;
            font-weight: bold;
            float: left;
            width: 12%;
            color: #3c3c3c;

            .schedule-disruption {
              width: 14px;
              float: right;
              transform: translate(-50%, 60%);
            }
          }
        }

        li.selected {
          background-color: #65C400;
          cursor: pointer;
        }

        > div:first-of-type {
          margin-top: 10px;
        }
      }
    }
  }
}

#ctp-lineScheduleLineInfos ul {
  padding-left: 15px;
  margin: 0;
}

#ctp-lineSchedule {
  display: none;

  .result-back-button,
  #ctp-lineScheduleLineButtons {
    width: 100%;
    padding: 15px 0;

    button {
      height: 32px;
      border: solid 1px $color-main-one;
      color: $color-main-one;
      border-radius: 16px;
      padding: 0 10px 0 10px;
      background: #fff;
      font-size: 0.8em;

      span {
        font-family: $font-main-one;
      }
    }

    button:hover {
      background-color: #f5f5f5;
      cursor: pointer;
    }

    button.left {
      float: left;
      margin-right: 10%;
    }

    .two_buttons {
      width: 45%;
    }
  }

  .kisio-stop-schedule {
    display: none;

    .result-back-button {
      width: 62%;
      padding: 15px 0 15px 10px;
    }

    .ctp-line-schedule-infos {
      box-shadow: 0 5px 4px 0 rgba(66, 66, 66, 0.23);
      padding: 0 4%;

      #ctp-lineSccheduleLineStopAreasSearch {
        height: 55px;

        .autocomplete-search-icon {
          color: $color-main-one;
          position: absolute;
          margin: 8px 0 0 2%;
          font-size: 19px;
        }
      }

      #ctp-lineSccheduleLineStopAreasSearchInput {
        overflow: visible;
        border: none;
        width: 100%;
        height: 40px;
        border-radius: 10px;
        background-color: #f1f1f1;
        padding-left: 35px;
      }

      #ctp-lineSccheduleLineStopAreasSearchInput:focus {
        outline: none;
      }
    }
  }
}

.disruption-line {
  border-radius: 5px;
  padding: 4px;
  margin-bottom: 13px;
  cursor: pointer;

  .schedule-disruption {
    width: 19px;
    vertical-align: sub;
    margin: 0 10px;
  }

  .ikisio-arrow-drop-up {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: translateY(50%) rotate(90deg);
    display: inline-block;
    float: right;
    font-size: 12px;
    margin-right: 4px;
  }

  &.information {
    border: solid 1px #35af57;
    background-color: rgba(53, 175, 87, 0.1);
  }

  &.warning {
    border: solid 1px #eca33b;
    background-color: rgba(236, 163, 59, 0.2);
  }

  &.disrupt {
    border: solid 1px #a80b13;
    background-color: rgba(168, 11, 19, 0.13);
  }
}

#stop_area_hours {
  font-size: 14px;
  padding: 10px 0;

  #backward {
    display: none;
  }

  .closing_time {
    float: right;
    width: 50%;
    text-align: right;
  }
}

#disruption {
  float: left;
  display: block;
  width: 100%;

  img {
    width: 20px;
  }
}
#network_dropdown {
  width: 100%;
  position: relative;
  background-color: $color-main-one;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 15px;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  padding: 10px;
  height: 45px;
}

.network_dropdown__arrow {
  position: absolute;
  top: 10px;
  right: 10px;
  pointer-events: none;
}

.network_dropdown__options {
  display: none;
  max-height: 330px;
  overflow: auto;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  background-color: white;
  color: $color-main-one;
  z-index: 1;
  border-radius: 0 0 10px 10px;
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #B3B5B5;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #B3B5B5;
    border-radius: 10px;
  }
}

.network_option {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
  border-bottom: solid 1px #D0D0D0;
  &.selected::after {
    content: "";
    position: absolute;
    right: 10px;
    background: #0EBDAB;
    width: 15px;
    height: 15px;
    border-radius: 50%
  }
}

.network_option:hover {
  background-color: #f0f0f0;
}

.list_open .network_dropdown__options {
  display: block;
}

#schedule_lines_content {
  width: 100%;
  padding: 10px 5% 0 5%;
}

.schedule-section {
  border-radius: 24px;
  background: #fff;
  width: 100%;
  margin: 5px 0;
  height: 40px;
  line-height: 40px;
  padding-left: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &__mode-picto {
    font-size: 28px;
    line-height: 40px;
  }

  &__mode {
    display: flex;
    align-items: center;
  }

  &__mode-name {
    margin-left: 10px;
    font-weight: 600;
  }

  .ikisio-arrow-drop-down {
    transform: rotate(-90deg);
    margin: 15px;
  }

  &__line-constrast {
    position: relative;
    font-weight: bold;
    line-height: 2;
    float: left;
    height: 24px;
    display: block;
    min-width: 24px;
    text-align: center;
    font-family: Arial, sans-serif;
    padding: 0 3px;
    font-size: 12px;
    margin: 5px 2px;
    border-radius: 5px;
  }

  &__lines-item {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin: 10px 5px;
    position: relative;
    padding: 1px 2px;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    float: left;
    background-color: #FFFFFF;
    i {
      font-size: 15px;
      background-color: #FFFFFF;
      border-radius: 22px;
      position: absolute;
      right: -11px;
      bottom: 24px;
      z-index: 1;
    }

    .information {
      color: $color-traffic-warning;
    }

    .information-important {
      color: $color-traffic-disrupt;
    }

    .information-outline {
      color: $color-traffic-info;
    }
  }

  &__lines-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
  }

  &__lines-network-name {
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
    margin-top: 10px;
  }

  &__lines-network {
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
    margin-top: 10px;
    color: $color-main-one;
    img {
      max-width: 100px;
    }
  }

  &.show {
    .ikisio-arrow-drop-down {
      transform: rotate(0deg);
    }
  }

  &__line-disruption-type {
    width: 14px;
    height: 14px;
    position: absolute;
    top: -2px;
    right: -2px;
  }
}

#schedule_lines {
  padding: 10px 15px;
  width: 100%;
  height: 100%;
  background: #F1F1F1;
  border-radius: 16px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .schedule_lines_network, .sections {
    padding: 10px 15px 5px 10px;
    margin: 0;
  }

  .schedule_lines_network img {
    max-width: 100px;
    margin: 0 10px 0 0;
  }

  .lines_sections {
    width: 100%;
    float: left;
    clear: both;
    font-weight: bold;
    margin-bottom: 5px;

    img {
      margin-left: 5px;
      height: 24px;
    }
  }

  .lines {
    margin: 5px 0 0 5px;
    font-size: 12px;
    height: 34px;
    border-radius: 2.5px;
    cursor: pointer;
    float: left;

    span {
      font-weight: bold;
      line-height: 2;
      float: left;
      height: 24px;
      display: block;
      min-width: 24px;
      text-align: center;
      border-radius: 3px;
      font-family: Arial, sans-serif;
      padding: 0 3px;

      .picto_line {
        max-height: 25px;
      }
    }

    .disruption-type {
      position: inherit;
      height: 15px;
      margin-left: -10px;
      background: #fff;
      border-radius: 8px;
      margin-top: -15px;
      margin-right: 0;
      border: solid 1px #fff;
    }
  }
}

#stop_schedule_error {
  padding: 20px;
  text-align: center;
}

#next_departures_results {
  padding: 20px;

  button {
    height: 32px;
    border: none;
    color: $color-main-one;
    border-radius: 16px;
    padding: 0 10px 0 10px;
    background: #fff;
    font-size: 0.8em;
    margin-bottom: 5px;

    span {
      font-family: $font-main-one;
    }
  }

  button:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }

  #stop_area_label {
    padding: 20px 0 10px 0;
    border-bottom: solid 1px #f0f0f0;
    margin: 0 0 5px 10px;
    font-size: 20px;
    font-weight: bold;
  }

  .schedule-lines {
    display: block;
    align-self: flex-start;
    width: 100%;
    float: left;
    margin-bottom: 10px;

    .icon-mode {
      margin-left: 10px;

      &:first-of-type {
        margin: 5px 0 0 0;
      }

      &.passing-lines-network {
        float: none;
        margin-top: 5px;
        margin-left: 2px;
      }
    }

    img {
      margin-top: 5px;
      float: left;
      height: 24px;
    }

    .picto_line {
      margin-top: 0;
      float: left;
      max-height: 24px;
    }

    .lines, .selected_line, .passing-lines {
      margin: 5px 0 0 5px;
      font-size: 12px;
      height: 34px;
      border-bottom: solid 5px #fff;
      border-radius: 2.5px;
      cursor: pointer;
      padding: 0 0 5px 0;
      float: left;
    }

    .passing-lines {
      font-size: 13px;
      margin: 0 0 0 10px;
      height: 39px;

      > span {
        height: 34px;
        position: relative;

        img:first-child {
          margin-right: 10px;
        }

        .passing-lines-number {
          height: 20px;
          line-height: 1;
          max-width: 20px;
          min-width: 20px;
          position: absolute;
          background: black;
          color: white;
          padding: 0;
          border-radius: 100%;
          border: 3px solid white;
          font-size: 11px;
          padding-top: 2px;
          left: 15px;
        }
      }
    }

    .passing-lines img {
      margin: 1px 3px 0 0;
    }

    .lines:hover, .selected_line:not(.passing-lines) {
      border-bottom: solid 5px #b0b0b0;
    }

    .passing-lines:hover, .selected_line {
      .passing-lines-network {
        border-bottom: solid 5px #b0b0b0;
        height: 34px;
        padding-bottom: 5px;
        border-radius: 2.5px;
      }
    }

    span {
      padding: 0 5px;
      font-weight: bold;
      line-height: 2;
      float: left;
      height: 24px;
      display: block;
      min-width: 24px;
      text-align: center;
      border-radius: 3px;
      font-family: Arial, sans-serif;
    }
  }

  .icon-schedule-line-type {
    padding: 0 !important;
    width: auto !important;
    float: left;
    font-size: 23px;
    height: 27px;
  }

  .schedule-line-code {
    border-radius: 5px;
    padding: 2px 5px 2px 5px;
    margin: 0 15px 0 4px;
    font-size: 10px;
    font-weight: bold;
    line-height: 2;
    height: 25px;
    margin-top: -1px;
  }

  .schedule-line-network {
    font-size: 9px;
    font-weight: 900;
    line-height: 2.22;
    letter-spacing: normal;
    text-align: left;
    color: #3c3c3c;
    width: 50%;
    float: left;

    p {
      margin-bottom: 0;
      background-color: #dbdbdb;
      padding-left: 4px;
      padding-right: 4px;
      float: right;
      border-radius: 2px;
    }
  }

  #prev_next {
    min-height: 36px;
    border-radius: 5px;
    background-color: #f0f0f0;
    clear: both;
    height: auto;

    button {
      color: $color-main-one;
      border: none;
      background: none;
    }

    image {
      fill: $color-main-one;
    }

    button:first-of-type {
      float: left;
      margin-top: 8px;
    }

    #schedule-datepicker-bloc {
      text-align: center;
      width: 100%;
      cursor: pointer;

      .schedule-datetime-header {
        display: flex;
        justify-content: space-between;

        #label-schedule-datetime {
          padding: 17px 0 0 0;
          width: 55%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #212529;
          font-size: 16px;

          .ikisio-times {
            font-size: 19px;
            color: $color-main-one;
            margin-right: 4%;
          }

          .arrow-ikisio {
            margin-left: 4%;
          }
        }

        button:last-of-type {
          float: right;
          margin-top: 6px;
          transform: rotate(180deg);
        }
      }

      #schedule-datetime-body {
        display: none;

        #schedule_from_datetime {
          list-style-type: none;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 3% 2% 3% 2%;

          > .input-append {
            &#schedule-datepicker {
              display: inline-flex;
              width: 60%;
            }

            &#schedule-timepicker {
              display: inline-flex;
              width: 30%;
            }

            > .input-group {
              display: inline-flex;
              flex-wrap: unset;

              > input, span {
                flex: 1 1 auto;
                width: inherit;
              }

              > .input-group-addon {
                min-width: auto;
                max-width: 30px;
              }
            }
          }

          li {
            padding: 0;
            width: 49%;
          }

          input[type="radio"] {
            opacity: 0.011;
            position: absolute;
            z-index: 100;
          }

          input[type="radio"]:checked + label {
            color: #ffffff;
            border: 1px solid #CCC;
            box-shadow: 1.4px 1.4px 2px 0 rgba(0, 0, 0, 0.23);
            background-color: $color-main-one;
          }

          label {
            color: #000000;
            background-color: #ffffff;
            border-radius: 5px;
            text-align: center;
            cursor: pointer;
            width: 100%;
            height: 100%;
            padding: 2% 0 0 0;

            i.icon_datetime_represents {
              font-size: large;
              vertical-align: middle;
              margin-right: 3%;
            }
          }

          label:hover {
            color: #ffffff;
            background: $color-main-one;
          }

          p {
            margin: auto;
          }
        }
      }
    }

    @-moz-document url-prefix() {
      .search_datetime_represents {
        #timepicker {
          input.input-picker {
            width: 90%;
            height: 33px;
          }

          span.icon-picker-input {
            position: absolute;
            right: 0;
          }
        }
      }
    }
  }

  #select-passing-lines-network-container {
    display: none;
    float: left;
    width: 100%;
    margin-bottom: 10px;

    #select-passing-lines-network {
      border-radius: 5px;
      background-color: #f1f1f1;
      padding: 0;

      .select-passing-lines-network-option-prototype {
        cursor: pointer;
        padding: 0 9px;

        .ikisio:not(.ikisio-arrow-drop-down) {
          margin-right: 5px;
        }

        .ikisio-arrow-drop-down {
          margin-top: 5px;
          margin-left: 8px;
        }
      }

      .select-passing-lines-network-option-choice {
        padding: 9px 10px;

        .ikisio-arrow-drop-down, .ikisio-arrow-drop-up {
          float: right;
        }
      }

      .select-passing-lines-network-option, .select-passing-lines-network-option-selected {
        .select-passing-lines-network-option-prototype-container {
          display: flex;
          align-items: center;
          border-top: solid 1px #dbdbdb;
          padding: 9px 0 10px 0;

          > span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            flex-grow: 2;
          }
        }

        display: none;

        &:hover {
          background: rgba(255, 255, 255, 0.65);
        }

        .ikisio-arrow-drop-down {
          display: none;
        }
      }

      .select-passing-lines-network-option-selected {
        border-top: none;

        &.closed {
          .select-passing-lines-network-option-prototype-container {
            border-top: none;
          }
        }

        .ikisio-arrow-drop-down {
          margin-top: 0;
        }
      }
    }
  }

  table {
    margin: 25px 0;
    width: 100%;

    img {
      margin: 0 5px 0 0;
    }
  }

  .hide {
    display: none;
  }

  table td:first-of-type {
    width: 80%;
  }

  table td:last-of-type {
    white-space: nowrap;
    text-align: right;

    .ikisio, .notes-index {
      margin-left: 3px;
      font-size: 0.75rem;
    }
  }

  .notesContainer {
    font-size: 0.75rem;
    margin-bottom: 10px;
    padding-left: 0;
    list-style: none;

    .ikisio-tad {
      margin-right: 3px;
    }
  }

  #button_action {
    width: 100%;
    text-align: center;
    margin: 20px 0 0 0;
  }

  #change_direction, #link-show-plan {
    color: $color-main-one;
    border: solid 1px $color-main-one;
    border-radius: 20px;
    padding: 10px;
    width: 200px;
    margin: 0 10px;
    text-align: center;

    &:hover {
      text-decoration: none;
      background-color: #f5f5f5;
    }
  }
}

.schedule-datetime-apply {
  height: 60px;

  .kisio-schedule-datetime-update {
    padding: 10px 27px;
    border-radius: 23px;
    background-color: $color-main-one;
    letter-spacing: 0.4px;
    border: none;
    color: $color-text;
    position: relative;
    top: 25%;
    transform: translate(50%);
    font-size: 14px;
    font-weight: normal;

    &:hover {
      padding: 12px 29px;
      text-decoration: none;
      color: $color-text;
    }

    &:active {
      background-color: $color-main-one-dark;
    }
  }
}

#popup-schedule-timetable {
  height: 32px;
  border: solid 1px $color-main-one;
  color: $color-main-one;
  border-radius: 16px;
  padding: 0 10px 0 10px;
  background: #fff;
  font-size: 0.8em;
  margin-bottom: 10px;

  span {
    font-family: $font-main-one;
  }
}

.tooltip-inner {
  max-width: 600px;
  width: auto;
}

.disruption-message {
  margin-top: 15px;
  text-align: left;
}

#no_results {
  padding: 10px;
  text-align: center;
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  #next_departures_results #prev_next #schedule-datepicker-bloc #schedule-datetime-body #schedule_from_datetime > .input-append > .input-group {
    -ms-flex-wrap: nowrap;
  }
}

.vehicle-position-popup {
  .leaflet-popup-close-button {
    display: none !important;
  }

  .leaflet-popup-tip-container {
    left: 0%;
    top: 7%;
    transform: rotate(90deg);
    width: 30px;
    height: 10px;

    .leaflet-popup-tip {
      width: 15px;
      height: 13px;
      background-color: $color-main-one;
      box-shadow: none;
    }
  }

  &__header {
    background-color: $color-main-one;
    display: flex;
    align-items: center;
    height: 25px;
    border-radius: 5px;
    color: #FFFFFF;
    padding-left: 8px;
  }

  &__code-line {
    height: 18px;
    width: 18px;
    text-align: center;
    margin: 2px 2px 2px 5px;
    border-radius: 3px;
  }

  &__direction {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    span:last-child {
      font-weight: bold;
    }
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 60px;
  }

  &__occupancy {
    width: 25%;

    img:last-child {
      width: 50px;
      height: 35px;
    }
  }

  &__occupancy-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__no-signal {
    margin-left: 10px;
  }
}

.next-departures-result {
  padding: 10px;
  text-align: center;
}