.select {
  details {
    position: relative;
    line-height: 35px;
  }

  details[open] {
    z-index: 1;
  }

  summary {
    padding: 0 10px;
    cursor: pointer;
    border-radius: 5px;
    background-color: $color-main-one;
    color: #D0D0D0;
    list-style: none;
    min-height: 40px;
  }

  summary::-webkit-details-marker {
    display: none;
  }

  details[open] {
    summary {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &:before {
        content: '';
        display: block;
        width: 100vw;
        height: 100vh;
        background: transparent;
        position: fixed;
        top: 0;
        left: 0;
      }
    }
  }

  summary:after {
    content: '';
    width: .5rem;
    height: .5rem;
    border-bottom: 1px solid currentColor;
    border-left: 1px solid currentColor;
    border-bottom-left-radius: 2px;
    transform: rotate(-45deg) translate(50%, 0%);
    transform-origin: center center;
    top: 16px;
    position: absolute;
    right: 16px;
    color: #FFFFFF;
  }

  summary:focus {
    outline: none;
  }

  details[open] summary:after {
    transform: rotate(45deg) translate(0%, 0%);
    right: 10px;
    top: 14px;
  }

  ul {
    width: 100%;
    background: #FFFFFF;
    position: absolute;
    box-sizing: border-box;
    border-radius: 5px;
    max-height: 200px;
    overflow-y: auto;
    list-style-type: none;
    padding: 0;
    margin: 0;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  li {
    margin: 0;
    border-bottom: 1px solid #CECECE;
    font-size: 18px;
    line-height: 26px;
  }

  li:last-child {
    border-bottom: none;
  }

  /* FAKE SELECT */
  summary.radios {
    counter-reset: radios;
  }

  summary.radios:before {
    content: var(--selection);
  }

  input[type=radio] {
    counter-increment: radios;
    appearance: none;
    display: none;
    cursor: pointer;
  }

  input[type=radio]:checked {
    display: inline;
    --display: block;
  }

  input[type=radio]:after {
    content: attr(title);
    display: inline;
    font-size: 1rem;
    color: #FFFFFF;
    font-weight: bold;
  }

  ul.list {
    counter-reset: labels;
  }

  label {
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    margin: 5px 0;
    width: 100%;
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    padding: 0 20px;
  }

  label span {
    --display: none;
    display: var(--display);
    width: 1rem;
    height: 1rem;
    border: 1px solid #727272;
    border-radius: 3px;
  }
}
