$icomoon-font-family: "ikisiofonticon" !default;
$icomoon-font-path: "../fonts" !default;

$ikisio-street-view: "\e963";
$ikisio-arrow: "\e961";
$ikisio-reload: "\e962";
$ikisio-trash: "\e960";
$ikisio-ctp-arrow-back: "\e95c";
$ikisio-ctp-arrow-left: "\e95d";
$ikisio-ctp-arrow-right: "\e95e";
$ikisio-ctp-arrow-straight: "\e95f";
$ikisio-vls: "\e966";
$ikisio-self-service-bike: "\e966";
$ikisio-bicycle: "\e965";
$ikisio-bike: "\e965";
$ikisio-car: "\e922";
$ikisio-ridesharing:  "\e969";
$ikisio-carnopark-new: "\e985";
$ikisio-walking: "\e96c";
$ikisio-bus-front: "\e945";
$ikisio-train: "\e97f";
$ikisio-metro: "\e97d";
$ikisio-tram: "\e980";
$ikisio-tramway: "\e980";
$ikisio-bus: "\e97c";
$ikisio-cable_car: "\e941";
$ikisio-suspendedcablecar: "\e92a";
$ikisio-airplane: "\e940";
$ikisio-air: "\e93d";
$ikisio-shuttle: "\e928";
$ikisio-busrapidtransit: "\e925";
$ikisio-autocar: "\e93e";
$ikisio-coach: "\e993";
$ikisio-density: "\e994";
$ikisio-localtrain: "\e97e";
$ikisio-ter: "\e921";
$ikisio-tgv: "\e93f";
$ikisio-longdistancetrain: "\e907";
$ikisio-rapidtransit: "\e91d";
$ikisio-railshuttle: "\e933";
$ikisio-boat: "\e967";
$ikisio-taxi: "\e926";
$ikisio-ferry: "\e967";
$ikisio-funicular: "\e947";
$ikisio-fast-walker: "\e925";
$ikisio-slow-walker: "\e926";
$ikisio-standard: "\e927";
$ikisio-fast-bike: "\e94c";
$ikisio-slow-bike: "\e94d";
$ikisio-standard-bike: "\e94e";
$ikisio-mail: "\e910";
$ikisio-facebook: "\e952";
$ikisio-twitter: "\e953";
$ikisio-right: "\e950";
$ikisio-tad: "\e94f";
$ikisio-validate: "\e935";
$ikisio-stop_point: "\e942";
$ikisio-administrative_region: "\e929";
$ikisio-stop_area: "\e942";
$ikisio-address: "\e943";
$ikisio-poi: "\e944";
$ikisio-pdf: "\e92f";
$ikisio-geoloc: "\e921";
$ikisio-proximity: "\e938";
$ikisio-calendar: "\e939";
$ikisio-reverse: "\e917";
$ikisio-close: "\e900";
$ikisio-clear-field: "\e901";
$ikisio-share: "\e918";
$ikisio-print: "\e919";
$ikisio-recenter: "\e91a";
$ikisio-leaf: "\e91c";
$ikisio-arrow-drop-down: "\e928";
$ikisio-arrow-drop-up: "\e929";
$ikisio-arrow-direction: "\e914";
$ikisio-zoom: "\e92b";
$ikisio-warning: "\e92c";
$ikisio-download: "\e92d";
$ikisio-wheelchair: "\e92e";
$ikisio-traffic: "\e905";
$ikisio-information: "\e930";
$ikisio-selector: "\e91b";
$ikisio-arrival: "\e932";
$ikisio-journey: "\e90e";
$ikisio-departure: "\e933";
$ikisio-settings: "\e915";
$ikisio-times: "\e939";
$ikisio-favorites: "\e973";
$ikisio-park-car: "\e970";
$ikisio-update: "\e971";
$ikisio-back: "\e972";
$ikisio-from: "\e975";
$ikisio-arrow-destination: "\e976";
$ikisio-locate: "\e977";
$ikisio-calendar-new: "\e978";
$ikisio-preferences-new: "\e979";
$ikisio-calendar-main: "\e97b";
$ikisio-clock-times: "\e979e";
$ikisio-arrow-go-back: "\e981";
$ikisio-arrow-go-forward: "\e982";
$ikisio-luggage: "\e9789";
$ikisio-rapid: "\e98c";
$ikisio-comfort: "\e98d";
$ikisio-balanced: "\e98e";
$ikisio-shortest: "\e98f";
$ikisio-arrow-right: "\e97a";
$ikisio-to: "\e974";
$ikisio-minibus: "\e93a";
$ikisio-information-outline: "\e95b";
$ikisio-schedule: "\e935";
$ikisio-walking-new: "\e986";
$ikisio-bike-new: "\e987";
$ikisio-waiting-time: "\e988";
$ikisio-entrance: "\e989";
$ikisio-exit: "\e98b";
$ikisio-changer: "\e98a";
$ikisio-car-new: "\e985";
$ikisio-public-transport: "\e984";
$ikisio-home: "\e90b";
$ikisio-work: "\e990";
$ikisio-favorites-full: "\e991";
$ikisio-big-bicycle: "\e923";
$ikisio-handi: "\e992";
$ikisio-parking: "\e96f";
$ikisio-bss: "\e95a";
$ikisio-information-important: "\e995";
$ikisio-places: "\e996";