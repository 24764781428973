#html, body {
  margin: 0;
  padding: 0;
  font-family: $font-main-one;
  overflow: hidden;
  height: 100vh;
}
a{
  outline: none;
}
#add_road {
  margin: 15px;
}
#map {
  z-index:1;
  overflow: hidden;
}
.maps-img {
  z-index: 400;
  position: fixed;
  bottom: 30px;
  right: 10px;
  img{
    width: 45px;
  }
}
@media screen and (max-width: 600px) {
  .maps-img { display: none; }
}
#mapid {
  width:100%;
  height: 100vh;
}
#loading-box, #loading-box-specific{
  position: absolute;
  z-index: 9999;
  width: 100%;
  text-align: center;
  height: 100%;
  background: #ccc;
  opacity: 0.8;
  visibility: hidden;
  div{
    display: flex;
    justify-content: center;
    align-items: center;
    background: silver;
    width: 100%;
    height: 100%;
  }
}

.gl-hide {
  display: none !important;
}

/*************** Global Buttons ******************/
.ctp-button-primary {
  cursor: pointer;
  text-align: center;
  border-radius: 23px;
  background-color: $color-main-two;
  font-size: 17px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  border: none;
  color: #ffffff;
  height: 46px;

  &.md {
    width: 172px;

    &:hover {
      width: 176px;
      height: 50px;
      margin-top: -2px;
    }
  }

  &.lg {
    width: 200px;

    &:hover {
      width: 204px;
      height: 50px;
      margin-top: -2px;
    }
  }

  &.disabled {
    background-color: #D7D7D7;
    cursor: not-allowed;
    pointer-events: none;
  }
}

/******************* Global img size ********************/
.icon-mode {
  width: 24px;
  height: 24px;
}


.active-poi-marker {
  &-pin {
    fill: $color-main-two;
    stroke: $color-main-two;
  }
  &-icon {
    position: absolute;
    top: 4px;
    left: 12px;
    height: 25px;
    width: 25px
  }
}
