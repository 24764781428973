@import "traffic_home";
@import "traffic-tooltip";

.ctp-traffic {
  height: 96vh;
  display: flex;
  flex-direction: column;
}

.disruption {
  &__network-container {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 6px;

    .disruption__network-title {
      color: #FFFFFF;
    }

    &.disrupt {
      background-color: #FF0000;

      .hide-txt .disruption__network-content-show {
        background: linear-gradient(180deg, rgba(210, 0, 0, 0) 54.65%, rgba(255, 0, 0, 0.7) 176.92%);
      }
    }

    &.warning {
      background-color: $color-traffic-warning;

      .hide-txt .disruption__network-content-show {
        background: linear-gradient(180deg, rgba(210, 0, 0, 0) 54.65%, rgba(255, 122, 0, 1) 176.92%);
      }
    }

    &.information {
      background-color: $color-traffic-info;

      .hide-txt .disruption__network-content-show {
        background: linear-gradient(180deg, rgba(210, 0, 0, 0) 54.65%, rgba(15, 169, 88, 1) 176.92%);
      }
    }

    .ikisio-information {
      color: $color-traffic-warning;
    }

    .ikisio-information-important {
      color: $color-traffic-disrupt;
    }

    .ikisio-information-outline {
      color: $color-traffic-info;
    }
  }

  &__network-title {
    display: flex;
    position: relative;
    font-size: 14px;
    line-height: 28px;
    padding: 0 7px;

    i {
      align-self: center;
      margin-right: 5px;
      font-size: 13px;
      cursor: pointer;
    }

    span {
      font-weight: 600;
      margin-right: 5px;
    }
  }

  &__network-title-resume {
    width: 75%;
    line-height: 15px;
    margin: 10px 0;
  }

  &__network-show-less {
    display: flex;
    position: absolute;
    right: 7px;
    font-size: 10px;
    cursor: pointer;

    i {
      font-size: 7px;
      margin-left: 5px;
      align-self: center;
    }

    span {
      text-decoration: underline;
    }
  }

  &__network-content {
    display: flex;
    position: relative;
    font-size: 14px;
    margin: 2px 2px 2px 5px;
    background-color: rgba(255, 255, 255, .97);
    border-radius: 0 6px 6px 0;
    padding: 18px 7px;

    .disruption__network-picto {
      margin-right: 10px;
      font-size: 20px;
    }

    .disruption__network-content-show {
      position: absolute;
      width: 100%;
      cursor: pointer;
      transform: rotate(180deg) translate(1%, 0);
      display: flex;
      justify-content: center;
      align-items: end;
      padding-bottom: 5px;
      bottom: 5px;
    }

    .disruption__message {
      line-height: 20px;
      overflow: hidden;

      p {
        margin-bottom: 0;
      }
    }

    &.hide-txt {
      .disruption__network-content-show {
        transform: none;
        height: 66px;
        top: 10px;
        right: 0;
      }

      .disruption__message {
        color: #807575;
        max-height: 40px;
        padding-bottom: 0;
      }
    }
  }

  &__lines-header {
    display: flex;
    background-color: #f1f1f1;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  &__lines-mode {
    height: 40px;
    display: flex;
    justify-content: center;
    position: relative;
    cursor: pointer;
    flex-direction: column;
    padding: 0 20px;
    background: #F8F8F8;
    border-bottom: 1px solid #D0D0D0;
    border-radius: 10px 10px 0 0;

    &.selected {
      background-color: #FFFFFF;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;

      &:before, &:after {
        content: " ";
        height: 100%;
        position: absolute;
        top: 0;
        width: 15px;
      }
      &:before {
        box-shadow: -9px 0 11px -15px inset;
        left: -15px;
      }
      &:after {
        box-shadow: 9px 0 11px -15px inset;
        right: -15px;
      }
    }

    &:first-child {
      &.selected {
        &:before {
          box-shadow: none;
        }
      }
    }
  }

  &__lines-mode-length {
    font-size: 14px;
    height: 20px;
    width: 20px;
    border-radius: 7px;
    line-height: 25px;
    text-align: center;

    i.information {
      color: $color-traffic-info;
    }
    i.warning {
      color: $color-traffic-warning;
    }
    i.disrupt {
      color: $color-traffic-disrupt;
    }
  }

  &__lines-mode-header {
    text-align: center;
    display: flex;
    align-items: center;
    font-size: 16px;

    img {
      margin-right: 4px;
      width: 34px;
    }
  }

  &__lines-mode-content {
    display: none;

    &.selected {
      min-height: 60px;
      padding: 10px 15px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: fit-content;
    }
  }

  &__lines {
    margin-top: 15px;
    display: flex;
    flex-direction: column;

    .disruption {
      &__lines-by-mode {
        border: 1px solid #D0D0D0;
        border-radius: 10px;
        margin-bottom: 20px;
      }
    }
  }

  &__line-contrast {
    position: relative;
    font-weight: bold;
    line-height: 2;
    float: left;
    height: 24px;
    display: block;
    min-width: 24px;
    text-align: center;
    font-family: Arial, sans-serif;
    padding: 0 3px;
    font-size: 12px;
  }

  &__info {
    display: flex;
    width: 90%;
    flex-direction: column;
    margin: 0 auto;
    font-size: 12px;
  }

  &__info-subtitle {
    color: $color-main-one;
    border-bottom: 1px solid $color-main-one;
  }

  &__info-title {
    margin: 20px 0;
  }

  &__lines-item-container {
    display: flex;
    flex-direction: column;
    padding: 5px 20px 50px 20px;
    width: 100%;
    height: 100vh !important;
  }

  &__lines-container-resume {
    span {
      margin: 0;
    }

    i.information {
      color: $color-traffic-info;
    }
    i.warning {
      color: $color-traffic-warning;
    }
    i.disrupt {
      color: $color-traffic-disrupt;
    }
  }

  &__lines-container-info {
    margin: 0 10px 30px 10px;
    border: 1px solid #D0D0D0;
    border-radius: 10px;
  }

  &__lines-container-body {
    background-color: #f1f1f1;
    border-radius: 5px;
    padding: 10px;
  }

  &__lines-container-back {
    font-size: 11px;
    color: $color-main-one;
    text-decoration: underline;
    margin: 10px 0 0 8px;
    cursor: pointer;
    width: 50px;
  }

  &__lines-container-resume-container {
    position: relative;
    display: flex;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    padding: 10px 0;

    i {
      position: absolute;
      top: -10px;
      right: 29px;
      background-color: #FFFFFF;
      border-radius: 15px;
    }

    .disruption__lines-item {
      margin: 0 7px;
      padding: 2px;
      height: 32px;

      span {
        margin: 2px;
      }
    }
  }

  &__line-header {
    display: flex;
    line-height: 14px;
    margin: 13px 0;
    font-size: 14px;
    border-bottom: solid 3px #251942;
    height: 54px;

    .last-update {
      align-items: left;
      justify-content: left;
      font-weight: 600;
      padding: 20px 6px;
      font-size: 10px;
      border: none;

      span {
        margin-left: 0px;
      }
      i {
        font-size: 18px;
        margin-right: 3px;
      }
      width: 33.33%;
    }
    div {
      display: flex;
      align-items: center;
      padding: 20px;
      font-size: 20px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      border: solid 1px #251942;
      border-bottom: none;
      cursor: pointer;
      width: 33.33%;
      justify-content: center;
      text-align: center;

      span {
        margin-left: 10px;
      }
    }

    span {
      cursor: pointer;
    }

    .disruption__count {
      text-decoration: none;
    }

    .selected {
      background-color: #251942;
      color: #FFFFFF;
    }
  }

  &__count {
    font-size: 10px;
    background-color: $color-traffic-disrupt;
    height: 15px;
    width: 15px;
    border-radius: 14px;
    color: #FFFFFF;
    text-align: center;
    margin-left: 4px;
  }

  &__line-body-title {
    background-color: #F8F8F8;
    border-radius: 10px 10px 0 0;
    min-height: 43px;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    padding-left: 20px;
    border-bottom: 1px solid #D0D0D0;

    span {
      margin-left: 10px;
    }
  }

  &__line-body-content {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding: 15px 26px;
  }

  &__line-body {
    margin-top: 35px;
  }

  &__no-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60%;
    margin: 0 20px;
    background-color: #F8F8F8;
    border: 1px solid #D0D0D0;
    border-radius: 10px;
    color: #A4A4A4;
    padding: 0 15%;
    text-align: center;
    font-size: 15px;

    &.size-md {
      height: 100px;
    }
  }
}

.disruption__lines-item {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin: 10px 5px;
  position: relative;
  padding: 1px 2px;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  float: left;

  span {
    margin: 5px 2px;
    border-radius: 5px;
  }

  i {
    font-size: 15px;
    background-color: #FFFFFF;
    border-radius: 22px;
    position: absolute;
    right: -11px;
    bottom: 24px;

    &.information {
      color: $color-traffic-info;
    }
    &.warning {
      color: $color-traffic-warning;
    }
    &.disrupt {
      color: $color-traffic-disrupt;
    }
  }

  &:last-child {
    border-right: none;
  }
}

#ctp-traffic__content {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
}
