.traffic-map-toolip {
  min-height: 100px;

  &__header {
    background-color: $color-main-one;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #FFFFFF;
    padding: 0 3px 0 12px;
    border-radius: 9px 9px 0 0;

    .ikisio {
      font-size: 24px;
    }

    .disruption__line-contrast {
      margin: 0;
    }

    .disruption__lines-item {
      margin: 5px;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__body {
    min-height: 73px;
    display: flex;

    .traffic-map-toolip__body-left {
      width: 6px;
      border-bottom-left-radius: 20px;

      &.information {
        background-color: $color-traffic-info;
      }

      &.warning {
        background-color: $color-traffic-warning;
      }

      &.disrupt {
        background-color: $color-traffic-disrupt;
      }
    }

    .traffic-map-toolip__body-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin: 12px;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;

      .traffic-map-toolip__routes {
        color: #A1A1A1;
        display: flex;
        align-items: center;

        span {
          margin-left: 5px;
        }
      }

      .traffic-map-toolip__all-routes {
        margin-bottom: 20px;
      }

      .traffic-map-toolip__discruption {
        display: flex;

        .ikisio-information {
          color: $color-traffic-info;
          font-size: 18px;
        }

        .ikisio-warning {
          color: $color-traffic-warning;
          font-size: 18px;
        }

        .ikisio-disrupt {
          color: $color-traffic-disrupt;
          font-size: 18px;
        }
      }
    }
  }

  &__discruption-title {
    margin-left: 8px;
  }

  // @TODO refacto with leaflet css (remove !important)
  &__terminus {
    padding: 0px !important;
    width: auto !important;
    border-radius: 20px !important;
    border: none !important;
    background-color: transparent !important;

    &-content {
      width: auto !important;
      border-radius: 20px !important;
      padding: 0 20px !important;
      position: relative !important;
      color: #000000 !important;
      top: 0 !important;
      right: 0 !important;
      background-color: #FFFFFF !important;
      font-size: 12px;
    }
  }
}
