.fav-star {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  outline: none;
  &:focus-visible {
    outline: auto;
  }
  &:hover {
    cursor: pointer;
  }
  &.fav-star-add {
    .ikisio-favorites {
      color: $font-main-one;
    }
  }
  &.fav-star-del, &:hover {
    .ikisio-favorites {
      color: #ffd700;
    }
  }
  
}

#list-favorites{
  padding: 0 10px 0 6%;
  height: auto;
  display: block;
  margin: 10px 0;
  .list-fav{
    list-style-type: none;
    padding: 0 0 10px 0;
    height: auto;
  }
  .ctp-favorites-accordion {
    background-color: transparent;
    padding: 0 10px 0 0;
    border-radius: 23px;
    overflow: hidden;
    margin: 0;
    &:before {
      height: 0;
      z-index: 0;
      box-shadow: none;
    }
    &:after {
      height: 0;
      background: none;
    }
    .section-favorites{
      padding: 0 0 0 10px;
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      margin: 12px 0;
      background-color: #f1f1f1;
      font-size: 15px;
      font-weight: bold;
      height: 36px;
      width: 100%;
      max-width: 100%;
      .arrow-ikisio{
        margin-right: 10px;
      }
      & + .section-favorites-content {
        display: none;
        &.open {
          display: block;
        }
      }
    }
    .content-favorites{
      background-color: transparent;
      list-style-type: none;
      padding: 0 10px;
      .item {
        margin-bottom: 15px;
        background-color: #F4F4F4;
        border-radius: 23px;
        padding: 8px 18px;
        .line-name {
          .line-code {
            font-size: 12px;
            font-weight: bold;
            line-height: 2;
            display: inline-block;
            padding: 0 5px;
            margin: 0 0 0 4px;
            min-width: 24px;
            height: 24px;
            text-align: center;
            border-radius: 3px;
            font-family: "Lato", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
          }
        }
        &.item-bss{
          float: left;
          width: 100%;
          .bss_content{
            float: left;
            width: 70%;
            .ajaxLoadError {
              font-size: 14px;
              display: flex;
              margin-top: 10px;
              .ikisio {
                align-self: center;
                margin: 0 10px;
                cursor: pointer;
              }
            }
          }
          .bss_favorite_buttons{
            float: right;
            text-align: right;
            button{
              border: none;
              display: none;
            }
            .trash-button-fav{
              margin: 0 5px;
            }
          }
        }
        &.item-stop_area_lines_direction {
          .times-container {
            margin: 5px 0 12px;
          }
          .times-container, .times-link {
            font-size: 14px;
          }
          .informations, .times-container {
            display: flex;
            justify-content: space-between;
          }
          .actions {
            .times-link {
              float: left;
            }
          }
        }
        &.item-journey_solution {
          .kisio-summary {
            cursor: pointer;
            height: auto;
            padding-bottom: 15px;
            &:hover {
              box-shadow: 0px 4px 5px 0 rgba(66, 66, 66, 0.31);
              border-radius: 5px;
            }
            .kisio-favorite-from-to {
              font-size: 14px;
              margin-bottom: 8px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .from, .to {
                width: 47%;
              }
              .to {
                text-align: right;
              }
            }
            .kisio-body-summary {
              position: relative;
              .trash-button-fav {
                position: absolute;
                right: 0;
                bottom: 0;
                height: 0;
              }
            }
            .ajaxLoadError {
              font-size: 14px;
              display: flex;
              margin-top: 10px;
              .ikisio {
                align-self: center;
                margin-left: 10px;
              }
            }
          }
        }
        .actions {
          .ikisio {
            float: right;
            cursor: pointer;
            &:hover {
              color: $color-main-one;
            }
          }
        }
      }
    }
  }
  .ctp-favorites-accordion:last-child {
      margin-bottom: 0;
  }
  .ctp-favorites-accordion:first-child {
    display: block;
    overflow: hidden;
  }
}
.kisio-global-popup-full {
  .kisio-popup-body {
    .fav-delete-text {
      display: inline-block;
      margin-bottom: 15px;
    }
  }
  .kisio-popup-footer {
    .fav-delete-buttons-container {
      .fav-delete.yes {
        float: left;
      }
      .fav-delete.no {
        float: right;
      }
    }
  }
}

.favorites_standalone_link {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 172px;
  height: 46px;
  text-align: center;
  border-radius: 23px;
  font-size: 17px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  border: 2px solid $color-main-one;
  color: $color-main-one;
  z-index: 401;
  .favorites_standalone_link_content {
    display: flex;
    align-items: flex-end;
    color: $color-main-one;
    font-weight: 600;
    font-size: 17px;
    // line-height: 24px;
    em {
      margin-right: 9px;
      font-size: 25px;
    }
  }
  .ikisio-arrow-right {
    position: absolute;
    right: 15px;
    color: $color-text;
  }
}

.favorites_standalone_link.map {
  display: none;
  position: absolute;
  bottom: 30px;
  right: 65px;
  background: $color-main-one;
  .favorites_standalone_link_content {
    color: #FFF;
  }
}

.board_open ~ .favorites_standalone_link,
.board_open_bookmark ~ .favorites_standalone_link,
#board_container[data-section='traffic'] ~ .favorites_standalone_link{
  display: none;
}

.board_open_proximity ~ .favorites_standalone_link,
#board_container[data-section='proximity'] ~ .favorites_standalone_link {
  display: flex;
}

.favorites_standalone_link.proximity {
  display: none !important;
  border: none;
  height: auto;
  width: fit-content;
  .favorites_standalone_link_content {
    align-items: center;
    color: $color-main-one;
    font-weight: 600;
    font-size: 17px;
    em {
      margin-right: 5px;
      margin-bottom: 7px;
      font-size: 23px;
    }
  }
}

.fav-schedule  {
  margin-left: auto;
  order: 2;
  font-size: 18px;
}