.timetable_paginate {
  &--section {
    background-color: #FFFFFF;
    width: 100%;
    border-radius: 24px;
    font-size: 13px;

    tr {
      display: -webkit-box;
      float: left;
      background-color: #F1F1F1;
      border-radius: 10px;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    td {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      display: block;
      padding: 10px;
      border-top: none;
    }

    th {
        line-height: 24px;
        display: block;
        padding: 10px;
        font-weight: bold;
        font-size: 20px;
        background-color: #FFFFFF;
        border-top:none;
    }

    tbody {
      display: flex;
      flex-direction: column;
      padding: 20px;
    }

    &.is-hidden {
      display: none;
    }
  }

  &--top-controls {
    margin: 13px 0;
    display: flex;
    justify-content: center;

    &--text {
      margin: 0 6%;
    }

    .next, .back {
      cursor: pointer;
      color: $color-main-one;
      font-size: 14px;
      margin: 0;
      font-weight: 600;
      line-height: 17px;
      display: flex;
      align-items: center;

      i {
        margin: 5px;
      }
    }

    .disabled {
      opacity: .3;
      pointer-events: none;
    }
  }

  &--bottom-controls {
    display: inline-flex;
    justify-content: center;
    margin: 12px 15%;

    &--item {
      cursor: pointer;
      border-radius: 50%;
      padding: 2px;
      border: 2px solid $color-main-two;
      margin: 0 15%;
      height: 1px;

      &.is-selected {
        background-color: $color-main-two;
      }
    }
  }
}

.timetable_download {
  color: $color-main-one;
  border: solid 1px $color-main-one;
  border-radius: 20px;
  padding: 10px;
  width: 215px;
  margin: 20px auto;
  text-align: center;
  background-color: #FFF;

  &:hover {
    text-decoration: none;
    color: $color-main-one;
    background-color: #f5f5f5;
  }
}
