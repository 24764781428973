
#search_from , #search_to {
  .historic-list, .bookmark-list{
    background-color: #F8F8F8;
    border: 1px solid #F8F8F8;
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    left: -1px;
    width: 101%;
    z-index: 99999;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow-y: auto;
    padding-left: 0;
    padding-bottom: 150px;
    border-radius: 10px;
    text-align: center;
    height: 900px;
    &::-webkit-scrollbar {
      width: 2px;
      opacity: 0.8;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
  
    &::-webkit-scrollbar-thumb {
      background: #B3B5B5;
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background: #B3B5B5;
      border-radius: 10px;
    }
    .historic-label{
      margin-bottom: 0%;
      width: 100%;
      padding: 3% 3% 1.5% 6%;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
    }
    .historic-item{
      width: 90%;
      margin: auto;
      background: #FDFDFD;
      box-shadow: 0px 1px 2px rgb(0 0 0 / 25%);
      border-radius: 12px;
      margin-top: 8px;
      list-style-type: none;
      font-size: 14px;
      font-weight: 450;
      padding: 2% 3%;
      border-bottom: 1px solid #dbdbdb;
      display: inline-block;
      .header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        em {
          margin-right: 5px;
        }
      }
      .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
      }
      .lines-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        margin-left: 30px;
        .icon-container {
          height: 25px;
          align-items: center;
          font-size: 20px;
        }
        .lines {
          border-radius: 5px;
          font-weight: 600;
          padding: 1px 3px;
          line-height: 19px;
          margin: 2px;
          font-size: 12px;
          img {
            height: 20px;
          }
        }
      }
      &:hover{
        background-color: #dbdbdb;
        cursor: pointer;
      }
    }
    li:last-child{
      border-bottom: 0px;
    }
  }
}






