.journeys-filter {
  &:focus-visible {
    outline: auto;
  }
  &__header {
    display: flex;
    justify-content: space-around;
    background: $color-main-one;
    color: white;
    border-radius: 16px 16px 0px 0px;
    align-items: center;
    height: 100%;
  }

  &__item {
    cursor: pointer;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    em {
      font-size: 24px;
    }

    .duration {
      font-size: 12px;
      height: 16px;
    }

    &.selected {
      color: $color-main-two;
      border-bottom: solid 5px;
    }
  }

  position: relative;
  white-space: nowrap;
  height: 58px;

  &__section {
    font-size: 16px;
    color: $color-main-two;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    list-style: none;
  }

  &__direction {
    position: absolute;
    background-color: #f0f0f0;
    width: 50px;
    cursor: pointer;

    &.right {
      transform: rotate(90deg);
      top: 59px;
      left: -15px;

      i {
        padding: 10px;
      }
    }

    &.left {
      transform: rotate(270deg);
      top: 47px;
      right: -7px;

      i {
        padding: 13px;
      }
    }
  }
}
  