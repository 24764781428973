@media (max-width: 319px) {
    #board_container {
        height: 100%;
        width: 100%;
        min-width: unset;
    
        .board_title {
            margin: unset;
            padding: 10px 10px 10px 43px;
            
            .section_icon {
                left: 15px;
            }

            .subtitle {
                font-size: 14px;
            }
        }
    }

    #board_footer {
        margin-bottom: unset;
        
        #footer_favorites {
            height: 42px;
            margin-bottom: 0;
        }
    }
    #ctp-favorites .transport-tab-content .shared-item .content .parent-slot {
        flex-flow: column !important;
    }
}