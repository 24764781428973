/*Medium devices (tablets, 600px and up until 850px, excluded)*/
@media (min-width: 600px) and (max-width: 849.98px) {
  #board_container {
    width: 450px;
    left: 2%;
    max-width: 532px;
    @media (orientation: landscape) {
      #board_content{
        // max-height: 245px;
        overflow: scroll;
      }
      #ui-accordion-content-settings,.ui-accordion-content-now{
        min-height: 110px;
      }
    }
  }
  #board_title_container .board_title {
    margin-left: 4%;
  }
  .ui-accordion-header {
    margin: 2% 0 2% 0;
  }
  .ui-accordion-header > * {
    font-size: 13px;
  }
  #datepicker input {
    width: 82%;
  }
  .ipt-content {
    width: 88%;
  }
 
  #precision_content{
    .precision-back-button {
      margin: 2% 0 2% 1%;
    }
  }

  .kisio-result {
    .kisio-error-message {
      li {
        padding: 5%;
      }
    }
  }

  @-moz-document url-prefix() {
    .search_datetime_represents {
      #datepicker{
        width: auto;
      }
      #timepicker{
        width: auto;
      }
      p{
        padding-left: 10%;
      }
    }
  }
  #board_title_container {
    font-size: 14px;
  }
  #journey-search-board-modes {
    margin: 0 5% 0 5%;
  }
  #ui-accordion-content-settings #bike_speed_0{
    label {
      padding-left: 0;
      i {
        margin-right: 4%
      }
      &:first-child{
        padding-left: 6%;
      }
    }

  }
  #ui-accordion-content-settings #walking_speed_0{
    label {
      padding-left: 0;
      i {
        margin-right: 4%
      }
    }
  }

  #notification{
    right: 30px;
  }

  @media (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /* IE10+ specific styles go here */
    #journey-search-board-modes .ui-accordion-header {
      justify-content: space-between;
    }
  }

  .locate-position, 
  .locate-position-porximity{
    margin: 6px 2% 0 0;
  }
  .datepicker-dropdown {
    min-width: 16rem !important;
  }
 
  #search_from input, #search_to input {
    width: 60%;
  }
  .autocomplete-clear {
    margin-right: 20%;
  }
  .kisio-details .kisio-co2-comparator .kisio-co2-car-compare .kisio-co2-value .kisio-co2-separator, .kisio-details .kisio-co2-comparator .kisio-co2-car-compare .kisio-co2-car-comparison .kisio-co2-separator, #kisio-full-details .kisio-co2-comparator .kisio-co2-car-compare .kisio-co2-value .kisio-co2-separator, #kisio-full-details .kisio-co2-comparator .kisio-co2-car-compare .kisio-co2-car-comparison .kisio-co2-separator{
    width: 0;
  }
  #ctp-proximity .autocomplete-clear{
    margin-right: 21%;
  }
  #ctp-proximityPoiForm legend .check_all {
    margin-right: 9%;
  }
  #proximity-search-time label {
    padding-left: 1%;
  }
  #ctp-proximityPoiForm legend .arrow-ikisio {
    margin: 3px 5px 0 0;
  }
  .board_open_proximity .expanded_proximity {
    height: 180px !important;
    .proximity-result-min {
      position: relative;
      transform: none;
      width: 100%;
    }
  }
  /**
  label.ctp-modes-traveler-type .ikisio {
    margin-top: 2px;
    margin-left: 12px;
    margin-right: 6%;
  }
  #bike_speed_0 label.ctp-modes-traveler-type .ikisio {
    margin: 6px 6% 0 12px;
  }
  **/
  #proximity-search-time label {
    font-size: 13px;
    padding: 2px 0 0 0;
  }
  #next_departures_results #prev_next #schedule-datepicker-bloc #schedule-datetime-body #schedule_from_datetime > .input-append > .input-group .schedule-timepicker {
    text-align: left;
    padding: 0 0 0 5px;
  }
  #kisio-full-details .kisio-co2-comparator .kisio-co2-car-compare .kisio-co2-value .kisio-co2-value-unit{
    min-width: 33%;
  }
  .journey-form-modify #cancel_search, #journey-form-new-search #cancel_search {
    float: left;
    margin: 10px 0 0 4%;
  }
  .search_datetime_represents p {
    padding-left: 10%;
    padding-right: 2%;
  }

  .kisio-btn-social {
    iframe {
      min-height: 500px;
    }
  }
  #soft_mode_tab{
    .img_content{
      width: 40%;
      img{
        height: 18px;
      }
    }
    .tab_content{
      width: 60%;
    }
  }

  #button_action{
    display: inline-flex;
    align-items: center;

    #change_direction {
      float: left;
      display: inline-flex;
      margin-left: 0;
    }

    #link-show-plan{
      float: right;
      margin-right: 0;
    }

    a{
      align-items: center;
    }
  }

  #geolocate-first-step{
    img{
      max-height: 110px;
    }
  }
  .img-favorite-container{
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .kisio-global-popup.show-time-table {
    min-width: 60%;
    max-width: 70%;

    .kisio-popup-body {
      max-width: 100%;
    }
  }

  .ctp-traffic .disruption__line-header {
    font-size: 11px;
  }
  .kisio-global-popup.show-time-table {
    min-width: 60%;
    max-width: 70%;
  }

  .proximity-results-container {
    margin-left: 0px !important;
  }
}