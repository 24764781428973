.traffic-home {
  &__header {
    height: auto;
    padding: 10px 20px;
    border-bottom: 1px solid #D0D0D0;

    &.traffic-home__empty {
      cursor: not-allowed;
    }

    &-infos {
      display: flex;
      justify-content: space-between;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;

      .last-update {
        font-size: 10px;
      }
    }
  }

  &__networks {
    display: flex;
    flex-direction: column;
    color: $color-main-one;
    font-size: 24px;
    line-height: 24px;
    margin-top: 10px;

    &.traffic-home__empty {
      pointer-events: none;

      summary {
        background-color: #D0D0D0;

        input {
          cursor: not-allowed;
        }
      }
    }

    summary:focus-visible {
      outline: auto;
    }
  }

  &__autocomplete {
    display: flex;
    line-height: 30px;
    position: relative;
    padding-top: 12px;

    .autocomplete-search-icon {
      position: absolute;
      right: 12px;
      top: 14px;
      color: #6F6F70;
    }

    input {
      width: 100%;
      border: 0.5px solid #6F6F70;
      border-radius: 17px;
      color: #000000;
      padding-left: 12px;
      font-size: 16px;

      &::placeholder {
        font-size: 12px;
        color: #6F6F70;
        line-height: 30px;
        font-style: italic;
      }

      &:focus {
        outline: none;
      }
    }

    &.autocomplete-display-radius {
      input {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }

    &.traffic-home__empty {
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  &__body {
    padding: 10px 20px;
    height: -webkit-fill-available;

    .active-disruptions, .future-disruptions {
      color: $color-main-one;
      font-weight: 700;
      font-size: 19px;
      line-height: 30px;

      .disruption__no-content {
        height: 70px;
        font-size: 13px;
        margin: 0;
      }
    }

    .future-disruptions {
      padding-top: 20px;
      height: 100%;
    }
  }

  &__modes {
    margin: 20px 0;

    &-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 23px;
    }

    &-content {
      margin-top: 5px;

      &.traffic-home__empty {
        .label__text {
          color: #D0D0D0;
          cursor: not-allowed;
        }

        .label__check {
          background: #D0D0D0;
          border: none;
          box-shadow: none;
          cursor: not-allowed;

          .picto_line {
            opacity: 0.3;
          }
        }
      }

      .label {
        display: inline-flex;
        width: 24%;
        margin: 0 auto;
      }

      .label__checkbox {
        display: none;
      }

      .label__check {
        display: inline-block;
        border-radius: 50%;
        border: 2px solid rgba(0,0,0,0.1);
        background: transparent;
        box-shadow: none;
        vertical-align: middle;
        width: 48px;
        height: 48px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: border .3s ease;

        .picto_line {
          font-size: 'calc(1rem + 1vw)';
          color: transparent;
          transition: opacity .3s .1s ease;
          -webkit-text-stroke: 3px rgba(0,0,0,.5);
          width: 34px;
        }
      }

      .label__text {
        font-size: 14px;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: auto;
      }

      .label__checkbox:checked + .label__text .label__check {
        animation: check .5s cubic-bezier(0.895, 0.030, 0.685, 0.220) forwards;

        .picto_line {
          opacity: 1;
          transform: scale(0);
          color: white;
          -webkit-text-stroke: 0;
          animation: picto_line .3s cubic-bezier(1.000, 0.008, 0.565, 1.650) .1s 1 forwards;
          filter: brightness(0) invert(1);
        }
      }
    }
  }
}

#traffic-home--autocomplete-clear {
  display: none;
}
