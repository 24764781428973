.from-to-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0 0 10px 0;

    button {
      display: flex;
      align-items: center;
      width: 120px;
      height: 34px;
      float: left;
      margin: 0 10px;
      font-size: 14px;
      background: #FFFFFF;
      background-color: rgb(255, 255, 255);
      box-shadow: 0px 4px 4px -2px rgba(37, 25, 66, 0.5);
      border-radius: 50px;
      border: none;
      font-weight: 600;
      justify-content: center;

      span {
        font-family: "Source Sans Pro", "Lato", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
      }

      &::before {
        transform: translateX(-5px);
      }

      &:hover {
        cursor: pointer;
        background-color: $color-main-one;
        color: #ffffff;
      }
    }
  }