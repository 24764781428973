.kisio-details, #kisio-full-details {
  display: none;
  padding: 0 2%;
  padding-bottom: 10px;
  position: relative;

  .toolbox-container {
    display: none;
  }
  ol {
    padding-left: 0;
    li {
      list-style-type: none;
    }
    .exit-container {
      display: flex;
      margin: 10px 0 10px 30px;
      align-items: center;
      .infos {
        margin-left: 15px;
      }
      .exit-informations {
        color: #10B00C;
        font-weight: 600;
        font-size: 14px;
      }
      .exit-icon {
        em {
          font-size: 24px;
        }
      }
      .exit-text {
        span {
          font-size: 600;
        }
      }
      .enter {
        span {
          font-weight: 600;
        }
      }
    }
  }

  .kisio-summary {
    background-color: #ffffff;
    list-style-type: none;
    margin-bottom: 2%;
    padding: 0 2% 0 2%;
    border-radius: 20px;
    .kisio-head-global {
      display: block;
      padding-bottom: 2%;
      padding-left: 10px;
      .kisio-head-summary {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #E5E5E5;
        position: relative;
        .header-left {
          display: flex;
          align-items: baseline;
        }
        ul.kisio-info-points {
          display: flex;
          padding: 10px;
          align-items: baseline;
          em {
            font-size: 10px;
          }
          .kisio-destination {
            margin-left: 7px;
          }
          .less-emission {
            font-size: 12px;
            margin-left: 12px;
          }
          .max-calories {
            font-size: 12px;
            margin-left: 12px;
          }
          .bike-types {
            font-size: 12px;
            margin-left: 10px;
            position: absolute;
            top: 5px;
            right: 10px;
          }
          .bike-subtitle {
            font-size: 12px;
          }
        }
        .kisio-disruptions {
          display: flex;
          justify-content: flex-end;
          font-size: 14px;
          font-weight: 700;
          line-height: 1.67;
          .information {
            color: #64a575;
          }
          .warning {
            color: #F85E0F;
          }
          .disrupt {
            color: #EC0000;
          }
        }
        li.kisio-direction {
          list-style-type: none;
          span {
            font-size: 14px;
          }
          margin-right: 2px;
          margin-left: 2px;
          display: flex;
          justify-content: center;
          align-items: end;
        }
        li.kisio-origin, li.kisio-destination {
          list-style-type: none;
          span {
            font-size: 14px;
          }
        }
        em {
          font-size: 20px;
        }
      }
      .kisio-body-summary {
        justify-content: space-between;
        margin-top: 10px;

        > div {
          display: flex;
        }
        .kisio-right-info {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          text-align: right;
          .kisio-duration-info {
            margin-left: 10px;
            span {
              color: $color-main-one;
              white-space:nowrap;
              font-weight: bold;
            }
          }
          .kisio-price-info {
            span {
              font-size: 13px;
              display: inline-block;
              float: right;
              color: #808080;
            }
          }
        }
        .kisio-left-info {
          display: flex;
          .kisio-duration-info {
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px;
            font-weight: 600;
            color: $color-main-two;
          }
          ul {
            display: flex;
            align-items: flex-end;
            padding: 0;
            li {
              margin-bottom: 8px;
              list-style-type: none;
              display: -webkit-box;
              .duration {
                font-size: 10px;
              }
              span.kisio-line-code {
                padding: 0 5px;
                height: 24px;
                position: relative;
                display: inline-block;
                line-height: 2;
                min-width: 24px;
                text-align: center;
                border-radius: 3px;
                font-family: $font-main-one;
                font-weight: bold;
                font-size: 12px;
              }
              .kisio-public-transport {
                position: relative;
                .ikisio-tad {
                  position: inherit;
                  border: solid 2px #ffffff;
                  background-color: $color-main-one;
                  background-clip: padding-box;
                  border-radius: 20px;
                  padding: 3px;
                  font-size: 9px;
                  color: #ffffff;
                  margin-left: -3px;
                  bottom: 2px;
                }
                .picto_line{
                  max-height: 24px;
                  border-top: solid 2px $color-main-one;
                  border-bottom: solid 2px $color-main-one;
                }
                .kisio-network {
                  display: none;
                }
              }
            }
            li.kisio-division {
              background-color: #B7B7B7;;
              border-radius: 7px;
              height: 7px;
              width: 7px;
              margin: auto 5px auto 5px;
            }
          }
        }
      }
      .pt_realtime{
        justify-content: normal;
        font-size: 11px;
        margin: 10px 0 0 0;
        .picto_line{
          height: 15px;
        }
        .kisio-line-code{
          padding: 3px 5px;
          font-size: 11px;
          height: 16px;
          border-radius: 3px;
          position: relative;
          font-family: $font-main-one;
          font-weight: bold;
          text-align: center;
        }
        .section_pt_realtime{
          float: right;
          color: #3f8178;
        }
        .img-pt-realtime{
          width: 15px;
          height: 15px;
          margin: 0 5px 0 0;
        }
      }
      .kisio-footer-summary {
        display: flex;
        width: 100%;
        position: relative;
        margin-top: 12px;
        align-items: center;
        .kisio-footer-co2, .total-walking, .total-calories {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          background: #F1F1F1;
          padding: 0 9px;
          border-radius: 16px;
          margin-left: 10px;
          img {
            height: 20px;
          }
        }
        #times_container {
          display: flex;
          font-size: 12px;
          background: #F1F1F1;
          padding: 0 9px;
          border-radius: 16px;
          align-items: center;
          img {
            margin-right: 10px;
            width: 12px;
            height: 12px;
          }
        }
        .kisio-price-info {
          margin-left: auto;
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }
  .kisio-section-no-type {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 2%;
    margin: 2% 0 2% 0;
    .kisio-bg-section {
      display: flex;
      .kisio-bloc-left {
        margin: auto 2%;
        .kisio-picto {
          img {
            width: 26px;
            height: 26px;
          }
        }
      }
      .kisio-info {
        display: flex;
        width: 100%;
        .kisio-time {
          margin: auto 4% auto 0;
        }
        .kisio-txtInfo {
          display: grid;
          .kisio-date {
            font-weight: bold;
            color: $color-main-one;
          }
        }
        .bike-percent{
          font-weight: bold;
          color: $color-main-one;
          font-size: 13px;
        }
      }
    }
    &.kisio-section-origin, &.kisio-section-destination {
      .kisio-bloc-right {
        display: flex;
        align-items: center;
        .google_street_view_link {
          color: $color-main-one;
          font-size: 30px;
          &:hover {
            font-size: 32px;
          }
        }
      }
    }
  }

  .kisio-section-public_transport, .kisio-section-on_demand_transport, .kisio-section-transfer, .kisio-section-ridesharing {
    padding: 2%;
    margin-bottom: 20px;

    .kisio-waiting-container {
      display: flex;
      align-items: center;
      border-radius: 8px;
      padding-left: 10px;
      margin: 10px 0;
      color: #FFFFFF;
      .waiting-left {
        flex: 0 0 80%;
        padding: 4px;
        display: flex;
        align-items: center;
        font-size: 18px;
      }
      .waiting-right {
        flex: 0 0 20%;
        background: rgba(255, 255, 255, 0.5);
        text-align: center;
        border-radius: 8px 8px 0px 8px;
        padding: 4px;
        display: none;
        em {
          font-size: 12px;
          margin-left: 3px;
        }
      }
      .kisio-time {
        font-weight: 600;
        margin-left: 5px;
      }
    }
    .kisio-bg-section {
      display: flex;
      line-height: 1.25;
      padding-left: 1%;
      .kisio-bloc-left {
        position: relative;
        min-width: 40px;
        margin-right: 2%;
        .kisio-picto {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-56%, -50%);
          em {
            padding: 30px 12px;
            border-radius: 8px;
            font-size: 23px;
            display: -webkit-box;
            text-align: center;
            min-width: 40px;
            line-height: 0.8;
            span {
              font-size: 11px;
              font-weight: bold;
              font-family: $font-main-one;
              white-space: pre;
              text-align: center;
              position: absolute;
              top: 58px;
              margin-left: -26px;
            }
          }
        }
        .kisio-picto-line {
          border-left: 4px solid;
          height: 100%;
          position: absolute;
          left: 50%;
          transform: translate(-50%);
        }
        .kisio-picto-circle {
          width: 15px;
          height: 15px;
          border: solid 3px;
          background-color: #f0f0f0;
          position: absolute;
          left: 50%;
          transform: translate(-50%);
          border-radius: 10px;
          &.top {
            top: 0;
          }
          &.bottom {
            bottom: 0;
          }
        }
      }
      .kisio-info {
        background: #FFFFFF;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
        border-radius: 16px;
        background-color: #ffffff;
        padding: 0 5% 3% 5%;
        width: 100%;
        font-weight: 400;

        span.kisio-time {
          font-size: 13px;
          line-height: 1.54;
          color: #FFFFFF;
        }

        .kisio-public-transport {
          padding: 6px 0;
          position: relative;
          display: flex;
          align-items: center;
          .ikisio-tad {
            position: inherit;
            right: 0;
            border: solid 2px #ffffff;
            background-color: $color-main-one;
            background-clip: padding-box;
            border-radius: 20px;
            padding: 3px;
            font-size: 9px;
            color: #ffffff;
            margin-left: -3px;
            bottom: 2px;
          }
          .picto_line{
            height: 24px;
          }
          .kisio-network {
            font-size: 12px;
            margin-left: 8px;
          }
        }
        ul {
          padding-left: 0;
          li.kisio-justify {
            justify-content: space-between;
            .clearfix {
              display: flex;
            }
          }
          li {
            display: flex;
            .ikisio-information-outline {
              position: relative;
              top: -15px
            }
            span.kisio-stop {
              display: inline-grid;
              .kisio-mode {
                font-size: 14px;
              }
              .kisio-direction {
                font-size: 13px;
                font-weight: bold;
                em {
                  margin-right: 8px;
                  font-size: 10px;
                }
              }
            }
          }
          .kisio-network {
            padding-top: 5px;
            display: list-item;
            font-size: 13px;
            line-height: 1.54;
            color: #808080;
            .kisio-network-name {
              color: $color-main-one;
              cursor: pointer;
            }
          }
        }
        .kisio-odt {
          font-size: 13px;
          line-height: 1.38;
          color: $color-main-one;
          .ikisio-tad {
            background-color: $color-main-one;
            background-clip: padding-box;
            border-radius: 20px;
            padding: 3px;
            font-size: 9px;
            color: #ffffff;
          }
        }
        .kisio-next-journey {
          width: 100%;
          border-radius: 8px;
          padding: 1px;
          margin-top: 5px;
          .kisio-next-journey_head {
            height: 32px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 4px 10px 0 10px;
            cursor: pointer;
            div {
              display: flex;
              flex-direction: row;
              align-items: center;
              img {
                height: 12px;
                width: 12px;
              }
              p {
                font-size: 14px;
                font-weight: 600;
                margin: 0 0 0 5px;
              }
            }
            em {
              font-size: 10px;
            }
          }
          ul {
            display: none;
            background: white;
            margin: 2px;
            border-radius: 0 0 8px 8px;
            padding: 3px 6px;
            li {
              display: flex;
              justify-content: space-between;
              align-items: center;
              background-color: #F2F2F2;
              margin: 3px 0;
              padding: 4px 8px;
              cursor: pointer;
              div {
                display: flex;
                flex-direction: row;
                align-items: center;
                span {
                  height: 8px;
                  width: 8px;
                  border-radius: 8px;
                }
              }
              .selector {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                height: 14px;
                width: 14px;
                border-radius: 14px;
                background-color: #FFFFFF;
                border: 1px solid #002830;
              }
              .name, .time {
                font-size: 12px;
                font-weight: 600;
                color: #000;
                margin-left: 8px;
                margin-bottom: 0px;
              }
              .time span {
                background: transparent;
                font-weight: 500;
              }
              .timed-title, .crossed {
                font-size: 12px;
                font-weight: 600;
                margin-bottom: 0;
              }
              .timed-title {
                margin-left: 8px;
                color: #DA1E28;
              }
              .crossed {
                text-decoration: line-through;
              }
              .late {
                color: #DA1E28;
              }
            }
            .active span {
              background: #1A3E45;
            }
          }
        }
        .kisio-next-journey.active {
          ul {
            display: block;
          }
        }
        .kisio-stop-lists {
          margin-top: 6px;
          .kisio-stops-description {
            position: relative;
            .kisio-stops-group-list {
              display: none;
              &.showing {
                display: flex;
                line-height: 2.08;
                .kisio-stop-name {
                  font-size: 12px;
                  font-weight: normal;
                }
                .ikisio-arrow-drop-down:before {
                  content: "\e929";
                }
              }
            }
            .showing {
              .ikisio-arrow-drop-down:before {
                content: "\e929";
              }
            }
            .kisio-stop-time {
              font-size: 11px;
              color: #3c3c3c;
              position: absolute;
              right: 0;
              font-weight: 600;
              font-size: 14px;
            }
            .kisio-stop-lists-separator {
              border-left: 4px solid;
              margin: 0 8px 0 8px;
              position: relative;
              &.top {
                margin-top: 4px;
              }
              &.bottom {
                margin-bottom: 4px;
              }
              .kisio-stop-lists-extrem {
                width: 11px;
                height: 11px;
                border: solid 1px;
                background-color: #ffffff;
                border-radius: 12px;
                position: absolute;
                transform: translateX(-60%);
                &.top {
                  top: 0;
                }
                &.bottom {
                  bottom: 0;
                }
              }
              .kisio-stop-lists-middel {
                width: 8px;
                height: 8px;
                position: absolute;
                transform: translate(-80%, 100%);
                border-radius: 20px;
              }
            }
            .kisio-stops-group {
              font-size: 12px;
              color: #000;
              padding: 15px;
              cursor: pointer;

              em {
                font-size: 8px;
                margin-right: 5px;
              }
            }
            .kisio-stop-name {
              display: inline-grid;
              font-size: 13px;
              margin-left: 10px;
            }
          }
        }
        .warning-pt {
          margin-top: 13px;
          font-size: 13px;
          .text-warning-pt {
            .ikisio {
              color: #eca33b;
            }
          }
        }
        .kisio-line-code {
          padding: 0 5px;
          height: 24px;
          position: relative;
          display: inline-block;
          padding-bottom: 3px;
          line-height: 2;
          min-width: 24px;
          text-align: center;
          border-radius: 3px;
          font-family: $font-main-one;
          font-weight: bold;
          font-size: 12px;
          margin-left: 3px;
        }
        .kisio-disruptions {
          .kisio-traffic-info {
            .kisio-traffic-accordion-header {
              display: flex;
              float: left;
              position: relative;
              font-size: 12px;
              line-height: 0.92;
              border-top: 1px solid;
              margin-top: 5px;
              padding-top: 14px;
              cursor: pointer;
              em {
                &.ikisio-disrupt-information {
                  color: #35af57;
                }
                &.ikisio-disrupt-warning {
                  color: #eca33b;
                }
                &.ikisio-disrupt-disrupt {
                  color: #a80b13;
                }
              }
              img {
                height: 14px;
                width: 14px;
              }
              .kisio-traffic-message {
                margin-left: 10px;
                p {
                  margin-bottom: 5px;
                  font-size: 14px;
                  &.kisio-warning {
                    color: #eca33b;
                  }
                }
              }
              .arrow-ikisio {
                font-weight: bold;
                position: absolute;
                right: 0;
              }
              &.kisio-information {
                border-top-color: #E2E2E2;
              }
              &.kisio-warning {
                border-top-color: #E2E2E2;
              }
              &.kisio-disrupt {
                border-top-color: #E2E2E2;
              }
            }
            .kisio-traffic-content {
              display: none;
              font-size: 12px;
              li {
                img {
                  max-width: 100%;
                }
              }
            }
          }
        }
      }
      .kisio-bloc-right {
        .disruption-bulle {
          position: absolute;
          transform: translate(-90%, -20%);
          font-size: 24px;
          background: #f1f1f1;
          padding: 4px;
          border-radius: 17px;
          height: 30px;
          width: 30px;
        }
      }
    }
  }

  .kisio-section-transfer {
    position: relative;
    .kisio-bloc-left {
      display: none;
    }
    .kisio-bloc-right {
      position: absolute;
      right: 20px;
      top: 50%;
      margin-top: -8px;
      cursor: pointer;
    }
    .kisio-bg-section .kisio-info {
      padding: 9px 8px 8px 12.5px;
      .transfer-mode-img {
        float: left;
        height: 100%;
        margin-right: 18.5px;
        display: flex;
        align-items: center;
        img {
          width: 24px;
          height: 24px;
        }
        .ctp-duration {
          font-size: 15px;
        }
      }
      .ctp-stop {
        font-size: 13px;
      }
    }
    .section-transfer {
      background-color: transparent !important;
      box-shadow: none !important;
      .transfer-info {
        display: flex;
        .transfer-duration {
          font-weight: 600;
          font-size: 16px;
        }
      }
      .ikisio-changer {
        font-size: 24px;
      }
    }
  }

  .kisio-section-street_network, .kisio-section-self_service {
    padding: 2%;
    background-color: #FFFFFF;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px #00000040;

    .kisio-detail-street-network {
      margin-left: 12%;
    }

    .kisio-bg-section {
      display: flex;
      line-height: 1.25;
      padding-left: 1%;
      .kisio-bloc-left {
        position: relative;
        min-width: 40px;
        background-position: 50% 2px;
        background-size: 12px 12px;
        height: auto;
        background-repeat: repeat-y;
        background-image: radial-gradient(#C1C1C1 25%, transparent 35%);
        .kisio-picto-street-network {
          .kisio-picto {
            font-size: 23px;
            min-width: 40px;
            line-height: 0.8;
            top: 50%;
            background-color: #f1f1f1;
            transform: translateY(-50%);
            position: absolute;
            text-align: center;
            img {
              padding: 4px 0;
            }
          }
        }
      }
      .kisio-info {
        width: 100%;
        padding: 5px 20px;
        border-radius: 20px;
        & > span {
          font-weight: bold;
        }
        .kisio-details-duration {
          .kisio-duration {
            display: flex;
            font-size: 15px;
          }
        }
        .transfer-mode-img {
          width: 12%;
          float: left;
          display: flex;
          align-items: center;
          em {
            font-size: 24px;
            padding-top: 7px;
          }
        }
        .duration {
          display: none;
        }
        .duration-container {
          span {
            font-weight: 600;
          }
        }
        .kisio-details-duration {
          font-weight: 600;
        }
        .kisio-detail-street-network {
          img{
            width: auto;
          }
          .kisio-detail-street-network-header {
            display: flex;
            margin: 4% 0;
            cursor: pointer;
            h4 {
              font-size: 15px;
              font-weight: 600;
              margin-bottom: 0;
            }
            i {
              margin: auto 4px;
              font-size: 11px;
              margin-left: 10px;
            }
          }
          ol {
            display: none;
            line-height: 1.58;
            li {
              font-size: 12px;
              font-weight: normal;
              margin-bottom: 5px;
              .ikisio {
                font-size: 1.1rem;
                vertical-align: sub;
              }
            }
          }
          .geovelo-picto {
            display: block;
            padding-bottom: 5%;
          }
        }
      }
      .bike-open {
        background-color: #ffffff;
        padding: 20px;
        border-radius: 20px;
      }
      .kisio-bloc-right {
        margin: auto 0;
        em {
          cursor: pointer;
          font-size: 22px;
        }
      }
    }
  }
  .destination-container {
    display: flex;
    justify-content: space-between;
    background-color: #10B00C;
    padding: 2%;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    margin-left: 2%;
    margin-right: 2%;

    color: #FFFFFF;
    .left {
      display: flex;
      .destination {
        margin-left:  10px;
        font-weight: 600;
      }
    }
    .arrival-time {
      font-weight: 600;
    }
  }
  .kisio-co2-comparator {
    display: flex;
    .kisio-co2-leaf {
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 8px;
      background-color: #10B00C;
      border-radius: 0 16px;
      margin-left: 2%;
    }
    .kisio-co2-car-compare {
      width: 100%;
      padding: 2%;
      border-radius: 5px;
      margin-left: -25px;
      padding-left: 7%;
      background-color: #10B00C;
      border-radius: 16px 0 16px 16px;
      color: #FFFFFF;
      margin-right: 2%;
      .kisio-co2-value, .kisio-co2-car-comparison {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .kisio-co2-mode-text {
          font-weight: bold;
          min-width: 20%;
          font-size: 13px;
        }
        .kisio-co2-separator {
          width: 100%;
          border-bottom: dotted 2px #F1F1F1;
          margin-bottom: 2%;
        }
        .kisio-co2-value-unit {
          min-width: 20%;
          text-align: right;
          font-size: 13px;
        }
      }
      .co2-one-result {
        transform: translateY(50%);
      }
    }
  }
  .kisio-section-park, .kisio-section-bss_rent, .kisio-section-bss_put_back {
    padding: 2%;
    .kisio-bg-section {
      display: flex;
      line-height: 1.25;
      padding-left: 1%;
      .kisio-bloc-left {
        position: relative;
        min-width: 40px;
        margin-right: 2%;
        .kisio-picto {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-56%, -50%);
          em {
            padding: 17px 6px;
            border-radius: 8px;
            font-size: 28px;
            display: -webkit-box;
            text-align: center;
            min-width: 40px;
            line-height: 0.8;
            background-color:#000000;
            color:#ffffff;
            span {
              font-size: 11px;
              font-weight: bold;
              font-family: $font-main-one;
              white-space: pre;
            }
          }
        }
        .kisio-picto-car, .kisio-picto-self-service-bike {
          border-left: 4px solid;
          height: 100%;
          position: absolute;
          left: 50%;
          transform: translate(-50%);
          border-color: #000000;
        }
        .kisio-picto-circle {
          width: 15px;
          height: 15px;
          border: solid 3px;
          background-color: #f0f0f0;
          position: absolute;
          left: 50%;
          transform: translate(-50%);
          border-radius: 10px;
          border-color: #000000;
          &.top {
            top: 0;
          }
          &.bottom {
            bottom: 0;
          }
        }
      }
      .kisio-info {
        border-radius: 5px;
        background-color: #ffffff;
        padding: 3% 5%;
        width: 100%;
        font-size: 13px;
        line-height: 1.54;
        .kisio-bss_rent, .kisio-bss_put_back {
          .kisio-picto {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .mode-code-container-bss {
              display: flex;
              align-items: center;
            }
          }
        }
        .kisio-picto {
          em {
            font-size: 24px;
            margin-right: 4px;
          }
          .kisio-network-code {
            background-color: #000000;
            color: #ffffff;
            font-size: 16px;
            padding: 3px;
            font-weight: 200;
            border-radius: 6px;
            height: auto;
          }
        }
        .kisio-label {
          margin-top: 3%;
          p {
            margin: 0;
            font-size: 14px;
          }
        }
        .kisio-places-info {
          font-size: 14px;
          font-weight: bold;
          color: $color-main-one;
          display: flex;
          .kisio-available-places {
            padding: 3% 0 0 1%;
            width: 100%;
          }
        }
      }
      .kisio-bloc-right {
        margin: auto 0;
        em {
          font-size: 22px;
        }
      }
    }
  }
  .kisio-section-disruptions {
    text-align: center;
    margin-bottom: 7%;
    padding: 0 22%;
    .kisio-disruptions-lines {
      width: 100%;
      justify-content: center;
      display: inline-flex;
      .kisio-section-type {
        margin-right: 7%;
        .kisio-line-code {
          padding: 0 5px;
          height: 24px;
          position: relative;
          display: inline-block;
          min-width: 24px;
          text-align: center;
          border-radius: 3px;
          font-family: $font-main-one;
          line-height: 2;
          font-weight: bold;
          font-size: 12px;
          .disruption-type {
            position: absolute;
            height: 16px;
            width: 16px;
          }
        }
      }
    }
    .kisio-journey-traffic-link {
      padding: 10px;
      border-radius: 23px;
      background-color: $color-main-one;
      font-size: 17px;
      font-weight: bold;
      letter-spacing: 0.4px;
      border: none;
      color: #ffffff;
      &:hover {
        padding: 12px;
        text-decoration: none;
      }
      &:active{
        background-color: $color-main-one-dark;
      }
    }
  }
  .section-ridesharing {
    font-family: $font-main-one;
    .ridesharing-header {
      height: 36px;
      display: flex;
      align-items: center;
      padding: 2px 0;
      font-weight: bold;
      h4{
        font-size: 16px;
        padding: 0;
        margin: 0;
      }
      .ikisio-ridesharing-new {
        font-size: 20px;
        margin-right: 5px;
      }
    }
    .ridesharing-content {
      display: flex;
      align-items: revert;
      .ridesharing-content-left {
        width: 85%;
        font-size: 14px;
        padding-top: 25px;
        .street {
          margin: 0 0 3px 0;
          img {
            width: 12px;
          }
        }

        hr  {
          width: 50%;
          margin-left: 0px;
        }

        .ico {
          margin-right: 5px;
        }

        em {
          color: $color-main-two;
        }
      }
      .ridesharing-content-right {
        display: flex;
        flex-direction: column;
        align-items: end;
        width: 15%;
        height: 100%;
        div {
          width: 100%;
          text-align: center;
          margin-top: 6px;
        }
        .total_fare {
          background: #F1F1F1;
          padding: 0 9px;
          border-radius: 16px;
          font-size: 14px
        }
        .driver_name {
          font-size: 14px
        }
        img.driver_avatar {
          width: 60px;
          height: 60px;
        }
      }
    }
    .ridesharing-footer {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
}

.kisio-car-no-park {
  &__dynamic_speeds {
    width: 200px;
    font-size: 12px;
    font-style: italic;
    font-size: 12px;
    font-weight: 200;
    padding-top: 10px;
    margin-left: 12%;

    .percentage-bar {
      min-width: 200px;
      height: 10px;
      display: flex;

      div:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      div:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .kisio-details, #kisio-full-details {
    .kisio-section-public_transport, .kisio-section-on_demand_transport {
      .kisio-bg-section {
        .kisio-bloc-left {
          .kisio-picto {
            em {
              display: block;
            }
          }
        }
      }
    }
  }
}
//For chrome only
@media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) {
  #kisio-full-details .kisio-section-public_transport .kisio-bg-section .kisio-bloc-left .kisio-picto i span {
    float: right;
    margin: 10px 0 0 0;
    width: 100%;
    text-align: center;
  }
}
//For firefox only
@-moz-document url-prefix() {
  #kisio-full-details .kisio-section-public_transport .kisio-bg-section .kisio-bloc-left .kisio-picto i span {
    float: none;
  }
}