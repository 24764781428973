.schedule-line-departures {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__card {
    background: #FFFFFF;
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    margin: 10px 20px;
    padding: 0 20px;

    .header {
      display: flex;
      flex-direction: column;
      padding: 0 0 10px 10px;
      border-bottom: 1px solid #E5E5E5;

      &__resume {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;

        i {
          font-size: 15px;
          margin-right: 5px;
        }
      }

      strong {
        font-weight: 600;
      }
    }

    .departures {
      padding: 15px 0;

      &__title {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: $color-main-one;
      }

      &__date {
        float: right;
        font-size: 14px;
        font-style: italic;
        font-weight: 500;
        margin-top: 6px;
      }

      &__item {
        display: flex;
        justify-content: space-between;

        &:first-child {
          margin-top: 5px;
        }

        div {
          span {
            font-size: 14px;
            font-style: italic;
            font-weight: 500;
            margin-top: 6px;
          }

          margin-left: 20px;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: $color-main-one;
          width: 100px;
          text-align: end;

          .realtime-picto {
            padding-bottom: 10px;
            margin-right: 5px;
          }
        }
      }
    }
  }

  &__timetable {
    margin-top: 10px;
    height: 100%;
    background-color: #F5F5F5;
    border-radius: 10px 10px 0 0;
    padding: 3% 20px;

    .timetable {
      display: flex;
      flex-direction: column;
      padding-bottom: 25px;

      &__title {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: $color-main-one;
      }

      &__sub-title {
        font-family: 'Inter';
        font-style: italic;
        font-weight: 300;
        font-size: 14px;
        line-height: 12px;
      }

      &__form {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        summary {
          padding: 0 30px 0 10px;
          border-radius: 20px;
        }

        details {
          width: 60%;

          ul {
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
          }
        }
      }

      .first-last-departure {
        display: flex;
        flex-direction: column;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 15px;
        color: $color-main-one;
        text-align: end;
      }

      .timetable_paginate {
        overflow: auto;
      }
    }

    #timetable-datepicker {
      width: auto;
      height: 40px;

      input {
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border: none;
        background-color: #FFFFFF;
        cursor: pointer;
        height: 40px;

        &:focus {
          outline: none;
        }
      }

      span {
        width: 28px;
        font-size: 22px;
        background-color: #FFFFFF;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        padding-left: 7px;
      }
    }
  }

  &__disruptions {
    padding: 10px 20px;

    span {
      display: block;
      width: 100%;
      border-radius: 13px;
      background: #FFF;
      box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.25);
      color: $color-traffic-disrupt;
      font-size: 14px;
      padding: 5px;

      i {
        margin-right: 5px;
      }

      a {
        color: $color-traffic-disrupt;
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer !important;
      }
    }
  }
}
