.expanded {
    background: transparent;

    #proximity_content #proximity-results {
        display: none !important;
    }
}

#board_container.board_min_proximity {
    top: unset !important;
    bottom: 0px !important;
}

.board_open_proximity {
    top: unset !important;
    bottom: 0px !important;
    border-top-left-radius: 30px !important; 
    border-top-right-radius: 30px !important;
    height: 55px !important;

    #mobile-minimizer {
        position: relative;
        flex-flow: column;
        justify-content: end;
        display: flex;
        border-top-left-radius: 20px; 
        border-top-right-radius: 20px;
        height: 55px;
        background-color: $color-main-one;
        text-align: center;
        color: $color-text;
        
        p {
            cursor: pointer;

            &::before {
                content: url("data:image/svg+xml,%3Csvg width='18' height='30' viewBox='0 0 18 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.2529 17.2948C16.186 17.5165 16.0691 17.6851 15.9278 17.7635C15.7865 17.8419 15.6324 17.8237 15.4991 17.7129L8.99998 12.2998L2.50198 17.7148C2.43587 17.771 2.36373 17.805 2.28972 17.8146C2.21571 17.8242 2.14129 17.8092 2.07076 17.7707C2.00022 17.7321 1.93496 17.6707 1.87874 17.5899C1.82252 17.5091 1.77646 17.4105 1.7432 17.2999C1.70994 17.1893 1.69015 17.0688 1.68496 16.9454C1.67978 16.8219 1.6893 16.698 1.71299 16.5808C1.73667 16.4635 1.77405 16.3552 1.82296 16.2622C1.87187 16.1691 1.93136 16.0931 1.99798 16.0385L8.74798 10.4135C8.8262 10.3482 8.91249 10.3142 8.99998 10.3142C9.08748 10.3142 9.17376 10.3482 9.25198 10.4135L16.002 16.0385C16.1351 16.1499 16.2362 16.3447 16.2832 16.5802C16.3303 16.8157 16.3193 17.0727 16.2529 17.2948Z' fill='white'/%3E%3C/svg%3E");
                position: absolute;
                top: 12px;
                left: 13px;
                height: 15px;
            }
        }

        &::after {
            display: none;
            position: absolute;
            top: 10;
            left: 0;
            right: 0;
            margin: auto;
            height: 15px;
            cursor: pointer;
        }
    }
}

.board_expanded_proximity {
    height: auto !important;
    border-top-right-radius: 30px !important;
    border-top-left-radius: 30px !important;

    #mobile-minimizer {
        height: 20px;
        background: transparent;

        &::after {
            display: block !important;
        }

        p {
            display: none;
        }
    }
}

.board_open_proximity .expanded_proximity {
    box-shadow: none;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    height: auto !important;

    h2 {
        line-height: 22px;
        font-size: 22px;
    }

    .proximity_result_title {
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
        padding: 15px 10px 0px 10px;
        .favorites_standalone_link.proximity {
            display: flex !important;
        }

        .ikisio-back {
            font-size: 30px;
        }

        h1 {
            font-size: 20px;
            margin: 0 5px 0 0;
        }
        h2 {
            margin: 0 0 0 10px;
        }
    }

    .proximity-result-min {
        top: 125px;
        transform: none;

        .proximity-results-container {
            margin-left: 10px;
        }

        .ctp-proxi-accordion-min {
            margin: 0 4px;
        }
    }

    #ctp-searchProximity {
        margin-bottom: 65px;

        .locate-position-proximity {
            margin: 6px 30px 0 0;
        }

        .autocomplete-clear {
            margin-right: 50px;
        }
    }

    #ctp-poi-bloc {
        legend {
            border-radius: 15px;
            height: 40px;
            padding: 0 5px;
            font-size: 16px;
        }

        img {
            margin: 0 5px 0 0;
            height: 20px;
            width: 20px;
            padding: 2px;
        }
    }

    .slider {
        height: 30px;
    }
}

.ctp-proximity {
    #proximity_title, .proximity_title {
        border-bottom: none;
        padding-bottom: unset !important;
        background: none !important;
        background-color: none !important;
    }

    .ikisio-back {
        color: $color-main-one !important;
    }
}

.board_open {
    #proximity_content {
        .proximity-full {
            display: flex;
            flex-flow: column;
            overflow: hidden;
            max-height: 50vh;

            .proximity_stoparea_content {
                flex: 1;
                overflow-y: hidden;
            }
        }
    }
}

.board_expanded_proximity.board_open {
    top: unset !important;
    bottom: 0 !important;
    height: 50vh !important;
}

.board_open_autocomplete {
    top: unset !important;
    bottom: 0 !important;
    height: 75vh !important;
}

#proximity_content {
    #ctp-proximity {
        height: auto;
    }

    #proximity_filters_content {
        .proximity_filters_titles {
            padding: 0px 15px 15px;
        }

        .proximity_filters_containers {
            padding: 0;
        }

        .proximity_filters_validate {
            bottom: 0;
        }

        .ctp-libelle-picto {
            font-size: 14px;
        }
    }

    #ctp-proximity-stoparea,
    #ctp-proximity-filters {
        // height: 10vh;
        // overflow: hidden;

        .ikisio-close {
            top: 15px;
            transform: unset;
        }

        #proximity_stoparea_content {
            .proximity_stoparea_title {
                margin-bottom: 20px;
            }
            .proximity_poi_content {
                padding: 0px 10px;
            }
            .proximity_stoparea_routes_container {
                height: 31vh;   
            }
        }
    }

    #ctp-proximity-stoparea .proximity_title_filter {
        display: none !important;
    }
}

#new-seach-button {
    top: 5px;
}

#toggleStopArea {
    bottom: 120px;
}