.kisio-result, .ctp-favorites-accordion {
  background-color: #f0f0f0;
  border-radius: 5px;
  z-index: 1000001;
  position: relative;

  &__header-resume {
    padding-bottom: 15px;
    border-radius: 13px;

    #ui-accordion-content-settings {
      &:before {
        top: 50%;
      }
    }

    #journey-search-board-modes {
      display: none;
    }

    .favorites_standalone_link {
      display: none;
    }

    .favorites_standalone_link.button-reverse {
      display: flex;
    }

    #search_from, #search_to {
      height: 34px;
      background-color: #FFFFFF;
    }

    #search_from {
      margin: 0% 5%;
    }

    #journey-form-reverse {
      &:focus-visible {
        outline: auto;
      }
      display: flex;
      align-content: center;
      justify-content: center;
      border: solid 3px $color-main-two !important;
      border-radius: 11px !important;
    }

    #journey-search-reverse {
      right: 14%;
      top: 33%;
    }

    .result-back-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 3%;

      a , em {
        color: $color-main-one !important;
      }
    }

    .journey-search-form {
      padding: 10px 0;
      margin: 0 3%;
      border-radius: 13px;
    }
  }

  .kisio-result-content {
    &__empty-section {
      background-color: #FFF;
      height: 100px;
      text-align: center;
      margin: auto;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      margin-top: 10px;
    }
  }

  .kisio-journey-link {
    margin: 2%;
    margin-top: 5%;
    list-style: none;
    a {
      &:focus-visible {
        outline: auto;
      }
      color: $color-main-one;
      background: #ffffff;
      padding: 5px 10px;
      border-radius: 16px;
      font-weight: 600;
      font-size: 14px;
    }
  }

  .kisio-link-next {
    float: right;
  }

  .kisio-result-bloc, #list-journey_solution {
    background-color: #f0f0f0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: auto;

    .kisio-datetime-changed {
      background-color: #FFFFFF;
      margin: 4% 2% 2% 2%;
      padding: 2%;
      border-radius: 5px;
      display: flex;

      i {
        color: $color-main-one;
        margin: 3%;
        font-size: 20px;
      }

      span {
        font-size: 14px;

        p {
          margin: 0;
        }
      }
    }

    ul.kisio-result-content {
      padding: 0 2% 2% 2%;
      margin-bottom: 0;

      &.empty {
        margin: 0;
        padding: 0;
      }

      .tabs {
        list-style: none;
        padding: 0;
        height: 10px;
        width: 100%;
      }

      .tabs li {
        display: none;
        width: 30%;
        margin: 2% 5% 2% 0;
        float: left;
        background: #fff;
        border-radius: 21px;
        padding: 5px;
        border: 1px solid #fff;

        &:last-of-type {
          margin: 2% 0;
        }

        .img_content {
          width: 50%;
          float: left;
          text-align: center;

          img {
            margin: 0 auto;
          }
        }

        .tab_content {
          width: 50%;
          text-align: center;
          color: $color-main-one;
          white-space: nowrap;
          font-weight: bold;
          display: block;
          float: right;

          img {
            width: 20px;
          }
        }
      }

      .tabs li:hover {
        color: #fff;
        cursor: pointer;
        border: 1px solid $color-main-one;
      }

      .modal {
        width: 100%;
        background: white;
        position: absolute;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        color: $color-main-one;
        font-weight: 600;
      }
    }

    li.kisio-result-shadow {
      border-radius: 5px;

      &:hover {
        box-shadow: 0px 4px 5px 0 rgba(66, 66, 66, 0.31);
      }
    }

    li.kisio-result-journey, .item {
      position: relative;
      list-style-type: none;
      clear: both;
      border-radius: 20px;

      &.selected {
        box-shadow: 0px 4px 5px 0 rgba(66, 66, 66, 0.31);
      }

      .kisio-summary {
        background-color: #ffffff;
        list-style-type: none;
        margin: 3% 0;
        padding: 0 2% 0 2%;
        border-radius: 20px;
        font-weight: 400;

        .kisio-head-global {
          display: block;
          padding-bottom: 2%;
          padding-left: 10px;

          .kisio-head-summary {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #E5E5E5;
            position: relative;

            .header-left {
              display: flex;
              flex-flow: row;
              align-items: baseline;

              &-infos {
                display: flex;
                flex-flow: column;
                align-items: baseline;
              }

              .future-date {
                font-size: 12px;
                color: rgba(236, 16, 16, 1);
              }
            }

            ul.kisio-info-points {
              display: flex;
              padding: 2px;
              align-items: baseline;

              em {
                font-size: 12px;
              }

              .kisio-destination {
                margin-left: 7px;
              }

              .less-emission {
                font-size: 12px;
                margin-left: 12px;
              }

              .bike-types {
                font-size: 12px;
                margin-left: 10px;
                position: absolute;
                top: 5px;
                right: 10px;
              }

              .max-calories {
                font-size: 12px;
                margin-left: 12px;
              }

              .traffic-density {
                margin-left: 10px;
                font-size: 12px;
              }
            }

            .kisio-disruptions {
              display: flex;
              justify-content: flex-end;
              font-size: 14px;
              font-weight: 700;
              line-height: 1.67;

              .information {
                color: #64a575;
              }

              .warning {
                color: #F85E0F;
              }

              .disrupt {
                color: #EC0000;
              }
            }

            li.kisio-direction {
              list-style-type: none;

              span {
                font-size: 14px;
              }

              margin-right: 2px;
              margin-left: 2px;
              display: flex;
              justify-content: center;
              align-items: end;
            }

            li.kisio-origin {
              list-style-type: none;

              span {
                font-size: 14px;
                font-weight: 600;
              }
            }

            li.kisio-destination {
              list-style-type: none;

              span {
                font-size: 14px;
                font-weight: 700;
              }
            }

            em {
              font-size: 20px;
            }
          }

          .kisio-body-summary {
            justify-content: space-between;
            margin-top: 10px;

            > div {
              display: flex;
            }

            .kisio-right-info {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              text-align: right;

              .kisio-duration-info {
                margin-left: 10px;
                span {
                  color: $color-main-one;
                  white-space: nowrap;
                  font-weight: bold;
                }
              }

              .kisio-price-info {
                span {
                  font-size: 13px;
                  display: inline-block;
                  float: right;
                  color: #808080;
                }
              }
            }

            .kisio-left-info {
              display: flex;
              justify-content: space-between;
              width: 100%;

              .kisio-duration-info {
                margin-left: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .duration {
                  font-weight: 600;
                  color: $color-main-two;
                }

                .total-distance {
                  font-size: 12px;
                  font-weight: 400;
                  color: #808080;
                }
              }

              ul {
                display: flex;
                align-items: center;
                padding: 0;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                width: fit-content;
                margin: 0;

                li {
                  // margin-bottom: 12px;
                  list-style-type: none;
                  display: -webkit-box;

                  .duration {
                    font-size: 10px;
                    margin-left: 3px;
                  }

                  span.kisio-line-code {
                    padding: 0 5px;
                    height: 24px;
                    position: relative;
                    display: inline-block;
                    line-height: 2;
                    min-width: 24px;
                    text-align: center;
                    border-radius: 3px;
                    font-family: $font-main-one;
                    font-weight: bold;
                    font-size: 12px;
                  }

                  .kisio-public-transport {
                    position: relative;

                    .ikisio-tad {
                      position: inherit;
                      border: solid 2px #ffffff;
                      background-color: $color-main-one;
                      background-clip: padding-box;
                      border-radius: 20px;
                      padding: 3px;
                      font-size: 9px;
                      color: #ffffff;
                      margin-left: -3px;
                      bottom: 2px;
                    }

                    .picto_line {
                      max-height: 24px;
                    }

                    .kisio-network {
                      display: none;
                    }
                  }

                  .transfer-mod-img {
                    display: flex;
                    align-content: center;
                    align-items: center;
                  }
                }

                &.mode-type {
                  &__bike, &__bss {
                    align-items: start;
                    flex-direction: column;

                    .percentage {
                      position: relative;
                      color: #76CC5B;
                      font-style: italic;
                      font-weight: 600;
                      font-size: 12px;
                    }

                    .percentage-bar {
                      min-width: 200px;
                      height: 10px;
                      display: flex;

                      div:first-child {
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                      }

                      div:last-child {
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                      }
                    }

                    .bike-subtitle {
                      font-size: 12px;
                      font-style: italic;
                    }
                  }

                  &__carnopark {
                    display: flex;
                    flex-direction: column;
                    align-items: self-start;

                    .kisio-car-no-park__dynamic_speeds {
                      padding-top: 0;
                      margin-left: 0;
                    }
                  }
                }
              }
              .kisio-division {
                background-color: #B7B7B7;;
                border-radius: 7px;
                height: 7px;
                width: 7px;
                margin: 0px 5px 0px 5px;
              }
            }
          }

          .pt_realtime {
            justify-content: normal;
            font-size: 11px;
            margin: 10px 0 0 0;

            .picto_line {
              height: 15px;
            }

            .kisio-line-code {
              padding: 3px 5px;
              font-size: 11px;
              height: 16px;
              border-radius: 3px;
              position: relative;
              font-family: $font-main-one;
              font-weight: bold;
              text-align: center;
            }

            .section_pt_realtime {
              float: right;
              color: #3f8178;
            }

            .img-pt-realtime {
              width: 15px;
              height: 15px;
              margin: 0 5px 0 0;
            }
          }

          .kisio-footer-summary {
            display: flex;
            width: 100%;
            position: relative;
            margin-top: 12px;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-end;

            .kisio-footer-co2, .total-walking, .total-calories {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              background: #F1F1F1;
              padding: 0 9px;
              border-radius: 16px;
              margin-left: 10px;

              img {
                height: 15px;
              }
            }

            .times_container {
              display: flex;
              font-size: 12px;
              background: #F1F1F1;
              padding: 0 9px;
              border-radius: 16px;
              align-items: center;

              img {
                margin-right: 10px;
                width: 12px;
                height: 12px;
              }
            }

            .kisio-price-info {
              margin-left: 5%;
              font-size: 12px;
              font-weight: 500;
              background: #F1F1F1;
              padding: 0 9px;
              border-radius: 16px;
            }
          }
        }
      }

      .vertical-bar-warning {
        position: absolute;
        top: 5%;
        left: 0;
        height: 90%;
        width: 5px;
        border-radius: 10px;
        background: #F85E0F;
        left: 10px;
      }

      .vertical-bar-disrupt {
        position: absolute;
        top: 5%;
        left: 0;
        height: 90%;
        width: 5px;
        border-radius: 10px;
        background: #EC0000;
        left: 10px;
      }
    }

    li.kisio-result-journey:last-child {
      margin-bottom: 0%;
    }
  }

  .kisio-error-message {
    background-color: #FFFFFF;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    padding-bottom: 5%;

    em {
      position: absolute;
      margin-left: 4.5%;
      font-size: 25px;
      color: $color-main-one;
      background-color: #FFFFFF;
      padding: 1%
    }

    li {
      border: solid 2px $color-main-one;
      border-radius: 11px;
      list-style-type: none;
      margin: 3% 5% 0 5%;
      padding: 3%;
      text-align: center;
      width: 100%
    }
  }

  .disruption-type {
    position: absolute;
    right: -10px;
    top: -5px;
    background-color: #ffffff;
    font-size: 12px;
    border-radius: 10px;
    padding: 2px;
    height: 19px;
    margin-left: -3px;
    margin-top: -3px;

    &.ikisio-disrupt-information {
      color: #64a575;
    }

    &.ikisio-disrupt-warning {
      color: #F85E0F;
    }

    &.ikisio-disrupt-disrupt {
      color: #a80b13;
    }
  }

  .kisio-full-details {
    margin: 5% 3%;
  }
}

.kisio-fdr-link {
  margin: 2%;
  height: 0;
  display: none;

  a {
    &:focus-visible {
      outline: auto;
    }
    color: $color-main-one;
    background: #ffffff;
    padding: 5px 10px;
    border-radius: 16px;
    font-weight: 600;
    font-size: 14px;

    em {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

.fdr-link-next {
  float: right;
}

#kisio-result-col {
  .kisio-result-summary {
    outline: none;
    position: relative;
    z-index: 1000000;

    .selected-journey {
      color: $color-main-two;
      padding-left: 30px;

      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: 250px;
        background-color: #f0f0f0;
        right: 0;
        top: 80px;
        z-index: -1;
      }
    }

    .kisio-duplicate-section {
      background-color: #ffffff;
      list-style-type: none;
      margin: 2%;
      padding: 0 2% 0 2%;
      border-radius: 20px;
      box-shadow: -1px 2px 2px 2px rgba(0.15, 1, 2, 0.15);
      border-radius: 16px;
      margin-right: 4%;
      margin-left: 4%;

      .kisio-summary {
        background-color: #ffffff;
        list-style-type: none;
        margin-bottom: 2%;
        padding: 0 2% 0 2%;
        border-radius: 20px;

        .distance-text {
          position: absolute;
          bottom: 78px;
          left: 165px;
          font-weight: 600;
          font-size: 14px;
        }

        .future-date {
          font-size: 12px;
          color: rgba(236, 16, 16, 1);
        }

        .kisio-head-global {
          display: block;
          padding-bottom: 2%;
          padding-left: 10px;

          .kisio-head-summary {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #E5E5E5;
            position: relative;

            .header-left {
              display: flex;
              align-items: baseline;
            }

            ul.kisio-info-points {
              display: flex;
              padding: 2px;
              align-items: baseline;
              margin-bottom: 0;

              em {
                font-size: 10px;
              }

              .kisio-destination {
                margin-left: 7px;
              }

              .less-emission {
                font-size: 12px;
                margin-left: 12px;
              }

              .bike-types {
                font-size: 12px;
                margin-left: 10px;
                position: absolute;
                top: 5px;
                right: 10px;
              }

              .max-calories {
                font-size: 12px;
                margin-left: 12px;
              }

              .traffic-density {
                margin-left: 10px;
                font-size: 12px;
              }
            }

            .kisio-disruptions {
              display: flex;
              // justify-content: flex-end;
              font-size: 14px;
              font-weight: 700;
              line-height: 1.67;

              .information {
                color: #64a575;
              }

              .warning {
                color: #F85E0F;
              }

              .disrupt {
                color: #EC0000;
              }
            }

            li.kisio-direction {
              list-style-type: none;

              span {
                font-size: 14px;
              }

              margin-right: 2px;
              margin-left: 2px;
              display: flex;
              justify-content: center;
              align-items: end;
            }

            li.kisio-origin {
              list-style-type: none;

              span {
                font-size: 14px;
                font-weight: 600;
              }
            }

            li.kisio-destination {
              list-style-type: none;

              span {
                font-size: 14px;
                font-weight: 700;
              }
            }

            em {
              font-size: 20px;
            }
          }

          .kisio-body-summary {
            justify-content: space-between;
            margin-top: 10px;

            > div {
              display: flex;
            }

            .kisio-right-info {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              text-align: right;

              .kisio-duration-info {
                margin-left: 10px;
                span {
                  color: $color-main-one;
                  white-space: nowrap;
                  font-weight: bold;
                }
              }

              .kisio-price-info {
                span {
                  font-size: 13px;
                  display: inline-block;
                  float: right;
                  color: #808080;
                }
              }
            }

            .kisio-left-info {
              display: flex;
              justify-content: space-between;
              width: 100%;

              .kisio-duration-info {
                margin-left: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .duration {
                  font-weight: 600;
                  color: $color-main-two;
                }

                .total-distance {
                  font-size: 12px;
                  font-weight: 400;
                  color: #808080;
                }
              }

              ul {
                &:nth-child(0) {
                  margin-left: 10px;
                  background-color: yellow;
                }
                display: flex;
                align-items: center;
                padding: 0;
                margin-bottom: 5px;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                width: fit-content;

                li {
                  list-style-type: none;
                  display: -webkit-box;

                  .duration {
                    font-size: 10px;
                    margin-left: 3px;
                  }

                  span.kisio-line-code {
                    padding: 0 5px;
                    height: 24px;
                    position: relative;
                    display: inline-block;
                    line-height: 2;
                    min-width: 24px;
                    text-align: center;
                    border-radius: 3px;
                    font-family: $font-main-one;
                    font-weight: bold;
                    font-size: 12px;
                  }

                  .kisio-public-transport {
                    position: relative;

                    .ikisio-tad {
                      position: inherit;
                      border: solid 2px #ffffff;
                      background-color: $color-main-one;
                      background-clip: padding-box;
                      border-radius: 20px;
                      padding: 3px;
                      font-size: 9px;
                      color: #ffffff;
                      margin-left: -3px;
                      bottom: 2px;
                    }

                    .picto_line {
                      max-height: 24px;
                    }

                    .kisio-network {
                      display: none;
                    }
                  }
                }

                &.mode-type {
                  &__bike, &__bss {
                    align-items: start;
                    flex-direction: column;

                    .percentage {
                      position: relative;
                      color: #76CC5B;
                      font-style: italic;
                      font-weight: 600;
                      font-size: 12px;
                    }

                    .percentage-bar {
                      min-width: 200px;
                      height: 10px;
                      display: flex;

                      div:first-child {
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                      }

                      div:last-child {
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                      }
                    }

                    .bike-subtitle {
                      font-size: 12px;
                      font-style: italic;
                    }
                  }

                  &__carnopark {
                    flex-direction: column;
                    align-items: start;

                    .kisio-car-no-park__dynamic_speeds {
                      margin-left: 0;
                    }
                  }
                }
              }
              .kisio-division {
                background-color: #B7B7B7;;
                border-radius: 7px;
                height: 7px;
                width: 7px;
                margin: 0px 5px 0px 5px;
              }
            }
          }

          .pt_realtime {
            justify-content: normal;
            font-size: 11px;
            margin: 10px 0 0 0;

            .picto_line {
              height: 15px;
            }

            .kisio-line-code {
              padding: 3px 5px;
              font-size: 11px;
              height: 16px;
              border-radius: 3px;
              position: relative;
              font-family: $font-main-one;
              font-weight: bold;
              text-align: center;
            }

            .section_pt_realtime {
              float: right;
              color: #3f8178;
            }

            .img-pt-realtime {
              width: 15px;
              height: 15px;
              margin: 0 5px 0 0;
            }
          }

          .kisio-footer-summary {
            display: flex;
            width: 100%;
            position: relative;
            margin-top: 12px;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-end;

            .kisio-footer-co2, .total-walking, .total-calories {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              background: #F1F1F1;
              padding: 0 9px;
              border-radius: 16px;
              margin-left: 10px;

              img {
                height: 15px;
              }
            }

            .times_container {
              display: flex;
              font-size: 12px;
              background: #F1F1F1;
              padding: 0 9px;
              border-radius: 16px;
              align-items: center;

              img {
                margin-right: 10px;
                width: 12px;
                height: 12px;
              }
            }
            .hide {
              display: none;
            }

            .kisio-price-info {
              margin-left: auto;
              font-size: 12px;
              font-weight: 500;
            }
          }
        }

        .disruption-type {
          position: absolute;
          right: -10px;
          top: -5px;
          background-color: #ffffff;
          font-size: 12px;
          border-radius: 10px;
          padding: 2px;
          height: 19px;
          margin-left: -3px;
          margin-top: -3px;

          &.ikisio-disrupt-information {
            color: #64a575;
          }

          &.ikisio-disrupt-warning {
            color: #F85E0F;
          }

          &.ikisio-disrupt-disrupt {
            color: #a80b13;
          }
        }
      }

      .vertical-bar-warning {
        position: absolute;
        top: 21%;
        height: 78%;
        width: 5px;
        border-radius: 10px;
        background: #F85E0F;
        left: 25px;
      }

      .vertical-bar-disrupt {
        position: absolute;
        top: 21%;
        height: 78%;
        width: 5px;
        border-radius: 10px;
        background: #EC0000;
        left: 35px;
      }
    }
  }
}

#result_content {
  position: relative;
}

.result-back-button {
  a {
    &:focus-visible {
      outline: auto;
    }
    color: #000000 !important;

    em {
      color: #ffffff;
      font-size: 14px !important;
    }
  }
}

.kisio-result-toolbox {
  position: absolute;
  transform: translateX(100%);
  right: 0;
  top: 0;
  margin-top: 110px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: $color-main-one;

  .kisio-toolbox {
    list-style-type: none;
    padding: 2px 5px;
    margin: 0;

    li {
      border-bottom: 1px solid rgba(255, 255, 255, 0.17);
      padding: 1px;
      text-align: center;
      height: 30px;
      width: 30px;

      a:hover {
        text-decoration: none;
      }

      i {
        color: #FFFFFF;
        width: 24px;
        height: 24px;
        border-radius: 20px;
        padding: 6px 4px;
        font-size: 13px;
        text-align: center;
      }

      i.ikisio-mail {
        font-size: 15px;
        display: block;
        padding-left: 7px;
        font-weight: bold;
      }
    }

    li:hover {
      background-color: $color-main-one-dark;
      border-radius: 20px;
      background-clip: content-box;
    }

    li:last-child {
      border-bottom: 0;
    }
  }
}

#mail_popup_form {
  width: 500px;
  margin: 0 auto;

  input {
    border-radius: 4px;
    border: solid 1px #e6e6e6;
    background-color: #f1f1f1;
    margin-bottom: 10px;
    padding-left: 10px;
  }

  #contact_email_from, #contact_email_to, #contact_lastname, #contact_firstname {
    width: 400px;
    height: 47px;
  }

  #contact_content {
    width: 402px;
    height: 107px;
    padding: 10px;
    background-color: #f1f1f1;
    border-radius: 4px;
    border: solid 1px #e6e6e6;
  }

  #contact_save {
    width: 160px;
    height: 44px;
    border-radius: 4px;
    background-color: $color-main-one;
    color: #fff;
    float: right;
    margin: 10px 49px 0 0;
    font-weight: bold;
    border: none;
  }
}

#journey_content.open_search {
  .kisio-result__header-resume, .kisio-result__header {
    height: 100%;
  }
}

.ikisio-carnopark-density {
  height: 7px;
  width: 7px;
  position: absolute;
  border-radius: 5px;

  &__medium {
    background-color: #FF6F08;
  }

  &__high {
    background-color: #CF3434;
  }
}

.added-li {
  list-style: none;

  .bike-types {
    background-color: #76CC5B;
    color: #FFFFFF;
    border-radius: 10px;
    padding: 0 10px;
  }
}

.kisio-modes  {
  margin-top: 15px;
}
