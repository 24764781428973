@import "../_variables.scss";

#proximity_content {
  display: none;

  #proximity_title,
  .proximity_title {
    padding-left: 20px;
  }
}

#title_container,
.title_container {
  position: relative;

  #proximity_clear {
    cursor: pointer;
  }

  .ikisio-back {
    position: absolute;
    left: 20px;
    top: 25px;
    font-size: 24px;
    color: #ffffff;
  }

  .proximity_title_filter {
    height: 60px !important;
    color: $color-text;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 0;

    em {
      position: static !important;
      height: 25px;
      width: 20px;
      margin: 5px 15px 0 32px;
    }

    span {
      // margin-left: 70px;
      font-size: 20px;
      font-weight: 700;
    }
  }

  .ikisio-close {
    position: absolute;
    right: 25px;
    background: $color-text;
    color: $color-main-one;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    bottom: 50%;
    transform: translate(50%, -50%);
    cursor: pointer;
  }

  .ikisio-close:before {
    font-size: 12px;
  }
}

#ctp-proximity-filters {
  display: none;
}

.proximity-full {
  .proximity_result_title {
    display: flex;
    justify-content: flex-start;
    padding: 15px 20px 0px 20px;
    div {
      display: flex;
      align-items: center;
    }
  }

  .ikisio-back {
    cursor: pointer;
    position: relative;
    font-size: 34px;
    color: $color-main-one;
  }

  h1 {
    color: $color-main-one;
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    margin: 7px 0 0 26px;
  }
}

#ctp-searchProximity {
  // padding: 10px;
  // box-shadow: 0 5px 2px rgba(0, 0, 0, 0.3);
}

#mobile-minimizer {
  display: none;
  z-index: 100;

  &::after {
    content: url("data:image/svg+xml, %3Csvg width='20' height='11' viewBox='0 0 20 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.329481 0.940002C0.418601 0.644282 0.574444 0.419529 0.762838 0.315021C0.951233 0.210513 1.15681 0.23478 1.33448 0.382502L9.99998 7.6L18.664 0.380002C18.7521 0.304954 18.8483 0.259728 18.947 0.246931C19.0457 0.234134 19.1449 0.254018 19.239 0.305439C19.333 0.356859 19.42 0.438798 19.495 0.546531C19.5699 0.654265 19.6314 0.785662 19.6757 0.933147C19.72 1.08063 19.7464 1.24129 19.7533 1.40585C19.7603 1.57042 19.7476 1.73563 19.716 1.89198C19.6844 2.04832 19.6346 2.19271 19.5693 2.3168C19.5041 2.44089 19.4248 2.54224 19.336 2.615L10.336 10.115C10.2317 10.2021 10.1166 10.2475 9.99998 10.2475C9.88332 10.2475 9.76828 10.2021 9.66398 10.115L0.663981 2.615C0.486549 2.46647 0.351697 2.20673 0.288992 1.89274C0.226288 1.57875 0.240848 1.23613 0.329481 0.940002Z' fill='black'/%3E%3C/svg%3E%0A");
    width: 75px;
    height: 8px;
  }
}

#proximity-search-form {
  padding: 0 20px;
  position: relative;

  .autocomplete-clear {
    z-index: 500;
    top: 0;
  }

  .locate-position,
  .locate-position-proximity {
    top: 0;
    margin: 6px 35px 0 0;
    right: 0;
  }

  .ikisio-zoom {
    position: absolute;
    color: $color-main-one;
    margin: 9px;
  }

  #proximity-search-input {
    padding-left: 30px;
    width: 100%;
  }
}

#proximity-search-time {
  list-style-type: none;
  flex-direction: row;
  justify-content: space-between;
  padding: 3% 20px 0 3%;
  text-align: center;

  #proximity-search-time-label {
    color: #3c3c3c;
    font-size: 12px;
  }

  #proximity-search-time-input .ctp-modes-traveler-type:last-of-type {
    float: none;
  }

  li {
    padding: 0;
    width: 49%;
  }

  input[type="radio"] {
    opacity: 0.011;
    position: absolute;
    z-index: 100;
  }

  input[type="radio"]:checked+label {
    color: $color-text;
    border: 1px solid #CCC;
    box-shadow: 1.4px 1.4px 2px 0 rgba(0, 0, 0, 0.23);
    background-color: $color-main-one;
  }

  label {
    color: #000000;
    background-color: #ffffff;
    border-radius: 15px;
    text-align: left;
    cursor: pointer;
    width: 30%;
    padding-left: 6%;

    i.icon_datetime_represents {
      font-size: x-large;
      vertical-align: middle !important;
      margin-right: 3%;
    }
  }

  label:hover {
    color: $color-text;
    background: $color-main-one;
  }

  p {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 2%;
  }
}

#proximity-form-submit {
  text-align: center;
  padding-bottom: 5%;
  padding-top: 10px;
  height: 80px;

  button {
    cursor: pointer;
    width: 172px;
    height: 46px;
    text-align: center;
    border-radius: 23px;
    background-color: $color-main-one;
    font-size: 17px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    border: none;
    color: #ffffff;

    &:hover {
      width: 176px;
      height: 50px;
      margin-top: -2px;
    }

    &:active {
      background-color: $color-main-one-dark;
    }
  }
}

#proximity_search_uri {
  >div {
    position: relative;

    #myPopup {
      // bottom: 40px;
      // margin-top: 0;
    }
  }

  div {
    width: 100%;
  }

  label {
    display: none;
  }

  input {
    overflow: visible;
    outline: none;
    text-overflow: ellipsis;
    background-color: transparent;
    border: none;
    height: 34px;
    width: 85%;
    padding-left: 30px;
  }

  background-color: #f1f1f1;
  border-radius: 10px;
  margin: 5% 5% 0 0;
  display: flex;
  width: 100%;
}

#ctp-proximity-home {
  .ctp-results {
    h2 {
      float: left;
      margin: 0.8em 0;
      font-weight: 300;
    }

    .link-show-summary {
      margin-top: 0.188em;
      margin-right: 0.5em;
    }

    #ctp-proximity-poi {
      form {
        .ctp-proxi-accordion {

          .ui-accordion-header {
            min-height: 3.563em;
            padding: 1.125em 1.75em 1.125em 3.125em;
            width: 100%;
          }

          fieldset {
            input {
              margin: 0 0.5em
            }
          }

          .ui-accordion-content {
            border: 1px solid red;
          }

          .ui-accordion-content-active {
            padding: 1.25em 1.25em 0;
          }
        }
      }
    }

    .ctp-right {
      float: right;
    }

    #ctp-poi-bloc .active_category legend {
      border: 0.3px solid $color-main-two;
      background-color: $color-main-two;
      color: #FFF;
    }
  }

  #poi_list {
    background-color: white;
    margin: 20px -10px 0 -10px;
    padding: 2px;
    height: auto;

    #display_poi {
      margin: 5px 0;
    }

    #display_stops_addresses {
      margin: 5px 0;

      ul.ctp-list-point {
        .arrow-ikisio {
          margin-right: 9px;
        }

        .ctp-libelle-picto {
          padding-left: 17px;
        }
      }
    }

    h3 {
      font-size: 1.5em;
      margin: 1em 0;
      clear: both;
    }

    h4,
    .ctp-see-map {
      font-size: 0.813em;
    }

    h4 {
      font-weight: bold;
      padding: 0 0 0.625em 0;
    }

    .ctp-see-map {
      display: block;
      margin-bottom: 0.938em;
    }

    ul.ctp-list-point {
      .arrow-ikisio {
        margin: 0 6px 0 10px;
        font-size: 14px;
      }

      & .section-poi-content {
        display: none;

      }

      fieldset {
        border-radius: 23px;
        width: auto;
        margin: -25px -5px 15px -5px;
        padding: 12px 12px 0 12px;

        legend {
          background-color: #f1f1f1;
          padding-top: 0;
          cursor: pointer;
          height: 40px;
          vertical-align: middle;

          &:active,
          &:focus {
            outline: none;
          }

          span.ctp-libelle-picto {
            padding-left: 10px;
            vertical-align: middle;
            font-size: 15px;
          }

          img {
            max-width: 24px;
            vertical-align: middle;
          }

          h3 {
            font-size: 20px;
            cursor: pointer;
          }
        }
      }

      ul {
        padding: 0 15px;
      }

      li {
        background-color: #f1f1f1;
        clear: both;
        margin-bottom: 1.5em;
        list-style-type: none;
        width: 100%;
        border-radius: 10px;
        padding: 15px;
        position: relative;

        h4 {
          img {
            max-width: 24px;
          }
        }

        .bss-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &.ctp-stop-address {
          h4 .ikisio {
            margin-right: 5px;
            font-size: 1rem;
          }

          .secondary-button .ikisio {
            margin-right: 0;
            margin-left: 5px;
          }
        }

        .buttons_travel {
          position: absolute;
          top: 15px;
          right: 15px;

          button {
            border: none;
            background: #f1f1f1;
          }
        }
      }
    }

    .ctp-actions {
      li {
        float: left;
        border: 1px solid #e1e1e1;
        margin-right: 0.313em;

        a {
          display: block;
          width: 1.875em;
          height: 1.875em;
          padding: 0.313em;
        }
      }
    }

    .bss-availability,
    .car-park-availability {
      font-size: 0.8em;

      >p {
        margin-bottom: 0;
      }
    }
  }

  .ctp_information {
    background: none repeat scroll 0 0 #F4DFDF;
    padding: 0.95em 0;
    text-align: center;
    position: relative;
    z-index: 10;
    width: inherit;
    display: block;
  }

  #ctp-mapFull {
    .ctp-expand-details {
      position: relative;
    }
  }

  .ctp-responsive-map {
    #ctp-result-map {
      position: relative;
      margin-top: 5px;
    }
  }

  .ctp-proximity-poi-checkbox {
    .checkbox-container {
      width: 1.75em;
      display: table-cell;
    }

    .label-container {
      display: table-cell;
    }

    .label-struct {
      display: table;

      label {
        display: table-row;
      }

      .label-icon {
        display: table-cell;
        width: 3em;

        img {
          margin-bottom: 4px;
        }
      }

      .label-text {
        display: table-cell;
        width: auto;
      }
    }

    padding: 0.5em;
  }

  .ctp-links {
    margin-bottom: 0.5em;

    li {
      padding: 0.25em 0;

      a {
        font-size: 0.9em;
      }
    }
  }
}

#ctp-proximity .autocomplete-clear {
  display: none;
  position: absolute;
  margin: 6px 60px 0 0;
  right: 0;
  cursor: pointer;

  i {
    color: #B3B5B5;
  }
}

#ctp-proximityPoiForm {
  position: relative;

  button {
    all: unset;
    &:focus-visible {
      legend {
        outline: auto;
      }
    }
  }

  legend {
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    height: 36px;
    padding-top: 9px;

    .arrow-ikisio {
      float: left;
      margin: 3px 10px 0 0;

    }

    >span {
      float: right;
      margin: 0 10% 0 0;
    }

    .check_all {
      float: right;
      margin: -3px 7% 0 0;
      position: relative;

      &:hover input+.checkmark {
        background-color: #ccc;
        cursor: pointer;
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 25px;
        width: 25px;
        z-index: 99999;
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background: #fff;
        border: solid 1px #eee;
        border-radius: 15px;
      }

      input:checked+.checkmark {
        background-color: $color-main-one;
      }

      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      input:checked+.checkmark:after {
        display: block;
      }

      .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }

  .ctp-button-primary {
    display: none;
  }
}

.ctp-proximity-poi-checkbox {
  width: 100%;
  height: 46px;
  border-radius: 23px;
  margin: 0 0 15px 0;
  padding: 9px 18px;
  cursor: pointer;

  span {
    cursor: pointer;
  }

  .label-icon img {
    margin-bottom: 4px;
  }

  .checkbox-container {
    float: right;
    display: block;
    position: relative;
    margin: 0 19px 0 0;

    &:hover input+.checkmark {
      background-color: #ccc;
      cursor: pointer;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 25px;
      width: 25px;
      z-index: 99999;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background: #fff;
      border: solid 1px #eee;
      border-radius: 15px;
    }

    input:checked+.checkmark {
      background-color: $color-main-one;
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  input:checked+.checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .label-container {
    float: left;
  }
}

.poi-unchecked {
  background-color: #F4F4F4;
}

.poi-checked,
.category_checked {
  background-color: lighten($color-main-one, 50%);
}

.category_unchecked {
  background-color: #f1f1f1;
}

#proximity-results {
  padding: 10px 10px 0 10px;
  height: auto;
  max-height: 65vh;
  display: none;

  #ctp-poi-bloc legend {
    margin: 0;
  }

  .ctp-proxi-accordion fieldset>div {
    padding: 0 10px;
    border-radius: 23px;
    background-color: var(--$color-main-one);
  }

  .ctp-proxi-accordion:last-child {
    .ctp-proximity-poi-checkbox:last-child {
      margin-bottom: 0;
    }
  }

  #ctp-proximityPoiForm {
    #proximity_search_uri {
      display: none !important;
    }
  }
}

.proximity-results-marge {
  top: 38px !important;
}

.proximity-result-min {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%);
  padding: 0 !important;

  .proximity-results-container {
    display: flex;
    position: relative;
    margin-left: 30px;
    overflow: hidden;

    #ctp-proximityBundle {
      width: 100%;
    }
  }

  .ctp-content {
    // margin: 13px 0;
  }

  #ctp-poi-bloc {
    display: flex;
    flex-flow: row;
    width: fit-content;
    min-width: 100%;
    justify-content: space-between;
    padding: 2px;
  }

  .ctp-proxi-accordion-min {
    margin: 0 8px;
    padding: 5px 0;
    background: transparent;
  }

  #ctp-poi-bloc legend {
    margin: 12px 0;
    background: #ffffff;
    color: $color-main-one;
    border: 0.3px solid $color-main-one;
    border-radius: 50px;
    font-size: 18px;
    padding: 0 35px;
    margin: 25px 10px;
    display: flex;
    align-items: center;
    height: 46px;
    width: max-content;
  }

  #ctp-poi-bloc .disable_category {
    position: relative;
    border: 0.3px solid rgba(37, 25, 66, 0.3);

    &::before {
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      background-color: #E5E5E5;
      top: 0;
      right: 0;
      border-radius: 50px;
      opacity: 0.7;
      cursor: default;
    }
  }

  #ctp-poi-bloc .active_category legend {
    border: 0.3px solid $color-main-two;
    background-color: $color-main-two;
    color: #FFF;
  }

  .ctp-proxi-accordion fieldset>div {
    padding: 0 10px;
    border-radius: 23px;
    background-color: var(--$color-main-one);
  }

  .ctp-proxi-accordion:last-child {
    .ctp-proximity-poi-checkbox:last-child {
      margin-bottom: 0;
    }
  }

  #ctp-poi-bloc img {
    height: 30px;
    width: 30px;
    border: 1px solid $color-main-one;
    border-radius: 50%;
    padding: 5px;
    margin: 0 15px 0 0;
    object-fit: contain;
  }
}

#mapid {
  .marker-cluster-small {
    background-color: $color-main-one-dark;
  }

  .marker-cluster-small div {
    background-color: $color-main-one;
  }

  .marker-cluster-medium {
    background-color: $color-main-one-dark;
  }

  .marker-cluster-medium div {
    background-color: $color-main-one;
  }

  .marker-cluster-large {
    background-color: $color-main-one-dark;
  }

  .marker-cluster-large div {
    background-color: $color-main-one;
  }

  .proximity-poi-icon {
    z-index: 199 !important;
  }
}

.leaflet-popup {
  margin-left: 150px;
}

.leaflet-popup-tip-container {
  margin-left: -30px !important;
  overflow: hidden !important;
  pointer-events: none !important;
  left: 0 !important;
  top: 10px !important;
  transform: rotate(90deg);

  .leaflet-popup-tip {
    background-color: $color-main-one;
  }
}

.leaflet-popup-close-button {
  // display: none;
}

.leaflet-marker-shadow {
  color: $color-main-one;
}

.leaflet-marker-icon-container {
  height: 30px;
  width: 30px;
}

.leaflet-active-popup {
  cursor: pointer;
  height: 30px;
  width: 30px;
  background: red;

  svg {
    path {
      fill: $color-main-two ;
    }
  }
}

#leaflet-popup-header .leaflet-popup-header-img {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFF;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 10px;
}

.leaflet-popup-header-img img {
  margin: 0 !important;
}

.proximity-poi-popup {
  #leaflet-popup-header {
    padding-right: 40px;
    display: flex;
    align-items: center;
    font-weight: 600;

    &:not(.nocontent) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .img-mode-container {
      min-width: 40%;
    }

    // img {
    //   margin-right: 10px;
    //   vertical-align: top;
    //   float: left;
    //   width: 24px;
    // }
    .lines {
      display: inline-block;
      float: none;
      margin-right: 5px;
      margin-bottom: 5px;
      margin-left: 0;
      flex: 1 1 auto;
      max-width: 60px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .disruption-type {
      position: inherit;
      height: 15px;
      margin-left: -9px;
      background: #fff;
      border-radius: 8px;
      margin-top: -6px;
      margin-right: 0;
    }
  }

  #leaflet-popup-header {
    .lines {
      border-radius: 2px;
      font-weight: bold;
      line-height: 2;
      float: left;
      height: 24px;
      display: block;
      min-width: 24px;
      text-align: center;
      font-size: 12px;
      padding: 0 5px;

      img {
        float: left;
      }

      span {
        padding: 0 5px;
        font-weight: bold;
        line-height: 2;
        float: left;
        height: 24px;
        display: block;
        min-width: 24px;
        text-align: center;
        border-radius: 3px;
        font-family: $font-main-one;
      }
    }

    img {
      margin-right: 10px;
    }
  }

  #leaflet-popup-content {
    padding: 15px;
    min-height: unset;

    &.nocontent {
      display: none;
    }

    .proximity-poi-info-container {
      display: flex;
      justify-content: space-between;
      align-items: start;
      flex-flow: row;

      .proximity-poi-info-left-side {
        width: 72%;

        .proximity-poi-address {
          padding-left: 10px;
        }
      }

      .proximity-poi-info-right-side {
        text-align: right;

        .proximity-poi-distance {
          padding-right: 5px;

          img {
            transform: scaleX(-1);
            width: 18px;
            height: 18px;
          }

          &.proximity-poi-freefloating {
            font-weight: bold;
          }

          .freefloating-walk {
            width: 14px;
            margin-right: 5px;
          }
        }
      }

      .proximity-poi-mode-row {
        display: flex;
        flex-flow: row;

        .proximity-poi-mode-row-lines {
          display: flex;
          flex-flow: row;
          align-content: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          display: flex;
        }

        img {
          display: flex;
          height: 20px;
          width: 20px;
          border: 1px solid;
          border-radius: 50%;
          padding: 1px;
          margin: 7px 10px 0 5px;
        }

        span {
          position: relative;
          font-weight: bold;
          line-height: 2;
          float: left;
          height: 24px;
          display: block;
          min-width: 24px;
          text-align: center;
          font-family: $font-main-one;
          padding: 0 3px;
          font-size: 12px;
          margin: 5px 2px;
          border-radius: 5px;
          img {
            all: inherit;
            margin: 0;
          }
        }
      }
    }

    .proximity-poi-info-column-container {
      flex-flow: column;
    }

    .proximity-poi-name-container {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
    }

    .proximity-poi-distance-container {
      display: flex;
      justify-content: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
    }

    .from-to-buttons-container {
      margin-top: 10px;
    }

    #popup-arrival-btn {
      width: 105px;
    }
  }
}

#mapid.leaflet-container a.leaflet-popup-close-button {
  font-weight: normal;
  width: auto;
  top: 0;
  right: 0;
}

.leaflet-tooltip.walk-legend {
  border-radius: 16px;
  background-color: $color-main-one;
  border: none;
  color: white;
  font-weight: bold;
  padding: 5px 15px;
}

.leaflet-interactive.solutions-circle {
  fill: $color-main-one;
  fill-opacity: 0.15;
  stroke: lighten($color-main-one, 9.5%);
}

#div_show_poi_list {
  width: 100%;
  padding-top: 10px;
  height: 70px;
  margin: 0 0 10px 0;
  display: none;
}

#show_poi_list {
  cursor: pointer;
  width: 210px;
  height: 46px;
  text-align: center;
  border-radius: 23px;
  background-color: $color-main-one;
  font-size: 17px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  border: none;
  color: $color-text;
  display: block;
  margin: 0 auto 20px auto;

  &:hover {
    width: 214px;
    height: 50px;
  }

  &:active {
    background-color: $color-main-one-dark;
  }
}

.poi_list {
  display: none;

  ul.ctp-list-point {
    padding: 0 5px;
  }
}

#proximity-show-hide-stops {
  text-align: center;

  button {
    color: #000000;
    background-color: #ffffff;
    border-radius: 15px;
    cursor: pointer;
    width: auto;
    text-align: center;
    padding: 1% 3%;

    &:hover {
      color: $color-text;
      background: $color-main-one;
    }
  }
}

#proximity_filters_content {
  .proximity_filters_titles {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    padding: 32px 33px 28px;

    span {
      font-size: 24px;
      line-height: 24px;
      color: $color-main-one;
      font-weight: 700;
    }

    button {
      position: relative;
      font-size: 18px;
      line-height: 22px;
      font-weight: 600;
      color: $color-text;

      background-color: $color-main-one;
      padding: 7px 26px;
      border-radius: 50px;
    }
  }

  .proximity_filters_containers {
    padding: 0 20px;

    .proximity_filters_containers_titles_container {
      position: relative;
      overflow: hidden;
    }

    .proximity_filters_containers_titles {
      display: flex;
      flex-flow: row nowrap;
      height: 75px;
      width: fit-content;

      button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 10px;
        border-radius: 50px;
        font-size: 15px;
        font-weight: 600;
        line-height: 18px;
        margin: 15px 10px;
        cursor: pointer;
        border: 1px solid #E5E5E5;
        width: max-content;

        img {
          height: 20px;
          width: 20px;
          border: 1px solid $color-main-one;
          border-radius: 50%;
          padding: 2px;
          margin: 0 5px 0 0;
        }

        p {
          margin: auto;
        }
      }

      .active_title {
        background: $color-main-two;
        color: #FFF;
        border: 1px solid $color-main-two;

        img {
          background: #FFF;
        }
      }
    }

    .proximity_filters_contents_container {
      background-color: #F8F8F8;
      border: 1px solid rgba(37, 25, 66, 0.3);
      border-radius: 20px;
      margin: 0px 10px;

      .proximity_filters_contents {
        display: none;
        flex-flow: column;
        padding: 10px 20px 15px 20px;

        .proximity_filters_select {
          display: flex;
          flex-flow: row;
          justify-content: space-between;
          background: #FFF;
          border: 1px solid $color-main-one;
          border-radius: 40px;
          padding: 10px 15px;
          height: 40px;
          display: flex;
          align-items: center;
          margin: 5px 0;

          span {
            margin: 0 10px 0 10px;
          }
        }

        .proximity_filters_select:first-child {
          margin-top: 0;
        }

        .picto_line {
          height: 20px;
          width: 20px;
          padding: 2px;
          border: 1px solid $color-main-one;
          border-radius: 50%;
        }

        .ctp-libelle-picto {
          color: $color-main-one;
          font-size: 18px;
          font-weight: 400;
          line-height: 17px;
          border: none;
        }
      }

      .active_content {
        display: flex;
      }

      .proximity_filters_all_select {
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: $color-text-two;
        margin: 10px 20px 0 0;
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      span {
        width: 16px;
        height: 16px;
        background: #FFF;
        border: 1px solid #000;
        border-radius: 50%;
        margin: 0 25px 0 13px;
      }

      input:checked~span {
        background-color: $color-main-two;
        border-color: $color-main-two;
      }

      input:focus-visible~span {
        outline: auto;
      }
    }
  }

  .proximity_filters_validate {
    all: unset;
    position: absolute;
    bottom: 60px;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    background-color: $color-main-two;
    color: #FFF;
    text-align: center;
    border-radius: 50px;
    padding: 5px 25px;
    width: fit-content;
    border: none;
    cursor: pointer;
    outline: auto;
  }
}

// Slider Elements
.slider {
  position: absolute;
  height: 46px;
  width: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  top: 0;
  bottom: 0;
  margin: auto 0;

  &::before {
    position: absolute;
    content: '';
    background: linear-gradient(270deg, #251942 -3%, rgba(255, 255, 255, 0) 100%);
    height: 100%;
    width: 100%;
    opacity: 0.5;
  }
}

.slider-right {
  right: 0px;
}

.slider-right:after,
.slider-left:after {
  // content: url("data:image/svg+xml,%3Csvg viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.2207 4L3.87444 0.666667L0.528186 4' stroke='%23251942' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  // transform: rotate(90deg);
  font-family: "ikisiofonticon" !important;
  height: 30px;
  width: 30px;
  background: $color-main-one;
  content: $ikisio-arrow-destination;
  color: #FFF;
  border-radius: 50%;
  z-index: 1;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 900;
  padding: 5px;
}

.slider-left {
  transform: rotate(-180deg);
}

// Search btn on map mouve
#new-seach-button {
  max-width: 275px;
  width: fit-content;
  background-color: $color-main-one;
  color: $color-text;
  border-radius: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  position: absolute;
  top: 110px;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 1000;
  display: flex;
  align-items: center;
  padding: 7px 10px;
  cursor: pointer;

  svg {
    margin-right: 17px;
  }

  &:focus-visible {
    outline: auto;
  }
}

.new-seach-button-hidden {
  display: none !important;
}

#ctp-proximity-stoparea,
#ctp-proximity-filters {
  .ikisio-back {
    top: 18px;
  }

  #proximity_title, .proximity_title {
    cursor: default;
    margin: 0;
    padding-left: 75px;

    em {
      margin: 5px 15px 0 85px;
    }
  }

  .proximity_stoparea_title {
    margin: 20px auto 30px 20px;
    color: $color-main-one;
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;

    img {
      margin-right: 10px;
      height: 30px;
      width: 30px;
      border: 1px solid $color-main-one;
      border-radius: 50%;
      padding: 2px;
    }
  }
}

#board-history {
  display: none;
}

#ctp-proximity-stoparea {
  display: none;
  flex-direction: column;
  height: 100vh;

  .proximity_stoparea_container {
    height: 100%;
    display: flex;
    flex-flow: column;

    .back {
      display: flex;
      align-items: baseline;
      font-size: 14px;
      color: $color-main-one;
      margin: 10px 0 0 8px;
      cursor: pointer;

      .ikisio-back {
        font-size: 11px;
        top: 0;
        padding-right: 2px;
      }
    }

  }
  
  .proximity_stoparea_content {
    flex-grow: 1;

    .proximity_stoparea_mode_container {
      margin: 0 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: $color-main-one;
      color: $color-text;
      border-radius: 16px 16px 0 0;
      min-height: 34px;
      padding: 6px 18px;

      .proximity_stoparea_mode {
        cursor: pointer;
        position: relative;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        width: fit-content;
        margin: 5px 10px 10px 0px;
        display: flex;
        align-content: center;
        align-items: center;

        img {
          height: 28px;
          width: 28px;
          background: white;
          border-radius: 50%;
          padding: 2px;
          margin-right: 5px;
        }
      }

      .active {
        color: $color-main-two;

        img {
          background: $color-main-two;
        }

        &::after {
          position: absolute;
          content: ' ';
          bottom: -7px;
          width: 38px;
          background-color: $color-main-two;
          right: 0;
          left: 0;
          margin: 5px auto 0 auto;
          height: 3px;
        }
      }
    }
  }

  .proximity_stoparea_routes_container,
  .proximity_poi_content {
    display: none;
    flex-grow: 1;
    // overflow: scroll;
    height: 80vh;
    padding: 28px 20px 5px 20px;
    border-radius: 10px 10px 0px 0px;

    .proximity_stoparea_route,
    .poi_container {
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      margin-bottom: 20px;

      // padding: 20px;
      .proximity_stoparea_route_title,
      .poi_title_container {
        position: relative;
        color: #000;
        font-weight: 700;
        font-size: 18px;
        line-height: 18px;
        padding: 10px 20px;
        display: flex;
        flex-flow: row;
        align-items: center;

        .icon-mode {
          border: 1px solid $color-main-one;
          border-radius: 50%;
          padding: 2px;
          width: 30px;
          height: 30px;
          margin: 0 8px 0 0;
        }

        .picto_line {
          height: 20px;
        }

        &::after {
          content: ' ';
          position: absolute;
          width: calc(100% - 20px);
          border: 1px solid #E5E5E5;
          bottom: 0;
          right: 0;
        }

        span {
          position: relative;
          font-weight: bold;
          line-height: 2;
          min-height: 24px;
          width: fit-content;
          display: block;
          min-width: 24px;
          text-align: center;
          font-family: $font-main-one;
          padding: 0 3px;
          font-size: 12px;
          margin: 0px 8px;
          border-radius: 5px;
        }
        .lines-logo {
          margin: 0px 8px;
          padding: 0 3px;
        }
      }

      #favorite_stoparea {
        position: absolute;
        right: 20px;
      }

      .poi_favorite {
        float: right;
        position: absolute;
        right: 20px;

        .ikisio-favorites {
          font-size: 20px;
        }
      }

      .proximity_stoparea_route_stops {
        padding: 5px 20px;

        .proximity_stoparea_route_stop {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin: 5px 0;

          .proximity_stoparea_route_stop_name {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            max-width: 50%;
          }

          .proximity_stoparea_route_stop_departures {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            position: relative;

            img {
              position: absolute;
              left: -13px;
              top: 0px;
              height: 10px;
              width: 10px;
            }
          }
        }
      }
    }
  }

  .proximity_stoparea_routes_container_active {
    display: block;
  }

  .proximity_poi_content {
    display: block;
    height: 84vh;

    .proximity_category_container {
      margin-bottom: 30px;
    }

    .proximity_category_container_close {
      .proximity_category_title {
        span {
          &::before {
            content: $ikisio-arrow-drop-up !important;
          }
        }
      }

      .proximity_category_content {
        display: none !important;
      }
    }

    .proximity_category_title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      color: $color-main-one;
      background-color: #F8F8F8;
      border: 1px solid #D0D0D0;
      border-radius: 10px 10px 0px 0px;
      cursor: pointer;

      span {
        &::before {
          font-family: $icomoon-font-family;
          content: $ikisio-arrow-drop-down;
          height: 10px;
          width: 10px;
          font-size: 10px;
        }
      }
    }

    .proximity_category_content {
      background: #FFFFFF;
      border: 1px solid #D0D0D0;
      border-radius: 0px 0px 10px 10px;
      padding: 20px;
    }

    .poi_container {
      background: #FFFFFF;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      border: 1px solid #D0D0D0;
      border-radius: 20px;
      margin-bottom: 20px;

      .poi_title_container {
        .poi_title {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
        }
      }

      .poi_content_container {
        display: flex;
        margin: 10px 20px;

        .poi_content_container_info {
          width: 65%;
        }

        .poi_content_distance {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 20px;
        }

        .poi_content_address_name,
        .poi_content_postal_code,
        .freefloating_info {
          font-size: 14px;
          line-height: 16px;
        }

        .poi_content_postal_code {}

        .poi_content_opening_hours {
          font-size: 14px;
          line-height: 16px;
          color: #6A6969;

          .open {
            all: unset;
            color: $color-traffic-info;
            background-color: unset;
          }

          .closed {
            all: unset;
            color: $color-traffic-disrupt;
            background-color: unset;
          }
        }

        .poi_content_container_half_infos {
          display: flex;
          flex-flow: column;
          align-items: flex-end;
          width: 35%;
          color: white;

          .places_container {
            display: flex;
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            padding: 5px 10px;
            border-radius: 50px;
            text-align: center;
            width: 120px;
            margin-bottom: 10px;
            align-items: center;

            .icon {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 22px;
              width: 22px;
              font-size: 13px;
              border: 1px solid #FFF;
              border-radius: 50%;
              margin-right: 5px;

            }

            .realtime {
              padding-bottom: 5px;
              margin-right: 2px;
            }
          }

          .available_places {
            background-color: $color-traffic-info;
          }

          .unavailable_places {
            background-color: $color-traffic-disrupt;
          }

          .almost_available_places {
            background-color: $color-traffic-warning;
          }

          .no_places {
            background-color: rgba(37, 25, 66, 0.3);
          }
        }
      }

      .freefloating_content_container {
        flex-flow: column;
      }
    }
  }
}

.board_open_proximity .standalone {
  .proximity_result_title {
    #proximity_clear {
      display: none;
    }
    h1 {
      margin: 7px 0 0 0;
    }
  }
  #proximity-search-form {
    margin: 5% 0;
    #proximity_search_uri {
      margin: unset;
    }
  }
}

#toggleStopArea {
  position: absolute;
  display: flex;
  z-index: 401;
  min-width: 186px;
  width: fit-content;
  height: 50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 50px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
  border-radius: 50px;
  font-weight: bold;
  cursor: pointer;
  &:focus-visible {
    outline: auto;
  }
}

@media (max-height: 599.98px) {
  #proximity_filters_content {
    .proximity_filters_validate {
      bottom: 10px;
    }
    .proximity_filters_containers {
      padding-bottom: 40px;
    }
  }
  .proximity-results-marge {
    display: none !important;
  }
}